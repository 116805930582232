<template lang="pug">
.loan-schedule-interest-period-list.card
  .card-header
    | {{ $t('title') }}
  .card-body
    fi-table(
      :items       = 'interests'
      :empty-label = '$t("notFound")'
      :fields      = 'fields'
      loader       = 'loan:interests:fetch'
    )
      template(#default = '{ items }')
        loan-schedule-interest-period-list-item(
          v-for     = 'period in items'
          :key      = 'period.id'
          :period   = 'period'
          :editable = 'editable'
          :periods  = 'interests'
        )

  loan-schedule-interest-period-modal(
    v-if     = 'showModal'
    v-model  = 'showModal'
    :periods = 'interests'
  )
</template>


<script>
import LoanScheduleInterestPeriodListItem from './LoanScheduleInterestPeriodListItem'
import LoanScheduleInterestPeriodModal from './LoanScheduleInterestPeriodModal'
import FiTable from '@/components/FiTable'

export default {
  name: 'loan-schedule-interest-period-list',

  components: {
    FiTable,
    LoanScheduleInterestPeriodListItem,
    LoanScheduleInterestPeriodModal
  },

  props: {
    interests: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean
    }
  },

  i18nOptions: {},

  data () {
    return {
      showModal: false
    }
  },

  computed: {
    fields () {
      return [
        {
          label: this.$t('type')
        },
        {
          label: this.$t('from')
        },
        {
          label: this.$t('until')
        },
        {
          label: this.$t('interest')
        },
        {}
      ]
    }
  }
}
</script>


<i18n>
en:
  title:    "Temporary interests"
  addNew:   "+ Add new period"
  type:     "Type"
  from:     "From"
  until:    "Until"
  interest: "Interest"
  notFound: "No interests found"
et:
  title:    "Temporary interests"
  addNew:   "+ Add new period"
  type:     "Type"
  from:     "From"
  until:    "Until"
  interest: "Interest"
  notFound: "No interests found"
ru:
  title:    "Temporary interests"
  addNew:   "+ Add new period"
  type:     "Type"
  from:     "From"
  until:    "Until"
  interest: "Interest"
  notFound: "No interests found"
</i18n>
