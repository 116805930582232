<template lang="pug">
.loan-schedule-control-numbers.card
  .card-header {{ $t('title') }}
  .card-body
    fi-data-field(:label = '$t("apr")') {{ schedule.annualPercentageRate | percent }}
    fi-data-field(:label = '$t("totalCost")') {{ schedule.totalCreditCost | money }}
</template>


<script>
import FiDataField from '@/components/FiDataField'

export default {
  name: 'loan-schedule-control-numbers',

  components: { FiDataField },

  props: {
    schedule: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {}
}
</script>


<i18n>
en:
  title:     "Control numbers"
  apr:       "APR"
  totalCost: "Total credit cost"
et:
  title:     "Control numbers"
  apr:       "APR"
  totalCost: "Total credit cost"
ru:
  title:     "Control numbers"
  apr:       "APR"
  totalCost: "Total credit cost"
</i18n>
