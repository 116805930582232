<template lang = "pug">
.loan-collateral-mortgage-asset-form.card-body.border-top.animated.fadeIn
  form.form-horizontal(
    @submit.prevent = 'submit'
    novalidate
  )
    fi-form-field(:label = '$t("assetId")')
      fi-select(
        v-model.number = 'assetData.assetId'
        :options       = 'optionsFromLoanAssetsWithId'
        sm
      )
    fi-form-field(:label = '$t("queue")')
      fi-select(
        v-model.number = 'assetData.queue'
        :options       = 'queueOptions'
        required
        sm
      )
    fi-form-field.animated.fadeIn(
      v-if   = 'assetData.queue > 1'
      :label = '$t("precedingPriorities")'
    )
      fi-money-input.form-control.form-control-sm(v-model.number = 'assetData.precedingPriorities')

    button.btn.btn-primary(
      type      = 'submit'
      :disabled = 'saving'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    | &nbsp;
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = 'close'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'loan-collateral-mortgage-asset-form',

  components: {
    FiSelect,
    FiFormField,
    FiMoneyInput
  },

  props: {
    mortgage: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      assetData: {
        assetId: '',
        queue: undefined,
        precedingPriorities: undefined
      }
    }
  },

  computed: {
    ...mapState('loans', ['loanMortgages']),
    ...mapGetters('loans', ['optionsFromLoanAssets', 'optionsFromLoanAssetsWithId']),
    saving () {
      return this.$vueLoading.isLoading(`loan:mortgage:${this.mortgage.id}:asset:save`)
    },
    queueOptions () {
      return Array.from({ length: 10 }, (_, i) => ({ text: i + 1, value: i + 1 }))
    },
    filterOptionsFromLoanAssets () {
      const list = [...this.optionsFromLoanAssets]
      if (this.mortgage.fullAssets.length) {
        this.mortgage.fullAssets.forEach((current) => {
          list.forEach((asset, index) => {
            if (asset.value === current.id) {
              list.splice(index, 1)
            }
          })
        })
        return list
      }
      return this.optionsFromLoanAssets
    }
  },

  methods: {
    ...mapActions('loans', ['assignAssetToMortgage']),
    async submit () {
      await this.assignAssetToMortgage({ id: this.mortgage.id, assetData: this.assetData })
      this.close()
    },
    close () {
      this.$emit('input', false)
    }
  }
}
</script>


<i18n>
en:
  assetId:             "Asset id"
  registerNumber:      "Register number"
  queue:               "Priority"
  precedingPriorities: "Preceding priorities amount"
et:
  assetId:             "Asset id"
  registerNumber:      "Register number"
  queue:               "Priority"
  precedingPriorities: "Preceding priorities amount"
ru:
  ssetId:             "Asset id"
  registerNumber:      "Register number"
  queue:               "Priority"
  precedingPriorities: "Preceding priorities amount"
</i18n>
