<template lang="pug">
tr.loan-list-item
  td: router-link(:to = 'loan.loanId.toString()'
                  append) {{ loan.agreementNumber }}
  td: router-link(:to = '{ name: "Customer", params: { id: loan.customer.id }}') {{ name }} ({{ loan.customer.idCode }})
  td {{ product.name }}
  td.money {{ loan.amount | money }}
  td.money {{ loan.balance | money }}
  td.money {{ loan.amountLimit | money }}
  td.money {{ loan.debts | money }}
  td {{ loan.referenceNumber }}
  td.text-nowrap {{ $t('common:formatDate', { value: loan.periodStart }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: loan.periodEnd }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: loan.nextPaymentDate }) }}
  td.text-nowrap {{ $t('common:formatDate', { value: loan.signingDate }) }}
  td: router-link(v-if = 'loan.manager'
                  :to  = '{ name: "Customer", params: { id: loan.manager.id }}') {{ manager }}
  td: .badge(:class = '`badge-${loanStatusColor(status.id)}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

export default {
  name: 'loan-list-item',

  props: {
    loan: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('products', ['productById']),
    ...mapGetters('loans', ['loanStatusColor']),
    ...mapGetters('customers', ['customerName']),
    product () {
      return this.productById(this.loan.productId)
    },
    status () {
      return this.classifierById(this.loan.status.classifier, this.loan.status.id)
    },
    name () {
      return this.customerName(this.loan.customer)
    },
    manager () {
      return this.loan.manager ? `${this.customerName(this.loan.manager)} (${this.loan.manager.idCode})` : ''
    }
  }
}
</script>
