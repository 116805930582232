<template lang="pug">
.loan-terms-relations.card
  .card-header {{ $t('title') }}
  .card-body
    v-loading(loader = 'contract:relations:fetch')
      template(#spinner)
        .text-center: i.fa.fa-spinner.fa-pulse.fa-lg
      fi-data-field(
        v-for      = '{ id, name } in relationTypes'
        :label     = '$t(name)'
        label-cols = 3
        :key       = 'id'
      )
        .form-row
          .col(v-if = 'editable')
            button.btn.btn-link.btn-sm.fa-pull-right(@click.prevent = 'addRelation(name)')
              i.far.fa-plus-square.fa-lg
        .form-row.align-items-center(
          v-for = 'relation in getRelations(name)'
          :key  = 'relation.relationId'
        )
          .col-7
            router-link(:to = '{ name: "Customer", params: { id: relation.customerId } }') {{ relation.customerName }} ({{ relation.idCode }})
          .col {{ relation.guaranteedAmount | money }}
          .col-auto(v-if = 'editable')
            button.btn.btn-link.btn-sm(@click.prevent = 'editRelation(relation)')
              i.far.fa-edit.fa-lg

            i.fa.fa-spinner.fa-pulse.fa-lg(v-if = '$vueLoading.isLoading(`contract:relation:${relation.relationId}:delete`)')
            button.btn.btn-link.btn-sm.text-danger(
              v-else
              v-fi-confirm = '{ ok: (done) => deleteRelation(relation, done), message: $t("removeConfirm") }'
            )
              i.far.fa-trash-alt.fa-lg

  loan-terms-relations-modal(
    v-if      = 'editable'
    v-model   = 'editing'
    loader    = 'contract:relation:save'
    :relation = 'relationData'
    @update   = 'saveRelation'
  )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import LoanTermsRelationsModal from './LoanTermsRelationsModal'
import { mapGetters } from 'vuex'

export default {
  name: 'loan-terms-relations',

  components: {
    FiDataField,
    LoanTermsRelationsModal
  },

  props: {
    relations: {
      type: Array,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      relationData: {}
    }
  },

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['classifierList']),
    relationTypes () {
      return this.classifierList('relatedCustomerType')
    }
  },

  methods: {
    getRelations (field) {
      return this.relations.filter(({ relatedCustomerType }) => relatedCustomerType.id === field)
    },
    editRelation (relation) {
      this.relationData = relation
      this.editing = true
    },
    addRelation (relatedCustomerType) {
      this.relationData = { relatedCustomerType }
      this.editing = true
    },
    deleteRelation (relation, done) {
      this.$emit('delete', relation)
      done()
    },
    saveRelation (payload) {
      this.$emit('save', payload)
    }
  }
}
</script>


<i18n>
en:
  title:         "Contract relations"
  COBORROWER:    "Co-Borrowers"
  WARRANTOR:     "Guarantors"
  GUARANTOR:     "Guarantors"
  SELLER:        "Sellers"
  removeConfirm: "Are you sure want to remove this relation?"
et:
  title:         "Contract relations"
  COBORROWER:    "Co-Borrowers"
  WARRANTOR:     "Guarantors"
  GUARANTOR:     "Guarantors"
  SELLER:        "Sellers"
  removeConfirm: "Are you sure want to remove this relation?"
ru:
  title:         "Contract relations"
  COBORROWER:    "Co-Borrowers"
  WARRANTOR:     "Guarantors"
  GUARANTOR:     "Guarantors"
  SELLER:        "Sellers"
  removeConfirm: "Are you sure want to remove this relation?"
</i18n>
