<template lang="pug">
fi-modal.fi-term-accept-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  lazy
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("feeName")'
      label-cols = 4
    )
      fi-select(
        v-model  = 'feeType'
        :options = 'feeOptions'
        sm
      )

    fi-form-field(
      :label     = '$t("feeAmount")'
      label-cols = 4
    )
      .form-row
        .col
          fi-select(
            v-model = 'feeCalculationType'
            :options = 'feeCalculationTypeOptions'
            required
            sm
          )
        .col
          percent-input.form-control.form-control-sm(
            v-if    = 'percentageFee'
            v-model = 'amount'
          )
          fi-money-input.form-control.form-control-sm(
            v-else
            v-model = 'amount'
          )
        .col
          fi-datepicker(
            v-model      = 'date'
            :placeholder = '$t("date")'
            sm
          )

  template(#modal-footer)
    button.btn.btn-success(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>

<script>
import FiModal from '@/components/FiModal'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import FiMoneyInput from '@/components/FiMoneyInput'
import PercentInput from '@/components/PercentInput'
import { mapGetters } from 'vuex'
import { convertFee, feePercentage } from '@/helpers'

export default {
  name: 'fi-term-accept-modal',

  components: { PercentInput, FiMoneyInput, FiDatepicker, FiSelect, FiFormField, FiModal },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    feeTypes: {
      type: Array,
      required: true
    },
    loader: {
      type: String,
      default: ''
    },
    contractAmount: {
      type: [Number, String],
      required: true
    },
    feeConfigs: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    feeType: '',
    amount: '',
    feeCalculationType: '',
    date: ''
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'optionsFromClassifier']),
    selectedFee () {
      return this.feeTypes.find(({ feeType }) => feeType.code === this.feeType)
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    feeOptions () {
      return this.feeTypes.filter(({ feeType }) => !Object.keys(this.feeConfigs).includes(feeType.code))
        .map(({ feeType }) => ({
          value: feeType.code,
          text: this.classifierByName(feeType.classifier, feeType.code)?.human
        }))
    },

    feeCalculationTypeOptions () {
      const feeType = Object.keys(this.feeConfigs).includes(this.feeType) ? this.feeType : 'CUSTOM_FEE'
      const feeCalculationTypePossible = this.feeConfigs[feeType]
      return this.optionsFromClassifier(this.selectedFee?.feeCalculationType?.classifier, true)
        .filter(({ value }) => feeCalculationTypePossible?.includes(value) ?? value)
    },

    percentageFee () {
      return feePercentage(this.feeCalculationType)
    }
  },

  watch: {
    feeType () {
      const { feeAmount, feeCalculationType } = this.selectedFee ?? {}

      this.feeAmount = feeAmount
      this.feeCalculationType = feeCalculationType?.code
    },
    feeCalculationType () {
      this.amount = convertFee({
        feeValue: this.amount,
        isPercentage: this.percentageFee,
        amountValue: this.contractAmount || 0
      })
    }
  },

  validations: {
    feeModel: {
      feeType: {
        classifier: {},
        code: {}
      },
      feeCalculationType: {
        classifier: {},
        code: {}
      },
      feeAmount: {}
    }
  },

  methods: {
    onSubmit () {
      const { amount, date, feeType, feeCalculationType } = this
      this.$emit('save', {
        done: () => this.$refs?.modal?.hide(),
        payload: {
          amount,
          date,
          feeType,
          feeCalculationType
        }
      })
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    }
  }
}
</script>


<i18n>
en:
  title:     "Terms change"
  feeName:   "Fee name"
  feeAmount: "Fee amount and date"
  date:      "Date"
  send:      "Activate"
et:
  title:     "Terms change"
  feeName:   "Fee name"
  feeAmount: "Fee amount and date"
  date:      "Date"
  send:      "Activate"
ru:
  title:     "Terms change"
  feeName:   "Fee name"
  feeAmount: "Fee amount and date"
  date:      "Date"
  send:      "Activate"
</i18n>
