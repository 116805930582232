<template lang='pug'>
fi-modal.loan-activate-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :state = '!$v.loanData.periodStart.$error'
      :label = '$t("periodStart")'
    )
      template(#error)
        .error-message(v-if = '!$v.loanData.periodStart.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model         = 'loanData.periodStart'
        :min-date       = 'systemData.nextDayChange'
        :class          = '{ "is-invalid": $v.loanData.periodStart.$error }'
      )
  template(#modal-footer)
    button.btn.btn-primary(:disabled      = 'saving'
                           @click.prevent = 'onSubmit')
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(:disabled      = 'saving'
                             @click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDatepicker from '@/components/FiDatepicker'
import FiModal from '@/components/FiModal'
import { validation, validators } from '@/validation'
import { mapState } from 'vuex'
import { isOverdraft } from '@/mixins'

export default {
  name: 'loan-activate-modal',

  components: {
    FiFormField,
    FiDatepicker,
    FiModal
  },

  mixins: [validation, isOverdraft],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    schedule: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      loanData: {
        periodStart: ''
      }
    }
  },

  computed: {
    ...mapState('loans', ['systemData']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('loan:status:save')
    },
    loanId () {
      return this.$route.params.id
    }
  },

  validations: {
    loanData: {
      periodStart: {
        required: validators.required
      }
    }
  },

  methods: {
    resetData () {
      const { periodStart } = this.schedule
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.$moment(periodStart).isBefore()) {
        this.loanData.periodStart = new Date()
      } else {
        this.loanData.periodStart = periodStart
      }
      this.$v.$reset()
    },
    onHide ({ preventDefault }) {
      if (this.saving) {
        preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        await this.callback(this.loanData)
        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  title:            "Please confirm details"
  periodStart:      "Payout date (start date)"
et:
  title:            "Please confirm details"
  periodStart:      "Payout date (start date)"
ru:
  title:            "Please confirm details"
  periodStart:      "Payout date (start date)"
</i18n>
