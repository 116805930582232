<template lang="pug">
.loan-communication
  v-loading(loader = 'customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    customer-communication-view(
      store-module           = 'loans'
      resources-state        = 'loanResources'
      resources-pages-state  = 'loanResourcesPages'
      comments-state         = 'loanComments'
      :resource-action       = 'resourceAction'
      commentable-type       = 'LOAN_CONTRACT'
      messages-state         = 'loanMessages'
      messages-pages-state   = 'loanMessagesPages'
      customer-history-state = 'loanCustomerHistory'
      customer-state         = 'loanCustomer'
    )
</template>


<script>
import { mapState, mapActions } from 'vuex'
import CustomerCommunicationView from '@/views/customers/CustomerCommunicationView'

export default {
  name: 'loan-communication',

  components: { CustomerCommunicationView },

  computed: {
    ...mapState('loans', ['loan', 'loanCustomer'])
  },

  created () {
    this.loadCustomerHistory({ customerId: this.loan.customerBorrower.id })
  },

  methods: {
    ...mapActions('loans', ['createLoanResource', 'loadCustomerHistory']),
    resourceAction ({ resourceData }) {
      return this.createLoanResource({
        resourceData: {
          ...resourceData,
          documentRelation: 'LOAN'
        },
        customerId: this.loanCustomer.id,
        contractId: this.loan.id
      })
    }
  }
}
</script>
