<template lang="pug">
  loan-collateral.loan-collateral-view(:seller = 'seller')
</template>


<script>
import LoanCollateral from '@/views/loans/LoanCollateral'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'loan-collateral-view',

  components: { LoanCollateral },

  computed: {
    ...mapState('loans', ['loanRelations']),
    seller () {
      const sellers = this.loanRelations.filter(({ relatedCustomerType }) => /SELLER/i.test(relatedCustomerType.code))
      return sellers.length === 1 ? sellers[0] : null
    }
  },

  created () {
    this.loadLoanRelations({ loanId: this.$route.params.id })
  },

  methods: {
    ...mapActions('customers', ['loadCustomer']),
    ...mapActions('loans', ['loadLoanRelations'])
  }
}
</script>
