<template lang="pug">
.loan-schedule-details.card
  .card-header {{ $t('title') }}
    a.fa-pull-right.animated.fadeIn(
      v-if           = 'editable && !editing'
      href           = '#'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing'
    ): i.far.fa-edit.fa-lg
  .card-body
    form.form-horizontal(
      @submit.prevent = 'onSubmit'
      novalidate
    )
      template(v-if = 'editing')
        fi-form-field(
          :label     = '$t("amount")'
          :state     = '!$v.scheduleData.amount.$error'
          label-cols = '5'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.amount.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.scheduleData.amount.lte') {{ $t('loanBetween') }}
          fi-money-input.form-control.form-control-sm(
            v-model.number = 'scheduleData.amount'
            :class         = '{ "is-invalid": $v.scheduleData.amount.$error }'
          )

        fi-form-field(
          v-if       = 'isLeasingGroup && product.principalWithVat'
          :label     = '$t("amountVAT")'
          label-cols = '5'
        )
          .form-row
            .col
              v-loading(loader = 'taxation:convert:save')
                template(#spinner)
                  i.fa.fa-spinner.fa-pulse
                fi-money-input.form-control.form-control-sm(
                  v-model.number  = 'amountWithVat'
                  type            = 'text'
                  disabled
                )
            .col
              fi-select(
                v-if     = 'vatTaxes'
                v-model  = 'scheduleData.vatRate'
                :options = 'optionsFromClassifierTax'
                sm
              )
        fi-form-field(
          :label     = '$t("limit")'
          :state     = '!$v.loanData.amountLimit.$error'
          label-cols = '5'
        )
          template(#error)
            .error-message(v-if = '!$v.loanData.amountLimit.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.loanData.amountLimit.gte') {{ $t('gte', { value: product.amountMin }) }}
            .error-message(v-if = '!$v.loanData.amountLimit.lte') {{ $t('lte', { value: product.amountMax }) }}
          fi-money-input.form-control.form-control-sm(
            v-model.number = 'loanData.amountLimit'
            :class         = '{ "is-invalid": $v.loanData.amountLimit.$error }'
            :disabled      = 'product.amountMin === product.amountMax'
          )
        template(v-if = 'isLeasingGroup')
          fi-form-field(
            :label     = '$t("upfrontAmount")'
            :state     = '!$v.upfrontAmountGroup.$error'
            label-cols = '5'
          )
            template(#error)
              .form-row
                .col
                  .error-message(v-if = '!$v.scheduleData.upfrontAmountCalculationType.required') {{ $t('common:required') }}
                .col
                  .error-message(v-if = '!$v.scheduleData.upfrontAmount.required') {{ $t('common:required') }}
            .form-row
              .col
                fi-select(
                  v-model.number = 'scheduleData.upfrontAmountCalculationType'
                  :options       = 'leasingCalculationTypes'
                  :class         = '{ "is-invalid": $v.scheduleData.upfrontAmountCalculationType.$error }'
                  :disabled      = '!product.upfrontAmountCalculationTypeIdOverride'
                  required
                  sm
                )
              .col
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentageUpfrontAmount'
                  v-model.number = 'scheduleData.upfrontAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.upfrontAmount.$error }'
                  :disabled      = '!scheduleData.upfrontAmountCalculationType || !product.upfrontAmountOverride'
                )
                fi-money-input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'scheduleData.upfrontAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.upfrontAmount.$error }'
                  :disabled      = '!scheduleData.upfrontAmountCalculationType || !product.upfrontAmountOverride'
                )
              .col
                .form-control-plaintext(v-if = 'percentageUpfrontAmount') {{ upfrontAmountInform | money }}
                .form-control-plaintext(v-else) {{ upfrontAmountInform | percent }}
          fi-form-field(
            :label     = '$t("residualAmount")'
            :state     = '!$v.residualAmountGroup.$error'
            label-cols = '5'
          )
            template(#error)
              .form-row
                .col
                  .error-message(v-if = '!$v.scheduleData.residualAmountCalculationType.required') {{ $t('common:required') }}
                .col
                  .error-message(v-if = '!$v.scheduleData.residualAmount.required') {{ $t('common:required') }}
            .form-row
              .col
                fi-select(
                  v-model.number = 'scheduleData.residualAmountCalculationType'
                  :options       = 'leasingCalculationTypes'
                  :class         = '{ "is-invalid": $v.scheduleData.residualAmountCalculationType.$error }'
                  :disabled      = '!product.residualAmountCalculationTypeIdOverride'
                  required
                  sm
                )
              .col
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentageResidualAmount'
                  v-model.number = 'scheduleData.residualAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.residualAmount.$error }'
                  :disabled      = '!scheduleData.residualAmountCalculationType || !product.residualAmountOverride'
                )
                fi-money-input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'scheduleData.residualAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.residualAmount.$error }'
                  :disabled      = '!scheduleData.residualAmountCalculationType || !product.residualAmountOverride'
                )
              .col
                .form-control-plaintext(v-if = 'percentageResidualAmount') {{ residualAmountInform | money }}
                .form-control-plaintext(v-else) {{ residualAmountInform | percent }}

        fi-form-field(
          :label     = '$t("scheduleType")'
          label-cols = '5'
        )
          fi-select(
            v-model   = 'scheduleData.scheduleType'
            :options  = 'optionsFromClassifier("LoanScheduleType")'
            :disabled = '!product.scheduleTypeIdOverride'
            sm
          )

        fi-form-field(
          :label     = '$t("dateStartEnd")'
          :state     = '!$v.periodGroup.$error'
          label-cols = '5'
        )
          .form-row
            .col
              fi-datepicker(
                v-model   = 'loanData.periodStart'
                :min-date = 'systemData.nextDayChange'
                :max-date = 'loanData.periodEnd'
                sm
              )
            .col
              fi-datepicker(
                v-model   = 'loanData.periodEnd'
                :min-date = 'periodMinDate'
                :max-date = 'periodMaxDate'
                sm
              )
        fi-form-field(
          :label     = '$t("interestRate")'
          :state     = '!$v.scheduleData.interestRate.$error'
          label-cols = '5'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.interestRate.gte') {{ $t('gte', { value: product.interestRateMin }) }}
            .error-message(v-if = '!$v.scheduleData.interestRate.lte') {{ $t('lte', { value: product.interestRateMax }) }}
          .form-row
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'scheduleData.interestRate'
              type           = 'text'
              :class         = '{ "is-invalid": $v.scheduleData.interestRate.$error }'
              :disabled      = 'product.interestRateMin === product.interestRateMax'
            )
            .col: fi-select(
              v-model   = 'scheduleData.dayCountConvention'
              :options  = 'optionsFromClassifier("DayCountConvention")'
              sm
            )
        fi-form-field(
          :label     = '$t("interestBaseRate")'
          :state     = '!$v.scheduleData.interestRate.$error'
          label-cols = '5'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.interestRate.gte') {{ $t('gte', { value: product.interestRateMin }) }}
            .error-message(v-if = '!$v.scheduleData.interestRate.lte') {{ $t('lte', { value: product.interestRateMax }) }}
          .form-row
            //- .col: percent-input.form-control.form-control-sm(
            //-   v-model.number = 'scheduleData.interestRate'
            //-   type           = 'text'
            //-   :class         = '{ "is-invalid": $v.scheduleData.interestRate.$error }'
            //-   :disabled      = 'product.interestRateMin === product.interestRateMax'
            //- )
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'scheduleData.variableInterestRate'
              type           = 'text'
              :decimalLimit  = 4
              :disabled      = 'interestRateName === "FIXED"'
            )
            .col: fi-select(
              v-model   = 'variableInterestIdGetter'
              :options  = 'interestRatesDataValues'
              :disabled = '!interestRatesData.length'
              :required = 'true'
              sm
            )
            //- .col: percent-input.form-control.form-control-sm(
            //-   v-model.number = 'scheduleData.variableInterestRate'
            //-   type           = 'text'
            //-   :decimalLimit  = 4
            //-   :disabled      = 'interestRateName === "FIXED"'
            //- )
            .col: div.cell-center {{ 'Σ ' }} {{ (scheduleData.interestRate + scheduleData.variableInterestRate) || scheduleData.interestRate | percent }}

        template(v-if = '!bulletScheduleType')
          fi-form-field(
            :label     = '$t("firstPrincipalDay")'
            :state     = '!$v.scheduleData.principalFirst.$error'
            label-cols = '5'
          )
            template(#error)
              .error-message(v-if = '!$v.scheduleData.principalFirst.between') {{ $t('principalFirstErr', { begin: $v.scheduleData.principalFirst.$params.between.min, end: $v.scheduleData.principalFirst.$params.between.max }) }}
            fi-datepicker(
              v-model         = 'scheduleData.principalFirst'
              :disabled       = 'isPrincipalTenor'
              :class          = '{ "is-invalid": $v.scheduleData.principalFirst.$error }'
              :disabled-dates = 'product.group !== "LEASING" ? lastMonthDays : {}'
              :enabled-dates  = 'product.group === "LEASING" ? interestPeriodEnabledDatesEnd : {}'
              sm
            )

          fi-form-field(
            :label     = '$t("principalFreq")'
            label-cols = '5'
            :disabled  = '!product.principalPaymentPeriodIdOverride'
          )
            fi-select(
              v-model   = 'scheduleData.principalPaymentPeriod'
              :options  = 'principalPaymentPeriods'
              required
              sm
            )

        fi-form-field(
          :label     = '$t("firstInterestDay")'
          :state     = '!$v.scheduleData.interestFirst.$error'
          label-cols = '5'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.interestFirst.between') {{ $t('interestFirstErr', { begin: $v.scheduleData.interestFirst.$params.between.min, end: $v.scheduleData.interestFirst.$params.between.max }) }}
          fi-datepicker(
            v-model         = 'scheduleData.interestFirst'
            :disabled       = 'isInterestTenor'
            :class          = '{ "is-invalid": $v.scheduleData.interestFirst.$error }'
            :enabled-dates  = 'product.group === "LEASING" ? interestPeriodEnabledDatesEnd : interestPeriodEnabledDates'
            sm
          )

        fi-form-field(
          :label     = '$t("interestFreq")'
          label-cols = '5'
          :disabled  = '!product.interestPaymentPeriodIdOverride'
        )
          fi-select(
            v-model  = 'scheduleData.interestPaymentPeriod'
            :options = 'optionsFromClassifier("Frequency")'
            required
            sm
          )

        template(v-if = 'isCapitalCompanyLeasing')
          fi-form-field(
            :label     = '$t("principalVatPeriod")'
            label-cols = '5'
          )
            fi-select(
              v-model.number = 'scheduleData.principalVatAmountPeriod'
              :options       = 'optionsFromClassifier("scheduleVatAmountPeriod")'
              required
              sm
            )

        fi-form-field(
          :label     = '$t("penaltyRate")'
          :state     = '!$v.scheduleData.penaltyInterestRate.$error'
          label-cols = '5'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.penaltyInterestRate.gte') {{ $t('gte', { value: product.penaltyInterestRateMin }) }}
            .error-message(v-if = '!$v.scheduleData.penaltyInterestRate.lte') {{ $t('lte', { value: product.penaltyInterestRateMax }) }}
          .form-row
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'scheduleData.penaltyInterestRate'
              type           = 'text'
              :class         = '{ "is-invalid": $v.scheduleData.penaltyInterestRate.$error }'
              :disabled      = 'product.penaltyInterestRateMin === product.penaltyInterestRateMax'
            )
            .col: fi-select(
              v-model   = 'loanData.penaltyDayCountConvention'
              :options  = 'optionsFromClassifier("DayCountConvention")'
              :disabled = '!product.penaltyDayCountConventionIdOverride'
              sm
            )
        template(v-if = 'balloonScheduleType')
          fi-form-field(
            :label     = '$t("amortizationPeriod")'
            :state     = '!$v.scheduleData.amortizationPeriod.$error'
            label-cols = '5'
          )
            template(#error)
              .error-message(v-if = '!$v.scheduleData.amortizationPeriod.required') {{ $t('common:required') }}
            input.form-control.form-control-sm(
              v-model.number = 'scheduleData.amortizationPeriod'
              :class         = '{ "is-invalid": $v.scheduleData.amortizationPeriod.$error }'
            )
        fi-fee-item(
          v-for         = '(fee, index) in availableFees'
          :key          = 'index'
          :fee          = 'fee'
          :amount       = '$v.scheduleData.amount.$model'
          :fee-configs   = 'feeConfigs'
          label-cols    = '5'
          :min-date     = 'minDate(fee)'
          editing
        )
        //- fi-form-field(
        //-   :label     = '$t("guaranteedPercentageFromAmount")'
        //-   label-cols = '5'
        //- )
        //-   percent-input.form-control.form-control-sm(
        //-     v-model = 'scheduleData.guaranteedPercentageFromAmount'
        //-   )

        .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

      template(v-else)
        fi-data-field(
          :label     = '$t("amount")'
          label-cols = '5'
        ) {{ schedule.amount | money }}
        fi-data-field(
          v-if       = 'isLeasingGroup && product.principalWithVat'
          :label     = '$t("amountVAT")'
          label-cols = '5'
        )
          .form-row
            .col {{ schedule.amountWithVat | money }}
            .col
            .col {{ schedule.vatRate | percent }}
        fi-data-field(
          :label     = '$t("limit")'
          label-cols = '5'
        ) {{ loan.amountLimit | money }}
        template(v-if = 'isLeasingGroup')
          fi-data-field(
            :label = '$t("upfrontAmount")'
            label-cols = '5'
          )
            .form-row
              template(v-if = 'percentageUpfrontAmount')
                .col {{ schedule.upfrontAmount | percent }}
                .col
                .col {{ upfrontAmountInform | money }}
              template(v-else)
                .col {{ upfrontAmountInform | percent }}
                .col
                .col {{ schedule.upfrontAmount | money }}
          fi-data-field(
            :label = '$t("residualAmount")'
            label-cols = '5'
          )
            .form-row
              template(v-if = 'percentageResidualAmount')
                .col {{ schedule.residualAmount | percent }}
                .col
                .col {{ residualAmountInform | money }}
              template(v-else)
                .col {{ residualAmountInform | percent }}
                .col
                .col {{ schedule.residualAmount | money }}
        fi-data-field(
          :label     = '$t("scheduleType")'
          label-cols = '5'
        ) {{ scheduleType.human }}
        fi-data-field(
          :label     = '$t("dateStartEnd")'
          label-cols = '5'
        )
          .form-row
            .col {{ $t('common:formatDate', { value: schedule.periodStart }) }}
            .col
            .col.justify-content-end {{ $t('common:formatDate', { value: schedule.periodEnd }) }}
        fi-data-field(
          :label     = '$t("interestRate")'
          label-cols = '5'
        )
          .form-row
            .col {{ schedule.interestRate | percent }}
            .col
            .col {{ dayCountConvention ? dayCountConvention.human : '' }}
            //- .col {{ schedule.variableInterestRate || 0 | percent('0.000 %') }}
            //- .col {{ 'Σ ' }} {{ (schedule.interestRate + schedule.variableInterestRate) || schedule.interestRate | percent('0.000 %') }}
        fi-data-field(
          :label     = '$t("interestBaseRate")'
          label-cols = '5'
        )
          .form-row
            //- .col {{ schedule.interestRate | percent }}
            .col {{ schedule.variableInterestRate || 0 | percent('0.000 %') }}
            .col {{ variableInterestName }}
            //- .col {{ schedule.variableInterestRate || 0 | percent('0.000 %') }}
            .col {{ 'Σ ' }} {{ (schedule.interestRate + schedule.variableInterestRate) || schedule.interestRate | percent('0.000 %') }}

        template(v-if = '!bulletScheduleType')
          fi-data-field(
            :label     = '$t("firstPrincipalDay")'
            label-cols = '5'
          ) {{ $t('common:formatDate', { value: schedule.principalFirst }) }}
          fi-data-field(
            :label     = '$t("principalFreq")'
            label-cols = '5'
          ) {{ principalPaymentPeriod.human }}

        fi-data-field(
          :label     = '$t("firstInterestDay")'
          label-cols = '5'
        ) {{ $t('common:formatDate', { value: schedule.interestFirst }) }}
        fi-data-field(
          :label     = '$t("interestFreq")'
          label-cols = '5'
        ) {{ interestPaymentPeriod.human }}

        template(v-if = 'isCapitalCompanyLeasing && schedule.principalVatAmountPeriod')
          fi-data-field(
            :label     = '$t("principalVatPeriod")'
            label-cols = '5'
          ) {{ classifierById(schedule.principalVatAmountPeriod.classifier, schedule.principalVatAmountPeriod.id).human }}
        fi-data-field(
          :label     = '$t("penaltyRate")'
          label-cols = '5'
        )
          .form-row
            .col {{ loan.penaltyInterestRate | percent }}
            .col
            .col {{ penaltyDayCountConvention.human }}

        template(v-if = 'balloonScheduleType')
          fi-form-field(
            :label     = '$t("amortizationPeriod")'
            label-cols = '5'
          ) {{ schedule.amortizationPeriod }}
        fi-fee-item(
          v-for         = '(fee, index) in availableFees'
          :key          = 'index'
          :fee          = 'fee'
          :amount       = 'schedule.amount'
          :fee-configs   = 'feeConfigs'
          label-cols    = '5'
        )
        //- fi-form-field(
        //-   :label     = '$t("guaranteedPercentageFromAmount")'
        //- )
        //-   div {{ schedule.guaranteedPercentageFromAmount | percent }}

      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import { rules, validation, validators } from '@/validation/index'
import {
  excludeLastMonthDays,
  feeDisabledMixin,
  feeEditableMixin,
  formatRangeError,
  isCapitalLeasing,
  isLoanLeasing
} from '@/mixins'
import debounce from 'lodash/debounce'
import { calculationTypes, convertFee, convertFeeRange, feeDisabled, feePercentage } from '@/helpers'
import FiFeeItem from '@/components/FiFeeItem'

export default {
  name: 'loan-schedule-details',

  components: {
    FiFeeItem,
    FiSelect,
    FiDataField,
    FiFormField,
    FiDatepicker,
    PercentInput,
    FiMoneyInput
  },

  mixins: [
    validation,
    excludeLastMonthDays,
    feeDisabledMixin,
    feeEditableMixin,
    isLoanLeasing,
    isCapitalLeasing,
    formatRangeError
  ],

  props: {
    loan: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    schedule: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data () {
    return {
      amountWithVat: null,
      vatTaxes: null,
      editing: false,
      error: null,
      interestRateName: '',
      loanData: {
        amountLimit: null,
        amountLimitWithVat: null,
        penaltyDayCountConvention: '',
        invoiceDay: null,
        periodStart: null,
        periodEnd: null
      },
      scheduleData: {
        fees: [],
        amortizationPeriod: null,
        amount: null,
        dayCountConvention: '',
        interestRate: null,
        penaltyInterestRate: null,
        scheduleType: '',
        principalPaymentPeriod: '',
        principalFirst: null,
        interestPaymentPeriod: '',
        interestFirst: null,
        upfrontAmount: null,
        upfrontAmountCalculationType: null,
        residualAmount: null,
        residualAmountCalculationType: null,
        principalVatAmountPeriod: null,
        vatRate: null,
        variableInterestId: this.schedule.variableInterestDto?.id || null,
        variableInterestRate: null,
        variableInterestFrom: null,
        variableInterestUntil: null
      },
      interestRatesData: []
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'optionsFromClassifier', 'classifierByName', 'classifierList']),
    ...mapGetters('loans', ['scheduleById']),
    ...mapGetters('settings', ['settingByKey']),
    ...mapState('loans', ['systemData']),
    ...mapState('interests', ['interestRates', 'variableInterests']),
    interestPeriodMinDate () {
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod)?.name
      let period = 1
      switch (interestPeriod) {
        case 'QUARTERLY':
          period = 3
          break
        case 'SEMIANNUAL':
          period = 6
          break
        case 'ANNUAL':
          period = 12
          break
      }
      const minDate = this.$moment(this.scheduleData.principalFirst)
      while (this.$moment(minDate).subtract(period, 'months').isSameOrAfter(this.loanData.periodStart)) {
        minDate.subtract(period, 'months')
      }
      return minDate
    },
    interestPeriodEnabledDates () {
      if (this.isPrincipalTenor) {
        return {
          start: new Date(this.loanData.periodStart),
          end: new Date(this.loanData.periodEnd)
        }
      }
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod) ?? {}
      const interestRange = this.$moment.range(this.interestPeriodMinDate, this.loanData.periodEnd).snapTo('day')
      let range = []
      switch (interestPeriod.name) {
        case 'MONTHLY':
          range = interestRange.by('months')
          break
        case 'QUARTERLY':
          range = interestRange.by('quarters')
          break
        case 'SEMIANNUAL':
          range = interestRange.by('months', { step: 6 })
          break
        case 'ANNUAL':
          range = interestRange.by('years')
          break
      }
      return [this.$moment(this.loanData.periodStart), this.$moment(this.loanData.periodEnd), ...Array.from(range)].map(date => date.toDate())
    },
    interestPeriodEnabledDatesEnd () {
      if (this.isPrincipalTenor) {
        return {
          start: new Date(this.loanData.periodStart),
          end: new Date(this.loanData.periodEnd)
        }
      }
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod) ?? {}
      const interestRange = this.$moment.range(this.interestPeriodMinDateEnd, this.loanData.periodEnd).snapTo('day')
      let range = []
      switch (interestPeriod.name) {
        case 'MONTHLY':
          range = interestRange.by('months')
          break
        case 'QUARTERLY':
          range = interestRange.by('quarters')
          break
        case 'SEMIANNUAL':
          range = interestRange.by('months', { step: 6 })
          break
        case 'ANNUAL':
          range = interestRange.by('years')
          break
      }
      return [this.$moment(this.loanData.periodEnd), this.$moment(this.loanData.periodEnd), ...Array.from(range)].map(date => date.toDate())
    },
    interestPeriodMinDateEnd () {
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.loanData.interestPaymentPeriod)?.name
      let period = 1
      switch (interestPeriod) {
        case 'QUARTERLY':
          period = 3
          break
        case 'SEMIANNUAL':
          period = 6
          break
        case 'ANNUAL':
          period = 12
          break
      }
      const minDate = this.$moment(this.$v.loanData.periodEnd.$model)
      if (typeof this.$v.loanData.periodStart.$model === 'string') {
        const startMonth = this.$v.loanData.periodStart.$model.split('-')[1]
        if (typeof minDate._i === 'string') {
          const mapMinDate = minDate._i.split('-')
          mapMinDate[1] = startMonth
          minDate._i = mapMinDate.join('-')
        }
      }
      while (this.$moment(minDate).subtract(1, 'months').isSameOrAfter(this.$v.loanData.periodStart)) {
        minDate.subtract(period, 'months')
      }
      return minDate
    },
    balloonScheduleType () {
      return this.editing ? this.scheduleData.scheduleType === 'BALLOON' : this.schedule.scheduleType.id === 'BALLOON'
    },
    bulletScheduleType () {
      return this.editing ? this.scheduleData.scheduleType === 'BULLET' : this.schedule.scheduleType.id === 'BULLET'
    },
    dayCountConvention () {
      return this.classifierByName(this.schedule.dayCountConvention.classifier, this.schedule.dayCountConvention.code) ?? {}
    },
    penaltyDayCountConvention () {
      return this.classifierByName(this.loan.penaltyDayCountConvention.classifier, this.loan.penaltyDayCountConvention.code) ?? {}
    },
    scheduleType () {
      return this.classifierByName(this.schedule.scheduleType.classifier, this.schedule.scheduleType.code) ?? {}
    },
    principalPaymentPeriod () {
      return this.classifierByName(this.schedule.principalPaymentPeriod.classifier, this.schedule.principalPaymentPeriod.code) ?? {}
    },
    interestPaymentPeriod () {
      return this.classifierByName(this.schedule.interestPaymentPeriod.classifier, this.schedule.interestPaymentPeriod.code) ?? {}
    },
    saving () {
      return this.$vueLoading.isLoading(`loan:${this.loan.id}:save`)
    },
    principalPaymentPeriods () {
      const interestPeriodId = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod)?.id
      const availablePeriodsNames = this.optionsFromClassifier('paymentPeriodTypes')
        .filter(period => period.value >= interestPeriodId)
        .map(period => period.text)
      return this.optionsFromClassifier('Frequency').filter(period => availablePeriodsNames.includes(period.text) || !period.value)
    },
    leasingCalculationTypes () {
      return calculationTypes(
        { feeType: { code: 'LEASING' }, feeCalculationType: { classifier: 'feeCalculationType' } },
        this.feeConfigs,
        this.classifierList('feeCalculationType')
      )
    },
    isInterestTenor () {
      return /TENOR/i.test(this.scheduleData.interestPaymentPeriod)
    },
    isPrincipalTenor () {
      return /TENOR/i.test(this.scheduleData.principalPaymentPeriod)
    },
    periodUnit () {
      return this.classifierById('periodUnitType', this.product.periodUnitTypeId)
    },
    periodMinDate () {
      return this.$moment(this.loanData.periodStart).add(this.product.periodMin, this.periodUnit.name).toDate()
    },
    periodMaxDate () {
      return this.$moment(this.loanData.periodStart).add(this.product.periodMax, this.periodUnit.name).toDate()
    },
    upfrontAmountInform () {
      return convertFee({
        feeValue: this.schedule.upfrontAmount,
        isPercentage: !this.percentageUpfrontAmount,
        amountValue: this.schedule.amount
      })
    },
    residualAmountInform () {
      return convertFee({
        feeValue: this.schedule.residualAmount,
        isPercentage: !this.percentageResidualAmount,
        amountValue: this.schedule.amount
      })
    },
    percentageUpfrontAmount () {
      return feePercentage(this.schedule?.upfrontAmountCalculationType?.code)
    },
    percentageResidualAmount () {
      return feePercentage(this.schedule?.residualAmountCalculationType?.code)
    },
    availableFees () {
      return this.editing ? Object.values(this.$v.scheduleData.fees.$each.$iter) : this.schedule.fees
    },
    optionsFromClassifierTax () {
      const options = []
      this.vatTaxes.forEach((current) => {
        options.push({ text: `${(current.rate) * 100} %`, value: current.rate })
      })
      return options
    },
    interestRatesDataValues () {
      if (this.interestRatesData.length) {
        return this.interestRatesData.map((rate) => rate.name)
      } else {
        return ['FIXED']
      }
    },
    variableInterestIdGetter: {
      get () {
        return this.interestRateName
      },
      set (value) {
        this.scheduleData.variableInterestId = this.interestRatesData?.find((rate) => rate.name === value)?.id || null
        this.scheduleData.variableInterestRate = this.interestRates?.find((rate) => rate.variableInterest.id === this.scheduleData.variableInterestId)?.rate
        this.interestRateName = this.interestRates?.find((rate) => rate.name === value)?.name || value
      }
    },
    variableInterestName () {
      return this.variableInterests.find((rate) => rate.id === this.schedule.variableInterestDto?.id)?.name || 'FIXED'
    }
  },

  watch: {
    'scheduleData.scheduleType' () {
      if (this.bulletScheduleType) {
        this.scheduleData.principalPaymentPeriod = 'TENOR'
      } else {
        this.scheduleData.interestFirst = this.findNearestPaymentDate()
        this.scheduleData.principalFirst = this.findNearestPaymentDate()
      }
    },
    'loanData.periodStart' (val, oldVal) {
      if (!oldVal || this.isPrincipalTenor) {
        return
      }
      this.scheduleData.principalFirst = this.findNearestPaymentDate(val)
    },
    'scheduleData.interestPaymentPeriod' (val) {
      const interestPeriodId = this.classifierByName('paymentPeriodTypes', val)?.id
      const principalPeriodId = this.classifierByName('paymentPeriodTypes', this.scheduleData.principalPaymentPeriod)?.id
      if (principalPeriodId < interestPeriodId) {
        this.scheduleData.principalPaymentPeriod = val
      }
      if (this.isInterestTenor) {
        this.scheduleData.interestFirst = this.loanData.periodEnd || this.schedule.periodEnd
      }
    },
    'scheduleData.principalPaymentPeriod' () {
      if (this.isPrincipalTenor) {
        this.scheduleData.principalFirst = this.loanData.periodEnd || this.schedule.periodEnd
      }
    },
    'loanData.periodEnd' (val) {
      if (this.isInterestTenor) {
        this.scheduleData.interestFirst = val
      }
      if (this.isPrincipalTenor) {
        this.scheduleData.principalFirst = val
      }
    },
    'scheduleData.amount' () {
      if (this.product.principalWithVat) {
        this.onAmountChange()
      }
    },
    percentageUpfrontAmount (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }
      this.scheduleData.upfrontAmount = convertFee({
        feeValue: this.scheduleData.upfrontAmount,
        isPercentage: value,
        amountValue: this.scheduleData.amount
      })
    },
    percentageResidualAmount (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }
      this.scheduleData.residualAmount = convertFee({
        feeValue: this.scheduleData.residualAmount,
        isPercentage: value,
        amountValue: this.scheduleData.amount
      })
    }
  },

  validations () {
    return {
      scheduleData: merge({}, pick(rules.loanRules, Object.keys(this.scheduleData)), {
        amount: {
          lte: value => value <= this.loanData.amountLimit
        },
        principalFirst: {
          between: validators.datesBetween(this.loanData.periodStart, this.loanData.periodEnd),
          required: validators.requiredUnless(function () {
            return this.bulletScheduleType
          })
        },
        interestFirst: {
          between: validators.datesBetween(this.loanData.periodStart, this.loanData.periodEnd)
        },
        interestRate: {
          gte: validators.gte(this.product.interestRateMin),
          lte: validators.lte(this.product.interestRateMax)
        },
        penaltyInterestRate: {
          gte: validators.gte(this.product.penaltyInterestRateMin),
          lte: validators.lte(this.product.penaltyInterestRateMax)
        },
        principalPaymentPeriod: {
          required: validators.requiredUnless(function () {
            return this.bulletScheduleType
          })
        },
        amortizationPeriod: {
          required: validators.requiredIf(function () {
            return this.balloonScheduleType
          })
        },
        residualAmount: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        residualAmountCalculationType: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        upfrontAmount: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        upfrontAmountCalculationType: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        fees: {
          $each: {
            date: {},
            feeType: {
              classifier: {},
              code: {}
            },
            feeCalculationType: {
              classifier: {},
              code: {}
            },
            feeAmount: {
              required: validators.requiredUnless(function (fee) {
                return feeDisabled(fee)
              }),
              min: validators.minValueRef(function (fee) {
                return convertFeeRange(fee, this.scheduleData.amount).feeMin ?? -Infinity
              }),
              max: validators.maxValueRef(function (fee) {
                return convertFeeRange(fee, this.scheduleData.amount).feeMax ?? Infinity
              })
            }
          }
        }
      }),
      loanData: merge({}, pick(rules.loanRules, Object.keys(this.loanData)), {
        amountLimit: {
          gte: validators.gte(this.product.amountMin),
          lte: validators.lte(this.product.amountMax)
        }
      }),
      periodGroup: ['loanData.periodStart', 'loanData.periodEnd'],
      upfrontAmountGroup: ['scheduleData.upfrontAmount', 'scheduleData.upfrontAmountCalculationType'],
      residualAmountGroup: ['scheduleData.residualAmount', 'scheduleData.residualAmountCalculationType']
    }
  },

  async created () {
    await this.loadVariableInterestsRate()
    await this.loadVariableInterests()
    this.interestRatesData = this.variableInterests.filter((rate) =>
      this.product.productInterestDefinitionDtos.find((interestDefinition) =>
        interestDefinition.variableInterestId === rate.id
      )
    )
    if (!this.product.productInterestDefinitionDtos.length) {
      this.interestRatesData = this.variableInterests.filter((interest) => interest.name === 'FIXED')
      this.interestRateName = 'FIXED'
      this.scheduleData.variableInterestId = this.interestRatesData[0]?.id || 7
      if (!this.scheduleData.variableinterestRate) {
        this.scheduleData.variableinterestRate = 0
      }
    } else {
      this.interestRateName = this.variableInterestName
    }
    // await this.resetData()
    // this.vatTaxes = await this.getTaxRateVat({ countryId: this.product.creditIssuerId })
    this.vatTaxes = await this.loadTaxationCountriesVat()
  },

  methods: {
    ...mapActions('loans', ['updateLoan']),
    ...mapActions('taxation', ['convertAmount', 'getTaxRateVat']),
    ...mapActions('interests', ['loadVariableInterestsRate', 'loadVariableInterests']),
    ...mapActions('classifiers', ['loadTaxationCountriesVat']),
    onAmountChange: debounce(
      async function () {
        this.amountWithVat = await this.convertAmount({
          amountData: {
            amount: this.scheduleData.amount,
            amountWithVat: false
          }
        })
      }, 500
    ),
    findNearestPaymentDate (start = this.$moment().format('YYYY-MM-DD')) {
      return this.schedule?.loanInvoices?.find(({ paymentDate }) => paymentDate > start)?.paymentDate
    },
    toggleEditing () {
      this.editing = !this.editing
      this.resetData()
      this.$emit('update:editable', this.editing)
    },
    resetData () {
      this.$v.$reset()
      this.error = null
      this.loanData = merge(
        {},
        this.$options.data.call(this).loanData,
        pick(this.loan, Object.keys(this.loanData))
      )
      this.scheduleData = merge(
        {},
        this.$options.data.call(this).scheduleData,
        pick(this.schedule, Object.keys(this.scheduleData))
      )

      this.$set(this.loanData, 'penaltyDayCountConvention', this.loan.penaltyDayCountConvention.id)
      this.$set(this.scheduleData, 'dayCountConvention', this.schedule.dayCountConvention.id)
      this.$set(this.scheduleData, 'scheduleType', this.schedule.scheduleType.id)
      this.$set(this.scheduleData, 'interestPaymentPeriod', this.schedule.interestPaymentPeriod.id)
      this.$set(this.scheduleData, 'principalPaymentPeriod', this.schedule.principalPaymentPeriod.id)

      if (this.isCapitalCompanyLeasing) {
        this.$set(this.scheduleData, 'principalVatAmountPeriod', this.schedule.principalVatAmountPeriod?.id)
      }


      if (this.isLeasingGroup) {
        this.$set(this.scheduleData, 'upfrontAmountCalculationType', this.schedule.upfrontAmountCalculationType.id)
        this.$set(this.scheduleData, 'residualAmountCalculationType', this.schedule.residualAmountCalculationType.id)
      }
      if ((!this.schedule.vatRate && this.vatTaxes) || (this.vatTaxes && this.schedule.vatRate && !this.vatTaxes?.find(i => i.rate === this.schedule.vatRate))) {
        this.scheduleData.vatRate = (this.vatTaxes?.filter(i => i.default === true))[0].rate
      }
    },
    async onSubmit () {
      if (this.validate()) {
        try {
          this.error = null
          if (!this.scheduleData.variableInterestId) {
            const ratesData = this.variableInterests.filter((interest) => interest.name === 'FIXED')
            this.scheduleData.variableInterestId = ratesData[0].id || 0
          }
          if (this.interestRateName === 'FIXED') {
            this.scheduleData.variableInterestFrom = this.loanData.periodStart
            this.scheduleData.variableInterestUntil = this.loanData.periodEnd
          }
          await this.updateLoan({
            id: this.loan.id,
            loanData: {
              ...this.loanData,
              ...this.scheduleData,
              fees: this.scheduleData.fees.map(({ feeAmount, feeType, feeCalculationType, date }) => ({ feeAmount, date, feeType: feeType.code, feeCalculationType: feeCalculationType.code }))
            }
          })
          this.toggleEditing()
        } catch (e) {
          this.error = e
        }
      }
    },
    feeCalculationTypes (fee) {
      return calculationTypes(fee, this.feeConfigs, this.classifierList('feeCalculationType'), true)
    },
    minDate (fee) {
      return fee.feeType.code.$model === 'AGREEMENT' ? this.$moment.max([this.$moment(this.systemData.nextDayChange), this.$moment(this.loanData.periodStart)]).toDate() : null
    }
  }
}
</script>


<i18n>
en:
  title:                         "Schedule details"
  amount:                        "Contract amount"
  amountVAT:                     "Contract amount with VAT"
  limit:                         "Contract limit"
  dateStartEnd:                  "Start/End date"
  invoiceDay:                    "Invoice day"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty interest"
  scheduleType:                  "Schedule type"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  firstInterestDay:              "1st interest day"
  interestFreq:                  "Interest frequency"
  paybackAmount:                 "Early payback amount"
  amortizationPeriod:            "Amortization period"
  loanBetween:                   "Contract amount must be less than contract limit"
  principalFirstErr:             "Principal first payment day must be between {{ begin }} and {{ end }}"
  interestFirstErr:              "Interest first payment day must be between {{ begin }} and {{ end }}"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  principalVatPeriod:            "Principal VAT period"
  guaranteedPercentageFromAmount:         "Guaranteed percentage amount"
et:
  title:                         "Schedule details"
  amount:                        "Contract amount"
  amountVAT:                     "Contract amount with VAT"
  limit:                         "Contract limit"
  dateStartEnd:                  "Start/End date"
  invoiceDay:                    "Invoice day"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty interest"
  scheduleType:                  "Schedule type"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  firstInterestDay:              "1st interest day"
  interestFreq:                  "Interest frequency"
  paybackAmount:                 "Early payback amount"
  amortizationPeriod:            "Amortization period"
  loanBetween:                   "Contract amount must be less than contract limit"
  principalFirstErr:             "Principal first payment day must be between {{ begin }} and {{ end }}"
  interestFirstErr:              "Interest first payment day must be between {{ begin }} and {{ end }}"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  principalVatPeriod:            "Principal VAT period"
  guaranteedPercentageFromAmount:         "Guaranteed percentage amount"
ru:
  title:                         "Schedule details"
  amount:                        "Contract amount"
  amountVAT:                     "Contract amount with VAT"
  limit:                         "Contract limit"
  dateStartEnd:                  "Start/End date"
  invoiceDay:                    "Invoice day"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty interest"
  scheduleType:                  "Schedule type"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  firstInterestDay:              "1st interest day"
  interestFreq:                  "Interest frequency"
  paybackAmount:                 "Early payback amount"
  amortizationPeriod:            "Amortization period"
  loanBetween:                   "Contract amount must be less than contract limit"
  principalFirstErr:             "Principal first payment day must be between {{ begin }} and {{ end }}"
  interestFirstErr:              "Interest first payment day must be between {{ begin }} and {{ end }}"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  principalVatPeriod:            "Principal VAT period"
  guaranteedPercentageFromAmount:         "Guaranteed percentage amount"
</i18n>
