<template lang = "pug">
.loan-schedule-list.card.animated.fadeIn
  .card-header {{ $t('title') }}
  .card-body
    .table-responsive
      table.table.table-hover
        thead: tr
          th {{ $t('validFrom') }}
          th {{ $t('validUntil') }}
          th {{ $t('reason') }}
          th {{ $t('createdBy') }}
          th {{ $t('status') }}
        tbody
          tr(v-if = 'loanSchedules.length === 0'): td.text-center(colspan = 3) {{ $t('notFound') }}
          template(v-else)
            loan-schedule-list-item(
              v-for         = 'schedule in loanSchedules'
              :value        = '$v.changeReasonModel'
              :key          = 'schedule.id'
              :schedule     = 'schedule'
              :active       = 'schedule.id === selectedSchedule.id'
              @click.native = 'selectSchedule(schedule)'
              @input        = '$v.changeReasonModel.$model = $event'
              @reason       = 'saveReason'
            )
    template(v-if = 'selectedScheduleStatus.code === "PENDING"')
      .form-row
        .col-4
          button.btn.btn-success(
            v-if         = 'product.outgoingPaymentsEnabled && selectedSchedule.increaseLoanAmount'
            :disabled    = 'accepting'
            key          = 'acceptDraftPayout'
            @click       = 'scheduleActivateModal = true'
          ) {{ $t('confirmDraft') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'accepting')

          button.btn.btn-success(
            v-else
            :disabled    = 'accepting'
            key          = 'acceptDraft'
            v-fi-confirm = '{ ok: confirm, message: $t("confirm") }'
          ) {{ $t('confirmDraft') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'accepting')
          | &nbsp;
          button.btn.btn-danger(
            :disabled = 'removing'
            @click    = 'remove'
          ) {{ $t('removeDraft') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'removing')
        .col-auto
          fi-pagination(
            v-if    = 'schedulePages > 1'
            :pages  = 'schedulePages'
            v-model = 'schedulePagination.page'
          )

    template(v-else-if = 'selectedScheduleStatus.code === "ACTIVE"')
      .form-row.flex-between
        .col-auto
          button.btn.btn-primary(
            :disabled = 'creating'
            @click    = 'create'
          ) {{ $t('createDraft') }}
        .col-auto
          fi-pagination(
            v-if    = 'schedulePages > 1'
            :pages  = 'schedulePages'
            :limit  = 5
            v-model = 'schedulePagination.page'
          )
        .col-auto
          .flex-end
            button.btn.btn-success.ml-1(
              v-if      = 'loan.extendable'
              :disabled = 'creating'
              @click    = 'extend'
            ) {{ $t('extend') }}
            button.btn.btn-success.ml-1(
              :disabled = 'creating'
              @click    = 'payback'
            ) {{ $t('payback') }}
            button.btn.btn-success.ml-1(
              :disabled = 'creating'
              @click    = 'terminate'
            ) {{ $t('terminate') }}
              i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'creating')
    template(v-else)
      fi-pagination(
        v-if    = 'schedulePages > 1'
        :pages  = 'schedulePages'
        v-model = 'schedulePagination.page'
      )

  loan-schedule-termination-modal(
    v-model           = 'terminationModal'
    :loader           = 'creating'
    :termination-fee  = 'terminationFee'
    :ok-action        = 'handleTerminateSubmit'
    :fee-configs      = 'feeConfigs'
  )
  loan-schedule-extend-modal(
    v-model        = 'extendModal'
    :loader        = 'creating'
    :ok-action     = 'updateLoanExtension'
  )
  loan-schedule-payback-modal(
    v-model           = 'paybackModal'
    :loader           = 'creating'
    :termination-fee  = 'terminationFee'
    :ok-action        = 'handlePaybackSubmit'
    :schedule         = 'selectedSchedule'
    :fee-configs      = 'feeConfigs'
  )
  loan-initiate-outgoing-payment-modal(
    v-if           = 'product.outgoingPaymentsEnabled && scheduleActivateModal'
    v-model        = 'scheduleActivateModal'
    :loan          = 'loan'
    :product       = 'product'
    :loader        = 'accepting'
    :callback      = 'confirm'
    :schedule      = 'selectedSchedule'
    :payout-amount = 'payoutAmount'
  )
</template>


<script>
import { schedulePagination } from '@/const'
import { isLoanLeasing } from '@/mixins'
import { validation, validators } from '@/validation'
import FiPagination from '@/components/FiPagination'
import LoanInitiateOutgoingPaymentModal from '@/views/loans/LoanInitiateOutgoingPaymentModal'
import LoanScheduleExtendModal from '@/views/loans/LoanSchedule/LoanScheduleExtendModal'
import LoanSchedulePaybackModal from '@/views/loans/LoanSchedule/LoanSchedulePaybackModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoanScheduleListItem from './LoanScheduleListItem'
import LoanScheduleTerminationModal from './LoanScheduleTerminationModal'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'

export default {
  name: 'loan-schedule-list',

  components: {
    LoanInitiateOutgoingPaymentModal,
    LoanScheduleExtendModal,
    LoanSchedulePaybackModal,
    LoanScheduleListItem,
    LoanScheduleTerminationModal,
    FiPagination
  },

  mixins: [validation, isLoanLeasing],

  model: {
    prop: 'selectedSchedule'
  },

  props: {
    loan: {
      type: Object,
      required: true
    },
    schedules: {
      type: Array,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    selectedSchedule: {
      type: Object,
      default: () => ({})
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    extendModal: false,
    scheduleActivateModal: false,
    terminationModal: false,
    paybackModal: false,
    changeReasonComment: localStorage.getItem(`schedule${vm.loan.id}_reason`),
    schedulePagination: { ...schedulePagination, ...pick(vm.$route.query, Object.keys(schedulePagination)) }
  }),

  computed: {
    ...mapState('loans', ['schedulePages', 'loanSchedules', 'loanActiveSchedule']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName']),
    payoutAmount () {
      const { increaseLoanAmount, amount, amountWithVat } = this.selectedSchedule
      return increaseLoanAmount || (this.isLeasingGroup ? amountWithVat : amount)
    },
    accepting () {
      return this.$vueLoading.isLoading('loan:schedule:accept:save')
    },
    removing () {
      return this.$vueLoading.isLoading('loan:schedule:remove')
    },
    creating () {
      return this.$vueLoading.isLoading(['loan:schedule:save', 'loan:extension:save'])
    },
    changeReasonModel: {
      get () {
        return this.changeReasonComment ?? this.selectedSchedule.changeReasonComment
      },
      set (reason) {
        this.changeReasonComment = reason
        localStorage.setItem(`schedule${this.loan.id}_reason`, reason)
      }
    },
    selectedScheduleStatus () {
      const { status = {} } = this.selectedSchedule
      return status
    },
    terminationFee () {
      return {
        ...this.loanProduct.fees.find(({ feeType }) => feeType.code === 'TERMINATION')
      }
    },
    searchQuery () {
      return pickBy({ ...this.schedulePagination })
    }
  },

  watch: {
    changeReasonModel: {
      handler (newComment) {
        const { changeReasonComment } = this.selectedSchedule
        if (newComment !== changeReasonComment) {
          this.$v.$touch()
        }
      },
      immediate: true
    },
    'schedulePagination.page': {
      handler () {
        this.search()
      },
      deep: true
    },
    '$route.query': {
      async handler (query) {
        await this.loadLoanSchedules({ filters: query })
      },
      deep: true
    }
  },

  validations: {
    changeReasonModel: {
      required: validators.required,
      maxLength: validators.maxLength(50)
    }
  },

  methods: {
    ...mapActions('loans', [
      'acceptLoanSchedule',
      'removeLoanSchedule',
      'loadLoanSchedule',
      'loadLoanSchedules',
      'updateLoanSchedule',
      'createPendingLoanSchedule',
      'terminateLoan',
      'paybackLoan',
      'saveLoanScheduleChangeReason',
      'updateLoanExtension'
    ]),
    confirm ({ done, paymentData } = {}) {
      // const activeSchedule = this.loanSchedules.find((loanSchedule) => loanSchedule.status.code === 'ACTIVE')
      // let paymentWayId = paymentData?.loanRelatedPayments[0]?.paymentWayId || 2
      // if (paymentData?.loanRelatedPayments.some(({ paymentWayId }) => paymentWayId === 1)) {
      //   paymentWayId = 1
      // }
      // const data = [{
      //   amount: this.selectedSchedule.amount - activeSchedule.amount,
      //   beneficiaryExternalAccountNumber: paymentData?.loanRelatedPayments[0]?.beneficiaryExternalAccountNumber || this.loan.account.number,
      //   beneficiaryName: this.loan.customerBorrower.customerName,
      //   beneficiaryIdCode: this.loan.customerBorrower.idCode,
      //   customerId: this.loan.customerBorrower.id,
      //   paymentWayId: paymentWayId
      // }]
      this.acceptLoanSchedule({ id: this.loan.id, changeReasonComment: this.changeReasonModel, loanRelatedPayments: paymentData?.loanRelatedPayments ? paymentData.loanRelatedPayments : [] })
      localStorage.removeItem(`schedule${this.loan.id}_reason`)
      // eslint-disable-next-line no-unused-expressions
      done?.()
      this.schedulePagination.page = 0
    },
    async remove () {
      await this.removeLoanSchedule({ id: this.loan.id })
      if (this.schedulePagination.page === 0) {
        this.selectSchedule(this.loanSchedules[0])
        await this.loadLoanSchedules({ filters: {} })
      } else {
        this.schedulePagination.page = 0
      }
    },
    create () {
      this.createPendingLoanSchedule({ id: this.loan.id })
      this.schedulePagination.page = 0
    },
    terminate () {
      this.terminationModal = true
    },
    payback () {
      this.paybackModal = true
    },
    extend () {
      this.extendModal = true
    },
    async saveReason (changeReasonComment) {
      await this.saveLoanScheduleChangeReason({ loanId: this.loan.id, changeReasonComment })
      this.$v.$reset()
      localStorage.removeItem(`schedule${this.loan.id}_reason`)
    },
    selectSchedule (schedule) {
      this.$emit('input', schedule)
    },
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 500
    ),
    handleTerminateSubmit (data) {
      this.terminateLoan(data)
      this.schedulePagination.page = 0
    },
    handlePaybackSubmit (data) {
      this.paybackLoan(data)
      this.schedulePagination.page = 0
    }
  }
}
</script>


<i18n>
en:
  title:        "Schedule history"
  reason:       "Reason for schedule change"
  status:       "Status"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  createDraft:  "Change"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  terminate:    "Full repayment"
  payback:      "Partial repayment"
  extend:       "Extend"
  confirm:      "Please confirm that Customer has confirm change and signed it"
  createdBy:    "Created by"
et:
  title:        "Schedule history"
  reason:       "Reason for schedule change"
  status:       "Status"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  createDraft:  "Change"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  terminate:    "Full repayment"
  payback:      "Partial repayment"
  extend:       "Extend"
  confirm:      "Please confirm that Customer has confirm change and signed it"
  createdBy:    "Created by"
ru:
  title:        "Schedule history"
  reason:       "Reason for schedule change"
  status:       "Status"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  createDraft:  "Change"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  terminate:    "Full repayment"
  payback:      "Partial repayment"
  extend:       "Extend"
  confirm:      "Please confirm that Customer has confirm change and signed it"
  createdBy:    "Created by"
</i18n>

<style scoped lang="scss">
  .flex-end {
    display: flex;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

</style>
