<template lang = "pug">
.loan-payment-list-item
  loan-payment-relation-form(
    v-if              = 'isEditing'
    v-model           = 'isEditing'
    :payment-relation = 'paymentRelation'
    :loading          = '$vueLoading.isLoading(`paymentRelation:${paymentRelation.relationId}:update:save`)'
    @submit           = 'submit'
  )
  template(v-else)
    fi-data-field(:label = '$t("paymentWay")') {{ paymentWay.human }}
      .fa-pull-right
        button.btn.btn-link.py-0.px-1.btn-sm(
          v-if           = 'editable'
          @click.prevent = 'isEditing = true'
        ): i.far.fa-edit.fa-lg
        button.btn.btn-link.py-0.px-1.text-danger.btn-sm(
          v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
          :disabled    = 'deleting'
        )
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'deleting')
          i.far.fa-trash-alt.fa-lg(v-else)
    fi-data-field(:label = '$t("beneficiaryName")') {{ paymentRelation.beneficiaryName }}
    fi-data-field(:label = '$t("beneficiaryIBAN")') {{ paymentRelation.beneficiaryExternalAccountNumber }}
    fi-data-field(:label = '$t("amount")') {{ paymentRelation.amount | money}}
    fi-data-field(:label = '$t("description")') {{ paymentRelation.description }}
    fi-data-field(:label = '$t("referenceNumber")') {{ paymentRelation.referenceNumber }}
    fi-data-field(:label = '$t("subtractFee")')
      fi-switch(
        :value = 'paymentRelation.subtractFeeAccount'
        disabled
      )
</template>

<script>
import FiDataField from '@/components/FiDataField'
import FiSwitch from '@/components/FiSwitch'
import LoanPaymentRelationForm from '@/views/loans/LoanTerms/LoanPaymentRelationForm'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'loan-payment-list-item',

  components: { FiSwitch, LoanPaymentRelationForm, FiDataField },

  props: {
    paymentRelation: {
      type: Object,
      default: null
    },
    editable: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    editing: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    isEditing: {
      get () {
        return this.editing
      },
      set (value) {
        this.editing = value
        this.$emit('update:editable', !value)
      }
    },
    paymentWay () {
      return this.classifierById('paymentWays', this.paymentRelation.paymentWayId)
    },
    deleting () {
      return this.$vueLoading.isLoading(`paymentRelation:${this.paymentRelation.relationId}:delete`)
    }
  },

  methods: {
    ...mapActions('loans', []),
    remove ({ done }) {
      this.$emit('remove', this.paymentRelation.relationId)
      done()
    },
    submit (paymentRelationData) {
      this.$emit('save', {
        done: () => {
          this.isEditing = false
        },
        paymentRelationId: this.paymentRelation.relationId,
        paymentRelationData
      })
    }
  }
}
</script>
