<template lang = 'pug'>
fi-modal.loan-schedule-extend-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("period")'
      :state = '!$v.loanData.extensionDays.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.loanData.extensionDays.required') {{ $t('common:required') }}
      fi-select(
        v-model = '$v.loanData.extensionDays.$model'
        :options = 'extensionSteps'
        :class  = '{ "is-invalid": $v.loanData.extensionDays.$error }'
        required
        sm
      )
    fi-form-field(:label = '$t("fee")')
      .form-control-plaintext {{ extensionFee.feeAmount | money }}
  template(#modal-footer)
    button.btn.btn-success(
      :disabled      = 'loader'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:activate') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import { isOverdraft } from '@/mixins'
import { validation, validators } from '@/validation/index'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'loan-schedule-extend-modal',

  components: {
    FiSelect,
    FiFormField,
    FiMoneyInput,
    FiModal
  },

  mixins: [
    validation,
    isOverdraft
  ],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    okAction: {
      type: Function,
      default: null
    },
    loader: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      loanData: {
        extensionDays: '',
        skipPreview: true
      }
    }
  },

  computed: {
    ...mapState('loans', ['loan', 'loanProduct']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById']),
    ...mapGetters('settings', ['settingByKey']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    extensionFee () {
      return this.loanProduct.fees.find(({ feeType }) => /AUTO_EXTENSION/i.test(feeType.code)) || {}
    },
    extensionSteps () {
      let { automaticExtensionDaysMax, automaticExtensionDaysMin, automaticExtensionDaysSliderStep } = this.loanProduct
      const period = automaticExtensionDaysMax - automaticExtensionDaysMin
      if (!period) {
        automaticExtensionDaysSliderStep = 1
      }
      const steps = (period - period % automaticExtensionDaysSliderStep) / automaticExtensionDaysSliderStep + 1 || 0
      return Array.from({ length: steps }, (_, idx) => automaticExtensionDaysMin + automaticExtensionDaysSliderStep * idx).filter(Boolean)
    }
  },

  validations () {
    return {
      loanData: {
        extensionDays: {
          required: validators.requiredIf(function () {
            return this.feeRequired
          })
        }
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.okAction) {
          await this.okAction({ id: this.loan.id, data: this.loanData })
        } else {
          this.$emit('submit', { id: this.loan.id, data: this.loanData })
        }
        this.$refs.modal && this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  title:  "Loan extension"
  period: "Extension period, days"
  fee:    "Extension fee"
et:
  title:  "Loan extension"
  period: "Extension period, days"
  fee:    "Extension fee"
ru:
  title:  "Loan extension"
  period: "Extension period, days"
  fee:    "Extension fee"
</i18n>
