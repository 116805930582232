<template lang="pug">
.loan-schedule-view.animated.fadeIn
  v-loading(:loader = 'loader')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    template(v-if = 'loanSchedule')
      .card-deck.mb-4(v-if = 'loanActivated')
        loan-overdraft-term-list(
          v-if              = 'isOverdraftProduct'
          v-model           = 'selectedTerm'
          :loan             = 'loan'
          :terms            = 'loanSchedules'
          :fee-configs      = 'feeConfigs'
          :current-schedule = 'selectedSchedule'
        )
        template(v-else)
          loan-schedule-list(
            v-model             = 'selectedSchedule'
            :loan               = 'loan'
            :product            = 'loanProduct'
            :schedules          = 'loanSchedules'
            :fee-configs        = 'feeConfigs'
          )
          loan-schedule-interest-period-list(
            :interests = '[loanSchedule]'
            :editable  = 'interestsEditable'
          )
      .card-deck.mb-4(v-else-if = '!isOverdraftProduct')
        loan-schedule-interest-period-list(
          :interests = '[loanSchedule]'
          :editable  = 'interestsEditable'
        )
      .row.animated.fadeIn
        .col-lg-4
          loan-overdraft-term-details(
            v-if           = 'isOverdraftProduct'
            :loan          = 'loan'
            :product       = 'loanProduct'
            :term          = 'loanSchedule'
            :fee-configs   = 'feeConfigs'
            :editable.sync = 'editable'
          )
          component(
            v-else
            :is                = 'loanScheduleComponent'
            :loan              = 'loan'
            :product           = 'loanProduct'
            :customer          = 'loanCustomer'
            :editable.sync     = 'editable'
            :flexible          = 'isFlexibleSchedule'
            :schedule          = 'loanSchedule'
            :fee-configs       = 'feeConfigs'
          )
          loan-schedule-control-numbers(:schedule = 'loanSchedule')
        .col
          component(
            :is               = 'scheduleComponent'
            :schedule-status  = 'scheduleStatus'
            :flexible         = 'isFlexibleSchedule'
            :editable.sync    = 'editable'
            :schedule         = 'loanSchedule'
          )
</template>


<script>
import { loanStatuses } from '@/const'
import { isOverdraft } from '@/mixins'
import LoanOverviewSchedule from '@/views/loans/LoanOverview/LoanOverviewSchedule'
import LoanOverviewScheduleOverdraft from '@/views/loans/LoanOverview/LoanOverviewScheduleOverdraft'
import LoanOverdraftTermDetails from '@/views/loans/LoanSchedule/LoanOverdraftTermDetails'
import LoanOverdraftTermList from '@/views/loans/LoanSchedule/LoanOverdraftTermList'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoanScheduleList from './LoanSchedule/LoanScheduleList'
import LoanScheduleDetails from './LoanSchedule/LoanScheduleDetails'
import LoanScheduleControlNumbers from './LoanSchedule/LoanScheduleControlNumbers'
import LoanScheduleParameters from './LoanSchedule/LoanScheduleParameters'
import LoanScheduleInterestPeriodList from './LoanSchedule/LoanScheduleInterestPeriodList'

export default {
  name: 'loan-schedule-view',

  components: {
    LoanOverdraftTermList,
    LoanOverdraftTermDetails,
    LoanOverviewSchedule,
    LoanScheduleParameters,
    LoanScheduleList,
    LoanScheduleDetails,
    LoanScheduleControlNumbers,
    LoanScheduleInterestPeriodList,
    LoanOverviewScheduleOverdraft
  },

  mixins: [isOverdraft],

  data: (vm) => ({
    selectedTerm: '',
    editing: false
  }),

  computed: {
    ...mapState('loans', ['loan', 'loanProduct', 'loanCustomer', 'loanSchedule', 'loanSchedules', 'loanInterests']),
    ...mapGetters('loans', ['scheduleById']),
    ...mapGetters('classifiers', ['classifierByName']),
    ...mapState('products', ['feeConfigs']),
    loanActivated () {
      const { inserted, signed, pending, deleted } = loanStatuses
      return ![inserted, signed, pending, deleted].includes(this.loan.status.code)
    },
    loanScheduleComponent () {
      return this.loanActivated ? 'LoanScheduleParameters' : 'LoanScheduleDetails'
    },
    scheduleStatus () {
      return this.classifierByName(this.loanSchedule?.status?.classifier, this.loanSchedule?.status?.code) ?? {}
    },
    loader () {
      return [...this.isOverdraftProduct ? ['overdraft:terms:fetch'] : ['loan:schedule:fetch', 'loan:schedules:fetch'], 'loan:customer:fetch']
    },
    isFlexibleSchedule () {
      return /FLEXIBLE_PRINCIPAL/i.test(this.loanSchedule?.scheduleType?.code)
    },
    scheduleComponent () {
      return this.isOverdraftProduct ? 'LoanOverviewScheduleOverdraft' : 'LoanOverviewSchedule'
    },
    editable: {
      get () {
        return !this.editing
      },
      set (value) {
        this.editing = value
      }
    },
    interestsEditable () {
      const { complete, pending } = loanStatuses
      return !this.loanActivated || ![complete, pending].includes(this.scheduleStatus?.code)
    },
    selectedSchedule: {
      get () {
        return this.loanSchedule
      },
      async set ({ id }) {
        await this.loadLoanSchedule({ scheduleId: id })
      }
    }
  },

  watch: {
    loanSchedule () {
      this.editable = false
      // !this.isOverdraftProduct && this.loadLoanInterests()
    }
  },

  async created () {
    if (this.isOverdraftProduct) {
      await this.loadLoanOverdraftTerms()
      await this.loadLoanOverdraftTerm({ termId: 'last' })
    } else {
      await this.loadLoanSchedules({ filters: this.searchQuery })
      await this.loadLoanActiveSchedule()
      await this.loadLoanSchedule({ scheduleId: 'last' })
    }
    await this.loadLoanCustomer()
    await this.loadFeeConfig({ group: this.loanProduct.group })
    await this.loadLoanRelations({ loanId: this.loan.id })
  },

  methods: {
    ...mapActions('products', ['loadFeeConfig']),
    ...mapActions('loans', [
      'loadLoanCustomer',
      'loadLoanSchedules',
      'loadLoanRelations',
      'loadLoanActiveSchedule',
      'loadLoanSchedule',
      'loadLoanOverdraftTerms',
      'loadLoanOverdraftTerm',
      'loadLoanInterests'
    ])
  }
}
</script>
