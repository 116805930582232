<template lang='pug'>
  .loan-message-form-fields
    fi-form-field(
      :label      = '$t("language")'
      :label-cols = 3
    )
      fi-select(
        v-model.number = 'formData.languageId.$model'
        :options       = 'languages'
        sm
      )
    fi-form-field(
      v-if        = 'storeType === "factoring"'
      :label      = '$t("thirdParty")'
      :label-cols = 3
    )
      fi-multiselect(
        v-model          = 'selectedThirdPartiesModel'
        :options         = 'filteredThirdParties'
      )
    fi-form-field(
      :label     = '$t("to")'
      label-cols = 3
    )
      fi-select(
        v-if     = 'isEmailChannel'
        v-model  = 'formData.customerEmail.$model'
        :options = 'thirdPartyToEmails'
        required
        sm
      )
      fi-select(
        v-else
        v-model  = 'formData.customerPhoneNumber.$model'
        :options = 'thirdPartyToPhoneNumbers'
        required
        sm
      )
    fi-form-field(
      :label      = '$t("sendTo") '
      :label-cols = 3
    )
      fi-multiselect(
        v-model          = 'selectedRecipientsModel'
        :options         = 'recipientOptions'
        :loading         = '$vueLoading.isLoading("loans:systemCustomers:fetch")'
        :placeholder     = '$t("common:selectBlank")'
        multiple
        @search          = '$listeners.search'
      )
        template(#option='{ option }') {{ option.text}}
          .small(v-if = 'option.role') {{ option.role }}
    fi-form-field(
      :label      = ' $t("template") '
      :label-cols = 3
    )
      i.fa.fa-spinner.fa-pulse.ml-1(
        v-if = 'loadingMessageTemplate'
        slot = 'label'
      )
      fi-select(
        v-model.number = 'templateModel'
        :disabled      = 'templateOverride'
        :options       = 'sortTemplates'
        sm
      )

    fi-form-field.animated.fadeIn(
      v-if        = 'isEmailChannel'
      :label      = '$t("subject")'
      :label-cols = 3
      :state      = '!formData.subject.$error'
    )
      template(#error)
        .error-message(v-if = '!formData.subject.required') {{ $t('common:required') }}
      template(#label)
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingMessageTemplate')
      input.form-control.form-control-sm(
        v-model.trim = 'formData.subject.$model'
        type         = 'text'
        :disabled    = 'loadingMessageTemplate'
        :class       = '{ "is-invalid": formData.subject.$error }'
      )

    fi-form-field(
      :label      = '$t("message")'
      :label-cols = 3
      :state      = '!formData.text.$error'
    )
      template(#label)
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loadingMessageTemplate')
      template(#error)
        .error-message(v-if = '!formData.text.required') {{ $t('common:required') }}
      fi-rich-text-editor(v-model   = 'formData.text.$model'
                          :disabled = 'loadingMessageTemplate'
                          :class    = '{ "is-invalid": formData.text.$error }')

    fi-form-field.mt-1.animated.fadeIn(
      v-if       = 'isEmailChannel'
      :label     = '$t("attachments")'
      label-cols = 3
    )
      .form-row
        .col-auto
          .input-group
            .input-group-prepend
              span.input-group-text
                i.fa.fa-file-alt
            fi-select(
              v-model.number = 'formData.documentCategoryId.$model'
              :options       = 'categories'
              :placeholder   = '$t("category")'
            )
        .col
          fi-file-input.form-control-sm(
            v-model = 'formData.files.$model'
            multiple
          )
</template>

<script>
import api from '@/api'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiFileInput from '@/components/FiFileInput'
import FiMultiselect from '@/components/FiMultiselect'

export default {
  name: 'loan-message-form-fields',

  components: {
    FiRichTextEditor,
    FiMultiselect,
    FiFileInput,
    FiSelect,
    FiFormField
  },

  props: {
    formData: {
      type: Object,
      required: true
    },
    templates: {
      type: Array,
      required: true
    },
    templateOverride: {
      type: Boolean,
      default: false
    },
    contract: {
      type: Object,
      required: true
    },
    storeModule: {
      type: String,
      required: true
    },
    emailOptions: {
      type: Array,
      required: true
    },
    phoneOptions: {
      type: Array,
      required: true
    },
    recipientOptions: {
      type: Array,
      required: true
    },
    messageTemplate: {
      type: Object,
      default: () => ({})
    },
    categories: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    thirdParties: {
      type: Array,
      required: false,
      default: null
    },
    storeType: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    selectedRecipients: null,
    selectedThirdParties: null,
    filteredThirdParties: null,
    templateId: ''
  }),

  computed: {
    sortTemplates () {
      const list = this.templates
      const sortList = list.sort(function (a, b) {
        const nameA = a.text.toLowerCase()
        const nameB = b.text.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    },
    loadingMessageTemplate () {
      return this.$vueLoading.isLoading('contract:message:template:fetch')
    },
    selectedThirdPartiesModel: {
      get () {
        return this.selectedThirdParties
      },
      set (selected) {
        this.selectedThirdParties = selected
        this.$emit('select-party', this.selectedThirdParties)
      }
    },
    selectedRecipientsModel: {
      get () {
        return this.selectedRecipients
      },
      set (selected) {
        this.selectedRecipients = selected
        this.formData.recipients.$model = selected.map(item => item.value) || []
      }
    },
    isEmailChannel () {
      return /email/i.test(this.formData.channel.$model)
    },
    templateModel: {
      get () {
        return this.templateId
      },
      set (templateId) {
        this.templateId = templateId
        this.formData.templateId.$model = templateId
        this.$emit('template-change', templateId)
      }
    },
    chosenThirdParty () {
      if (this.selectedThirdParties) {
        return this.thirdParties.find(el => el.customerId === this.selectedThirdParties.id)
      }
      return { id: 0 }
    },
    thirdPartyToEmails () {
      const mainCustomersEmails = this.chosenThirdParty.mainCustomer?.emails.filter(el => el.main === true).map(el => el.value) || []
      const customersEmails = this.chosenThirdParty.customer?.emails.filter(el => el.main === true).map(el => el.value) || []
      const result = [...mainCustomersEmails, ...customersEmails]
      return result.filter((elem, index) => result.indexOf(elem) === index).length ? result.filter((elem, index) => result.indexOf(elem) === index) : this.emailOptions
    },
    thirdPartyToPhoneNumbers () {
      return this.chosenThirdParty.mainCustomer?.phoneNumbers.filter(el => el.main === true).map(el => el.value).length ? this.chosenThirdParty.mainCustomer?.phoneNumbers.filter(el => el.main === true).map(el => el.value) : this.phoneOptions
    }
  },

  async created () {
    await this.loadMainCustomers()
    if (this.storeType === 'factoring') {
      this.filteringThirdParties()
    }
    if (this.filteredThirdParties?.length === 1) {
      this.selectedThirdParties = this.filteredThirdParties[0]
    }
  },

  methods: {
    removeDuplicates (arr) {
      const result = []
      const duplicatesIndices = []
      arr.forEach((current, index) => {
        if (duplicatesIndices.includes(index)) return
        result.push(current)
        for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
          const comparison = arr[comparisonIndex]
          const currentKeys = Object.keys(current)
          const comparisonKeys = Object.keys(comparison)
          if (currentKeys.length !== comparisonKeys.length) continue
          const currentKeysString = currentKeys.sort().join('').toLowerCase()
          const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase()
          if (currentKeysString !== comparisonKeysString) continue
          let valuesEqual = true
          for (let i = 0; i < currentKeys.length; i++) {
            const key = currentKeys[i]
            if (current[key] !== comparison[key]) {
              valuesEqual = false
              break
            }
          }
          if (valuesEqual) duplicatesIndices.push(comparisonIndex)
        }
      })
      return result
    },
    filteringThirdParties () {
      const newThirdPartys = []
      this.thirdParties.forEach(el => {
        newThirdPartys.push({ text: `${el.customer.fullName} (${el.customer.idCode})`, id: el.customer.id })
      })
      this.filteredThirdParties = this.removeDuplicates(newThirdPartys)
      this.filteredThirdParties.forEach((el, index) => {
        el.value = index
      })
    },
    loadMainCustomers () {
      if (this.thirdParties) {
        this.thirdParties.forEach(async el => {
          const { data: customer } = await api.getCustomer({ id: el.customerId })
          el.mainCustomer = customer?.referredRelations?.find(({ mainContact, active }) => mainContact && active)?.customer ?? el.customer ?? {}
        })
      }
    }
  }
}
</script>


<i18n>
en:
  sendTo:     "CC"
  coBorrower: "Co-borrower"
  warrantor:  "Warrantor"
  thirdParty: "Third party"
et:
  sendTo:     "CC"
  coBorrower: "Co-borrower"
  warrantor:  "Warrantor"
  thirdParty: "Third party"
ru:
  sendTo:     "CC"
  coBorrower: "Созаемщик"
  warrantor:  "Поручитель"
  thirdParty: "Треть лицо"
</i18n>
