<template lang = 'pug'>
fi-modal.loan-schedule-payback-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("paybackAmount")'
      :state = '!$v.paybackData.earlyPaybackAmount.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.paybackData.earlyPaybackAmount.required') {{ $t('common:required') }}
        .error-message(v-if = '!$v.paybackData.earlyPaybackAmount.lte') {{ $t('lte', { value: $v.paybackData.earlyPaybackAmount.$params.lte.value }) }}
      fi-money-input.form-control.form-control-sm(
        v-model.number = 'paybackData.earlyPaybackAmount'
        :class         = '{ "is-invalid": $v.paybackData.earlyPaybackAmount.$error }'
      )
    fi-form-field(
      :label = '$t("paybackDate")'
      :state = '!$v.paybackData.earlyPaybackDate.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.paybackData.earlyPaybackDate.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model = 'paybackData.earlyPaybackDate'
        :class  = '{ "is-invalid": $v.paybackData.earlyPaybackDate.$error }'
        :min-date = 'systemData.nextDayChange'
        :max-date = 'latestInvoiceDate'
        sm
      )

    fi-form-field(
      :label = '$t("feeTypeName")'
      :state = '!$v.paybackData.feeProductFeeId.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.paybackData.feeProductFeeId.required') {{ $t('common:required') }}
      fi-select(
        v-model.number = 'paybackData.feeProductFeeId'
        :options       = 'optionsFromFees'
        :class         = '{ "is-invalid": $v.paybackData.feeProductFeeId.$error }'
        required
        sm
      )

    fi-form-field(
      :label     = '$t("feeAmount")'
      :state     = '!$v.paybackData.feeAmount.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.paybackData.feeAmount.required') {{ $t('common:required') }}
        .error-message(v-if = '!$v.paybackData.feeAmount.gte') {{ $t('gte', { value: $v.paybackData.feeAmount.$params.gte.value }) }}
        .error-message(v-if = '!$v.paybackData.feeAmount.lte') {{ $t('lte', { value: $v.paybackData.feeAmount.$params.lte.value }) }}
      .form-row
        .col: fi-select(
          v-model.number = 'paybackData.feeCalculationTypeId'
          :options       = 'calculationTypes'
          :disabled      = '!selectedFee.feeOverride'
          required
          sm
        )
        .col
          percent-input.form-control.form-control-sm(
            v-if           = 'percentageFee'
            v-model.number = 'paybackData.feeAmount'
            :class         = '{ "is-invalid": $v.paybackData.feeAmount.$error }'
            :disabled      = '!feeEditable(selectedFee)'
          )
          fi-money-input.form-control.form-control-sm(
            v-else
            v-model.number = 'paybackData.feeAmount'
            :class         = '{ "is-invalid": $v.paybackData.feeAmount.$error }'
            :disabled      = '!feeEditable(selectedFee)'
          )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'loader'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import { validation, validators } from '@/validation/index'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import { mapActions, mapGetters, mapState } from 'vuex'
import { calculationTypes, convertFee, convertFeeRange, feePercentage } from '@/helpers'
import { feeEditableMixin } from '@/mixins'

export default {
  name: 'loan-schedule-payback-modal',

  components: {
    FiSelect,
    FiFormField,
    FiDatepicker,
    FiMoneyInput,
    PercentInput,
    FiModal
  },

  mixins: [
    validation,
    feeEditableMixin
  ],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    okAction: {
      type: Function,
      default: null
    },
    loader: {
      type: Boolean,
      required: true
    },
    schedule: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data () {
    return {
      paybackData: {
        earlyPaybackAmount: null,
        earlyPaybackDate: '',
        feeAmount: null,
        feeProductFeeId: '',
        feeCalculationTypeId: ''
      }
    }
  },

  computed: {
    ...mapState('loans', ['loan', 'loanProduct', 'systemData']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    ...mapGetters('settings', ['settingByKey']),
    optionsFromFees () {
      return this.loanProduct.fees.filter(({ feeType }) => !this.feeConfigs[feeType.code])
        .map(
          ({ id, feeType }) => (
            {
              value: id,
              text: this.classifierByName(feeType.classifier, feeType.code)?.human
            }))
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    calculationTypes () {
      return calculationTypes(this.selectedFee, this.feeConfigs, this.classifierList('feeCalculationType'), true)
    },
    percentageFee () {
      return feePercentage(this.classifierById('feeCalculationType', this.paybackData.feeCalculationTypeId).code)
    },
    convertedFeeRange () {
      return convertFeeRange({ ...this.selectedFee, feeCalculationType: this.classifierById('feeCalculationType', this.paybackData.feeCalculationTypeId).code }, this.loan.financial.balance)
    },
    feeEnabled () {
      return this.selectedFee.feeMin !== this.selectedFee.feeMax
    },
    selectedFee () {
      return this.loanProduct.fees.find(({ id }) => id === this.paybackData.feeProductFeeId) || {}
    },
    latestInvoiceDate () {
      return new Date(Math.max(...this.schedule?.loanInvoices?.map(({ paymentDate }) => new Date(paymentDate)) ?? []))
    }
  },

  watch: {
    'paybackData.feeProductFeeId' () {
      const { feeCalculationType, feeAmount } = this.selectedFee
      this.paybackData.feeCalculationTypeId = ''
      this.$nextTick(() => {
        this.paybackData.feeAmount = feeAmount
        this.paybackData.feeCalculationTypeId = this.classifierByName(feeCalculationType?.classifier, feeCalculationType?.code)?.id
      })
    },
    percentageFee (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }

      this.paybackData.feeAmount = convertFee({
        feeValue: this.paybackData.feeAmount,
        isPercentage: this.percentageFee,
        amountValue: this.loan.financial.balance
      })
    }
  },

  validations () {
    return {
      paybackData: {
        earlyPaybackAmount: {
          required: validators.required,
          lte: validators.lte(this.loan.financial.balance)
        },
        earlyPaybackDate: {
          required: validators.required
        },
        feeAmount: {
          gte: validators.gte(this.convertedFeeRange.feeMin),
          lte: validators.lte(this.convertedFeeRange.feeMax)
        },
        feeProductFeeId: {
        }
      }
    }
  },

  methods: {
    ...mapActions('loans', ['terminateLoan']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.paybackData = merge({}, this.paybackData, pick(this.schedule, Object.keys(this.paybackData)))
      this.paybackData.earlyPaybackDate = new Date()
      this.$v.$reset()
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        let paybackData
        if (this.paybackData.feeAmount !== null && this.paybackData.feeProductFeeId) {
          const feeCalculationType = this.classifierById('feeCalculationType', this.paybackData.feeCalculationTypeId).code
          const feeType = this.loanProduct.fees.find(({ id }) => id === this.paybackData.feeProductFeeId) || {}
          paybackData = {
            earlyPaybackAmount: this.paybackData.earlyPaybackAmount,
            earlyPaybackDate: this.paybackData.earlyPaybackDate,
            feeAmount: this.paybackData.feeAmount,
            feeCalculationType: feeCalculationType,
            feeType: feeType.feeType.code
          }
        } else {
          paybackData = {
            earlyPaybackAmount: this.paybackData.earlyPaybackAmount,
            earlyPaybackDate: this.paybackData.earlyPaybackDate
          }
        }
        if (this.okAction) {
          await this.okAction({ id: this.loan.id, data: paybackData })
        } else {
          this.$emit('submit', { id: this.loan.id, data: paybackData })
        }
        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  title:         "Partial repayment"
  paybackDate:   "Date"
  paybackAmount: "Amount"
  feeTypeName:   "Fee name"
  feeAmount:     "Fee amount"
  lte:           "This field value should be less than {{ value }}"
  gte:           "This field value should be greater than {{ value }}"
et:
  title:         "Partial repayment"
  paybackDate:   "Date"
  paybackAmount: "Amount"
  feeTypeName:   "Fee name"
  feeAmount:     "Fee amount"
  lte:           "This field value should be less than {{ value }}"
  gte:           "This field value should be greater than {{ value }}"
ru:
  title:         "Partial repayment"
  paybackDate:   "Date"
  paybackAmount: "Amount"
  feeTypeName:   "Fee name"
  feeAmount:     "Fee amount"
  lte:           "This field value should be less than {{ value }}"
  gte:           "This field value should be greater than {{ value }}"
</i18n>
