<template lang="pug">
tr.loan-schedule-list-item(:class = '{ "table-active": active }')
  td {{ $t('common:formatDate', { value: schedule.validFrom }) }}
  td {{ $t('common:formatDate', { value: schedule.validUntil}) }}
  td.text-nowrap
    fi-form-field(
      v-if = '(status.name === "PENDING") && active'
      label-cols="0"
      :state = '!value.$invalid'
    )
      .input-group
        input.form-control.form-control-sm(
          v-model.trim = 'reasonModel'
          @click.stop
        )
        .input-group-append
          button.btn.btn-primary.p-1(
            v-if = 'value.$dirty'
            :disabled = 'value.$invalid'
            @click.stop = '$emit("reason", value.$model)'
          ) {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("loan:schedule:reason:save")')
      small.pull-right(:class = '{ "text-danger": value.$invalid }') {{ messageLength }} / 50
    template(v-else) {{ schedule.changeReasonComment }}
  td {{ schedule.updatedBy }}
  td: .badge(:class = '`badge-${statusClass}`') {{ status.human }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import { mapGetters } from 'vuex'

const COLOR_MAP = {
  PENDING: 'primary',
  COMPLETE: 'secondary',
  ACTIVE: 'success'
}

export default {
  name: 'loan-schedule-list-item',

  components: { FiFormField },

  props: {
    schedule: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    reasonModel: {
      get () {
        return this.value.$model
      },
      set (reason) {
        this.$emit('input', reason)
      }
    },
    status () {
      return this.classifierById(this.schedule.status.classifier, this.schedule.status.id)
    },
    statusClass () {
      return COLOR_MAP[this.schedule.status.id]
    },
    messageLength () {
      return this.value?.$model?.match(/./g)?.length ?? 0
    }
  }
}
</script>


<style lang="scss">
.loan-schedule-list-item {
  cursor: pointer;
}
</style>


<i18n>
en:
  maxLengthError: "Message is exceeding 50 symbols"
et:
  maxLengthError: "Message is exceeding 50 symbols"
ru:
  maxLengthError: "Message is exceeding 50 symbols"
</i18n>
