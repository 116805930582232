<template lang="pug">
fi-modal.loan-schedule-interest-period-new-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = 'title'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("from")'
      :state = '!$v.interestPeriodData.periodStart.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.interestPeriodData.periodStart.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model        = 'interestPeriodData.periodStart'
        :placeholder   = '$t("datePlaceholder")'
        :class         = '{ "is-invalid": $v.interestPeriodData.periodStart.$error }'
        :enabled-dates = 'enabledStartDates'
      )
    fi-form-field(
      :label = '$t("until")'
      :state = '!$v.interestPeriodData.periodEnd.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.interestPeriodData.periodEnd.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model      = 'interestPeriodData.periodEnd'
        :placeholder = '$t("datePlaceholder")'
        :class       = '{ "is-invalid": $v.interestPeriodData.periodEnd.$error }'
        :min-date    = 'periodEndMinDate'
        :max-date    = 'activeSchedule.periodEnd'
        disabled
      )
    fi-form-field(
      :label = '$t("interest")'
      :state = '!$v.interestPeriodData.rate.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.interestPeriodData.rate.required') {{ $t('common:required') }}
      percent-input.form-control.form-control-sm(
        v-model.number = 'interestPeriodData.rate'
        :decimal-limit = '3'
        :class         = '{ "is-invalid": $v.interestPeriodData.rate.$error }'
        placeholder    = '0.000 %'
        type           = 'text'
        allow-negative
      )

    .text-danger(v-if = '$v.interestPeriodData.$error && !$v.interestPeriodData.notExists') {{ $t('periodExists') }}

  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    )
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import PercentInput from '@/components/PercentInput'
import FiModal from '@/components/FiModal'
import pick from 'lodash/pick'
import { mapGetters, mapActions, mapState } from 'vuex'
import { validation, validators } from '@/validation/index'

export default {
  name: 'loan-schedule-interest-period-new-modal',

  components: {
    FiSelect,
    FiFormField,
    FiDatepicker,
    PercentInput,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    interestPeriod: {
      type: Object,
      default: null
    },
    periods: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      interestPeriodData: {
        periodEnd: '',
        periodStart: '',
        rate: null
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapState('loans', ['loan', 'systemData', 'loanSchedule']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    editing () {
      return !!this.interestPeriod
    },
    saving () {
      if (this.editing) {
        return this.$vueLoading.isLoading(`loan:schedule:interest:${this.interestPeriod.id}:save`)
      }
      return this.$vueLoading.isLoading('loan:schedule:interest:save')
    },
    title () {
      if (this.editing) {
        return this.$t('editTitle', { id: this.interestPeriod.id })
      }
      return this.$t('newTitle')
    },
    activeSchedule () {
      return this.loanSchedule
    },
    periodStartMinDate () {
      return this.$moment(this.systemData.lastDayChange).add(1, 'days').format('YYYY-MM-DD')
    },
    periodStartMaxDate () {
      return this.$moment(this.interestPeriodData.periodEnd || this.activeSchedule.periodEnd).subtract(1, 'day').format('YYYY-MM-DD')
    },
    schedulePendingDates () {
      return this.loanSchedule.loanInvoices.map((el) => {
        if (this.$moment(el.paymentDate).isAfter(this.periodStartMinDate) && this.$moment(el.paymentDate).isBefore(this.periodStartMaxDate)) {
          return this.$moment(el.paymentDate).format('YYYY-MM-DD')
        }
      })
    },
    enabledStartDates () {
      return [
        this.activeSchedule.periodStart,
        ...this.schedulePendingDates,
        this.periodStartMaxDate
      ].filter((date) => this.periods.length ? this.$moment(date).isAfter(this.periods[this.periods.length - 1].periodStart) : true)
    },
    periodEndMinDate () {
      return this.$moment(this.interestPeriodData.periodStart || this.periodStartMinDate).add(1, 'day').format('YYYY-MM-DD')
    }
  },

  validations () {
    return {
      interestPeriodData: {
        periodStart: {
          required: validators.required
        },
        periodEnd: {
          required: validators.required
        },
        rate: {
          required: validators.required
        },
        notExists: (value) => this.editing ||
          !this.periods.find(period => period.periodStart === value.periodStart && period.periodEnd === value.periodEnd)
      }
    }
  },

  methods: {
    ...mapActions('loans', ['createLoanScheduleInterest', 'updateLoanScheduleInterest']),
    resetData () {
      this.$v.$reset()
      Object.assign(this.$data, this.$options.data.call(this))
      this.interestPeriodData = pick(this.interestPeriod, Object.keys(this.interestPeriodData))
      this.interestPeriodData.periodEnd = this.loan.periodEnd
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.editing) {
          await this.updateLoanScheduleInterest({ interestId: this.interestPeriod.id, interestData: this.interestPeriodData })
        } else {
          await this.createLoanScheduleInterest({ interestData: this.interestPeriodData })
        }
        this.onHide()
      }
    }
  }
}
</script>


<i18n>
en:
  type:            "Type"
  from:            "From"
  until:           "Until"
  datePlaceholder: "Select date"
  interest:        "Interest"
  editTitle:       "Edit interest period #{{id}}"
  newTitle:        "Add new interest period"
  periodExists:    "The same interest period already exists"
et:
  type:            "Type"
  from:            "From"
  until:           "Until"
  datePlaceholder: "Select date"
  interest:        "Interest"
  editTitle:       "Edit interest period #{{id}}"
  newTitle:        "Add new interest period"
  periodExists:    "The same interest period already exists"
ru:
  type:            "Type"
  from:            "From"
  until:           "Until"
  datePlaceholder: "Select date"
  interest:        "Interest"
  editTitle:       "Edit interest period #{{id}}"
  newTitle:        "Add new interest period"
  periodExists:    "The same interest period already exists"
</i18n>
