<template lang="pug">
  .loan-overdraft-term-list.card.animated.fadeIn
    .card-header {{ $t('title') }}
    .card-body
      .table-responsive
        table.table.table-hover
          thead: tr
            th {{ $t('validFrom') }}
            th {{ $t('validUntil') }}
            th {{ $t('status') }}
          tbody
            tr(v-if = 'terms.length === 0'): td.text-center(colspan = 3) {{ $t('notFound') }}
            template(v-else)
              loan-overdraft-term-list-item(
                v-for         = 'term in terms'
                :key          = 'term.id'
                :term         = 'term'
                :class        = '{ "table-active": term.id === loanSchedule.id }'
                @click.native = 'select(term)'
              )
      .row
        .col
          template(v-if = 'status.name === "PENDING"')
            button.btn.btn-success(
              :disabled    = 'accepting'
              key          = 'acceptDraft'
              @click       = 'prepareAccept'
            ) {{ $t('confirmDraft') }}
              i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'accepting')
            |&nbsp;
            button.btn.btn-danger(
              :disabled = 'removing'
              @click    = 'remove'
            ) {{ $t('removeDraft') }}
              i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'removing')

          template(v-else-if = 'status.name === "ACTIVE"')
            button.btn.btn-primary(
              :disabled = 'creating'
              @click    = 'create'
            ) {{ $t('createDraft') }}
              i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'creating')
            button.fa-pull-right.btn.btn-success(
              :disabled = 'creating'
              @click    = 'terminate'
            ) {{ $t('terminate') }}
          button.fa-pull-right.btn.btn-secondary.mx-1(@click = 'withdrawalModal = true') {{ $t('payout') }}...


    loan-schedule-termination-modal(
      v-model           = 'terminationModal'
      :loader           = 'terminating'
      :ok-action        = 'terminateLoanOverdraft'
      :termination-fee  = 'terminationFee'
      :fee-configs      = 'feeConfigs'
    )
    loan-initiate-outgoing-payment-modal(
      v-if           = 'withdrawalModal'
      v-model        = 'withdrawalModal'
      :loan          = 'loan'
      :product       = 'loanProduct'
      :loader        = '$vueLoading.isLoading(["overdraft:payout:save", "overdraft:term:activate:save"])'
      :callback      = 'withdrawal'
      :schedule      = 'loanSchedule'
      :payout-amount = 'overdraftPayout'
      free-input
    )
    fi-term-accept-modal(
      v-if             = 'acceptModal'
      v-model          = 'acceptModal'
      :contract-amount = 'loan.amountLimit'
      :fee-types       = 'loanProduct.fees'
      :fee-configs     = 'feeConfigs'
      loader           = 'overdraft:term:activate:save'
      @save            = 'confirm'
    )
</template>


<script>
import LoanOverdraftTermListItem from '@/views/loans/LoanSchedule/LoanOverdraftTermListItem'
import LoanScheduleTerminationModal from './LoanScheduleTerminationModal'

import { mapActions, mapGetters, mapState } from 'vuex'
import LoanInitiateOutgoingPaymentModal from '@/views/loans/LoanInitiateOutgoingPaymentModal'
import FiTermAcceptModal from '@/components/FiTermAcceptModal'
import BigNumber from 'bignumber.js'

export default {
  name: 'loan-overdraft-term-list',

  components: {
    FiTermAcceptModal,
    LoanInitiateOutgoingPaymentModal,
    LoanOverdraftTermListItem,
    LoanScheduleTerminationModal
  },

  props: {
    terms: {
      type: Array,
      required: true
    },
    loan: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    },
    currentSchedule: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data () {
    return {
      acceptModal: false,
      withdrawalModal: false,
      terminationModal: false
    }
  },

  computed: {
    ...mapState('loans', ['loanSchedule', 'loanProduct']),
    ...mapGetters('classifiers', ['classifierById']),
    accepting () {
      return this.$vueLoading.isLoading('overdraft:term:activate:save')
    },
    removing () {
      return this.$vueLoading.isLoading('overdraft:term:delete')
    },
    creating () {
      return this.$vueLoading.isLoading('overdraft:term:copy:save')
    },
    terminating () {
      return this.$vueLoading.isLoading('loan:overdraft:terminate')
    },
    status () {
      return (this.classifierById(this.loanSchedule.status.classifier, this.loanSchedule.status.id)) || {}
    },
    terminationFee () {
      return {
        ...this.loanProduct.fees.find(({ feeType }) => feeType.code === 'TERMINATION')
      }
    },
    overdraftPayout () {
      const { amountLimit, financial } = this.loan
      return new BigNumber(amountLimit).minus(financial.balance).toNumber()
    }
  },

  created () {
    this.loadFeeConfig({ group: this.loanProduct.group })
  },

  methods: {
    ...mapActions('products', ['loadFeeConfig']),
    ...mapActions('loans', [
      'activateLoanOverdraftTerm',
      'deleteLoanOverdraftTerm',
      'loadLoanOverdraftTerms',
      'createLoanOverdraftTermCopy',
      'terminateLoanOverdraft',
      'loadLoanOverdraftTerm',
      'saveOverdraftPayout'
    ]),
    async confirm ({ done, payload }) {
      await this.activateLoanOverdraftTerm({ overdraftId: this.loan.id, termId: this.loanSchedule.id, data: payload })
      done && done()
      this.loadLoanOverdraftTerms({ overdraftId: this.loan.id })
    },
    async remove () {
      await this.deleteLoanOverdraftTerm({ overdraftId: this.loan.id, termId: this.loanSchedule.id })
      this.loadLoanOverdraftTerms({ overdraftId: this.loan.id })
    },
    async create () {
      await this.createLoanOverdraftTermCopy({ overdraftId: this.loan.id, termId: this.loanSchedule.id })
    },
    async select (term) {
      await this.loadLoanOverdraftTerm({ overdraftId: this.loan.id, termId: term.id })
    },
    terminate () {
      this.terminationModal = true
    },
    async withdrawal ({ paymentData, done }) {
      await this.saveOverdraftPayout({ overdraftId: this.loan.id, payoutData: paymentData.loanRelatedPayments })
      done()
      await this.loadLoanOverdraftTerm({ overdraftId: this.loan.id, termId: this.loanSchedule.id })
    },
    prepareAccept () {
      if (this.currentSchedule.pendingForTermination && (this.loanProduct.group === 'OVERDRAFT' || this.loanProduct.group === 'GUARANTEE')) {
        this.confirm({
          done: () => this.$refs?.modal?.hide(),
          payload: {
            amount: '',
            date: '',
            feeType: '',
            feeCalculationType: ''
          }
        })
      } else {
        this.acceptModal = true
      }
    }
  }
}
</script>


<i18n>
en:
  title:        "Schedule history"
  status:       "Status"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  createDraft:  "Change"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  terminate:    "Full repayment"
  payout:       "Payout"
  confirm:      "Please confirm that Customer has confirm change and signed it"
et:
  title:        "Schedule history"
  status:       "Status"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  createDraft:  "Change"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  terminate:    "Full repayment"
  payout:       "Payout"
  confirm:      "Please confirm that Customer has confirm change and signed it"
ru:
  title:        "Schedule history"
  status:       "Status"
  validFrom:    "Valid from"
  validUntil:   "Valid until"
  createDraft:  "Change"
  confirmDraft: "Activate"
  removeDraft:  "Remove"
  terminate:    "Full repayment"
  payout:       "Payout"
  confirm:      "Please confirm that Customer has confirm change and signed it"
</i18n>
