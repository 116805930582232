<template lang='pug'>
fi-modal.loan-payout-relation-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @ok     = 'onSubmit'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    loan-payment-relation-fields(
      :payment-relation = '$v.relationData'
      :accounts         = 'bankAccountOptions'
      @select-customer  = 'selectCustomer'
    )
  template(#modal-footer='{ ok, cancel }')
    button.btn.btn-primary(@click.prevent = 'ok') {{ $t("common:save") }}
    button.btn.btn-secondary(@click.prevent = 'cancel') {{ $t("common:cancel") }}
</template>


<script>
import FiModal from '@/components/FiModal'
import { validation, validators } from '@/validation'
import LoanPaymentRelationFields from '@/views/loans/LoanTerms/LoanPaymentRelationFields'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'
import { paymentRelationData } from '@/const'
import { customerName } from '@/helpers'

export default {
  name: 'loan-payout-relation-modal',

  components: {
    LoanPaymentRelationFields,
    FiModal
  },

  mixins: [validation],

  props: {
    paymentRelation: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    callback: {
      type: Function,
      required: true
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    selectedCustomer: null,
    relationData: {
      ...paymentRelationData,
      ...pick(vm.paymentRelation, Object.keys(paymentRelationData))
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'classifierById']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    bankAccountOptions () {
      const { externalBankAccounts = [], accounts = [] } = this.selectedCustomer ?? {}
      return (this.classifierById('paymentWays', this.relationData.paymentWayId).name === 'external'
        ? externalBankAccounts.map(({ accountExternalNumber }) => accountExternalNumber)
        : accounts.filter(({ accountTypeId }) => /prepayment/i.test(this.classifierById('accountTypes', accountTypeId).name))
          .map(({ number }) => number)) ?? []
    }
  },

  watch: {
    selectedCustomer: {
      async handler (customer) {
        const data = this.relationData
        this.relationData = {
          ...this.relationData,
          ...(customer ? {
            ...pick(this.paymentRelation, Object.keys(this.relationData)),
            paymentWayId: data.paymentWayId,
            beneficiaryName: customerName(customer),
            beneficiaryExternalAccountNumber: this.paymentRelation?.beneficiaryExternalAccountNumber || customer?.defaultExternalAccount?.accountExternalNumber,
            beneficiaryIdCode: customer?.idCode,
            customerId: customer?.id
          } : { customerId: undefined })
        }
      }
    }
  },

  validations: {
    relationData: {
      amount: {
        required: validators.required
      },
      beneficiaryExternalAccountNumber: {
        required: validators.required
      },
      beneficiaryIdCode: {},
      beneficiaryName: {
        required: validators.required
      },
      customerId: {},
      subtractFeeAccount: {},
      description: {},
      referenceNumber: {},
      paymentWayId: {
        required: validators.required
      }
    }
  },

  methods: {
    async onSubmit (event) {
      if (this.validate()) {
        await this.callback({ ...this.paymentRelation, ...this.relationData })
      } else {
        event.preventDefault()
      }
    },
    selectCustomer (customer) {
      this.selectedCustomer = customer
    }
  }
}
</script>


<i18n>
en:
  title:           "Payout relation"
  beneficiary:     "Select customer"
  payoutAccount:   "Payout bank account"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  payoutRelations: "Payouts"
et:
  title:           "Payout relation"
  beneficiary:     "Select customer"
  payoutAccount:   "Payout bank account"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  payoutRelations: "Payouts"
ru:
  title:           "Payout relation"
  beneficiary:     "Select customer"
  payoutAccount:   "Payout bank account"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  payoutRelations: "Payouts"
</i18n>
