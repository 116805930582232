<template lang="pug">
.loan-collateral-mortgage.animated.fadeIn
  .card.mb-0.border-bottom-0
    .card-header {{ $t('title') }}
      .fa-pull-right(v-if = '!editing')
        button.btn.btn-link.py-0.btn-sm(@click.prevent = 'toggleEdit'): i.far.fa-edit.fa-lg
        button.btn.btn-link.py-0.btn-sm.text-danger(v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }')
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'removing')
          i.far.fa-trash-alt.fa-lg(v-else)

    .card-body
      form.form-horizontal(@submit.prevent = 'submit')
        component(:is            = 'mortgageFields'
                  :mortgage-data = 'mortgageData')

        template(v-if = 'editing')
          button.btn.btn-primary(type      = 'submit'
                                 :disabled = 'saving')
            | {{ $t('common:save') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
          | &nbsp;
          button.btn.btn-secondary(:disabled      = 'saving'
                                   @click.prevent = 'resetData') {{ $t('common:cancel') }}

  loan-collateral-mortgage-asset-list(:mortgage = 'mortgage')
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import LoanCollateralMortgageFieldsDisplay from './LoanCollateralMortgageFieldsDisplay'
import LoanCollateralMortgageFieldsEdit from './LoanCollateralMortgageFieldsEdit'
import LoanCollateralMortgageAssetList from './LoanCollateralMortgageAssetList'
import pick from 'lodash/pick'

export default {
  name: 'loan-collateral-mortgage',

  components: {
    LoanCollateralMortgageFieldsDisplay,
    LoanCollateralMortgageFieldsEdit,
    LoanCollateralMortgageAssetList
  },

  props: {
    mortgage: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      mortgageData: {
        mortgageTypeId: '',
        amount: null,
        setDate: '',
        pledgeeId: ''
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    mortgageFields () {
      return this.editing ? 'LoanCollateralMortgageFieldsEdit' : 'LoanCollateralMortgageFieldsDisplay'
    },
    saving () {
      return this.$vueLoading.isLoading(`loan:mortgage:${this.mortgage.id}:save`)
    },
    removing () {
      return this.$vueLoading.isLoading(`loan:mortgage:${this.mortgage.id}:remove`)
    }
  },

  created () {
    this.resetData()
  },

  methods: {
    ...mapActions('loans', ['updateLoanMortgage', 'removeLoanMortgage']),
    toggleEdit () {
      this.editing = !this.editing
    },
    resetData () {
      Object.assign(this.$data, this.$options.data())
      this.mortgageData = pick(this.mortgage, Object.keys(this.mortgageData))
    },
    async remove ({ done }) {
      await this.removeLoanMortgage({ id: this.mortgage.id })
      done()
    },
    async submit () {
      await this.updateLoanMortgage({ id: this.mortgage.id, mortgageData: this.mortgageData })
      this.toggleEdit()
    }
  }
}
</script>


<i18n>
en:
  title:         "Mortgage"
  removeConfirm: "Are you sure you want to delete this mortgage?"
et:
  title:         "Mortgage"
  removeConfirm: "Are you sure you want to delete this mortgage?"
ru:
  title:         "Mortgage"
  removeConfirm: "Are you sure you want to delete this mortgage?"
</i18n>
