<template lang="pug">
.card.loan-details
  .card-header {{ $t('loanDetails') }}
  .card-body
    .row
      .col-lg-6
        fi-data-field(
          v-if   = '!isOverdraftProduct'
          :label = '$t("loanAmount")'
        ) {{ schedule.amount | money }}
        fi-data-field(
          v-if   = 'leasing && schedule.principalWithVat'
          :label = '$t("loanAmountVAT")'
        )
          .form-row
            .col {{ schedule.amountWithVat | money }}
            .col {{ schedule.vatRate | percent }}
        fi-data-field(:label = '$t("loanLimit")') {{ loan.amountLimit | money }}
        template(v-if = 'leasing')
          fi-data-field(:label = '$t("upfrontAmount")')
            .form-row
              .col {{ upfrontAmountInfo | money }}
              .col {{ upfrontAmountPercentageInfo | percent }}
          fi-data-field(:label = '$t("residualAmount")')
            .form-row
              .col {{ residualAmountInfo | money }}
              .col {{ residualAmountPercentageInfo | percent }}
        fi-data-field(:label = '$t("startDate")') {{ $t('common:formatDate', { value: loan.periodStart }) }}
        fi-data-field(:label = '$t("endDate")') {{ $t('common:formatDate', { value: loan.periodEnd }) }}
      .col-lg-6
        fi-data-field(:label = '$t("interestRate")')
          .form-row
            .col {{ schedule.interestRate | percent }}
            .col {{ dayCountConvention.human }}
        fi-data-field(:label = '$t("penaltyRate")')
          .form-row
            .col {{ penaltyRate | percent }}
            .col {{ penaltyDayCountConvention.human }}
        fi-fee-item(
          v-for         = 'fee in schedule.fees'
          :key          = 'fee.feeType.code'
          :fee          = 'fee'
          :amount       = 'schedule.amount || loan.amountLimit'
          :fee-configs  = 'feeConfigs'
        )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'
import { isOverdraft } from '@/mixins'
import { feePercentage } from '@/helpers'
import FiFeeItem from '@/components/FiFeeItem'

export default {
  name: 'loan-details',

  components: { FiFeeItem, FiDataField },

  mixins: [isOverdraft],

  props: {
    loan: {
      type: Object,
      required: true
    },
    leasing: {
      type: Boolean,
      default: false
    },
    schedule: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  computed: {
    scheduleAmount () {
      const { limit, amount } = this.schedule
      return amount ?? limit
    },
    penaltyRate () {
      const { penaltyRate, penaltyInterestRate } = this.schedule
      return penaltyInterestRate ?? penaltyRate
    },
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    dayCountConvention () {
      return this.classifierById(this.schedule.dayCountConvention?.classifier, this.schedule.dayCountConvention?.id)
    },
    penaltyDayCountConvention () {
      return this.classifierById(this.loan.penaltyDayCountConvention?.classifier, this.loan.penaltyDayCountConvention?.id)
    },
    percentageAgreementFee () {
      return feePercentage(this.classifierById('feeCalculationType', this.loan.agreementFeeMethodId).code)
    },
    percentageGuaranteeFee () {
      return feePercentage(this.classifierById('feeCalculationType', this.loan.guaranteeFeeMethodId).code)
    },
    percentageCommitmentFee () {
      return feePercentage(this.classifierById('feeCalculationType', this.loan.commitmentFeeMethodId).code)
    },
    upfrontAmountInfo () {
      return this.percentageUpfrontAmountInfo
        ? (this.schedule.upfrontAmount * this.scheduleAmount)
        : (this.schedule.upfrontAmount)
    },
    residualAmountInfo () {
      return this.percentageResidualAmountInfo
        ? (this.schedule.residualAmount * this.scheduleAmount)
        : (this.schedule.residualAmount)
    },
    upfrontAmountPercentageInfo () {
      return this.percentageUpfrontAmountInfo
        ? (this.schedule.upfrontAmount)
        : (this.schedule.upfrontAmount / this.scheduleAmount * 100).toFixed(2) + '%'
    },
    residualAmountPercentageInfo () {
      return this.percentageResidualAmountInfo
        ? (this.schedule.residualAmount)
        : (this.schedule.residualAmount / this.scheduleAmount * 100).toFixed(2) + '%'
    },
    percentageUpfrontAmountInfo () {
      return feePercentage(this.schedule.upfrontAmountCalculationType?.code)
    },
    percentageResidualAmountInfo () {
      return feePercentage(this.schedule.residualAmountCalculationType?.code)
    }
  }
}
</script>


<i18n>
en:
  loanDetails:       "Loan details"
  loanAmount:        "Contract amount"
  loanAmountVAT:     "Contract amount with VAT"
  loanLimit:         "Contract limit"
  startDate:         "Start date"
  endDate:           "End date"
  interestRate:      "Interest rate"
  penaltyRate:       "Penalty rate"
  agreementFee:      "Agreement fee"
  agreementFeeDate:  "Agreement fee date"
  guaranteeFee:      "Guarantee fee"
  guaranteeFeeDate:  "Guarantee fee date"
  commitmentFee:     "Commitment fee"
  commitmentFeeDate: "Commitment fee date"
  upfrontAmount:     "Upfront amount"
  residualAmount:    "Residual amount"
et:
  loanDetails:       "Loan details"
  loanAmount:        "Contract amount"
  loanAmountVAT:     "Contract amount with VAT"
  loanLimit:         "Contract limit"
  startDate:         "Start date"
  endDate:           "End date"
  interestRate:      "Interest rate"
  penaltyRate:       "Penalty rate"
  agreementFee:      "Agreement fee"
  agreementFeeDate:  "Agreement fee date"
  guaranteeFee:      "Guarantee fee"
  guaranteeFeeDate:  "Guarantee fee date"
  commitmentFee:     "Commitment fee"
  commitmentFeeDate: "Commitment fee date"
  upfrontAmount:     "Upfront amount"
  residualAmount:    "Residual amount"
ru:
  loanDetails:       "Loan details"
  loanAmount:        "Contract amount"
  loanAmountVAT:     "Contract amount with VAT"
  loanLimit:         "Contract limit"
  startDate:         "Start date"
  endDate:           "End date"
  interestRate:      "Interest rate"
  penaltyRate:       "Penalty rate"
  agreementFee:      "Agreement fee"
  agreementFeeDate:  "Agreement fee date"
  guaranteeFee:      "Guarantee fee"
  guaranteeFeeDate:  "Guarantee fee date"
  commitmentFee:     "Commitment fee"
  commitmentFeeDate: "Commitment fee date"
  upfrontAmount:     "Upfront amount"
  residualAmount:    "Residual amount"
</i18n>
