<template lang='pug'>
.card.loan-collaterall-pledgee-new.animated.fadeIn
  .card-body
    form(@submit.prevent = 'submit')
      fi-form-field
        input.form-control-sm.form-control(v-model      = 'pledgeeData.name'
                                           :placeholder = '$t("pledgeeName")')
      .form-row.justify-content-end
        button.btn.btn-primary.mr-1(type       = 'submit'
                                    :disabled  = 'saving')
          | {{ $t("common:save") }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        button.btn.btn-secondary(:disabled      = 'saving'
                                 @click.prevent = '$emit("cancel")') {{ $t("common:cancel") }}
</template>


<script>
import { mapActions } from 'vuex'
import FiFormField from '@/components/FiFormField'

export default {
  name: 'loan-collateral-pledgee-new',

  components: { FiFormField },

  i18nOptions: {},

  data: () => ({
    pledgeeData: {
      name: '',
      active: true
    }
  }),

  computed: {
    saving () {
      return this.$vueLoading.isLoading('pledgee:save')
    }
  },

  methods: {
    ...mapActions('pledgees', ['createPledgee']),
    async submit () {
      const pledgee = await this.createPledgee({ pledgeeData: this.pledgeeData })
      this.$emit('input', pledgee.id)
    }
  }
}
</script>


<i18n>
en:
  pledgeeName: "Pedgeee name"
et:
  pledgeeName: "Pedgeee name"
ru:
  pledgeeName: "Pedgeee name"
</i18n>

