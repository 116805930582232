<template lang="pug">
.loan-new.animated.fadeIn
  form.form-horizontal(
    novalidate
    @submit.prevent = 'onSubmit'
  )
    .card.mb-0.border-bottom-0
      .card-header {{ $t('title') }}
      .card-body
        .row
          .col-lg-6
            fi-form-field(
              :label = '$t("customer")'
              :state = '!$v.loanData.customerBorrowerId.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.loanData.customerBorrowerId.required') {{ $t('common:required') }}
              fi-customer-select(
                v-model      = 'selectedCustomerModel'
                :placeholder = '$t("customerPh")'
                :class       = '{ "is-invalid": $v.loanData.customerBorrowerId.$error }'
                :sector-id   = 'loanProduct.sectorId'
              )
            fi-form-field(
              :label = '$t("product")'
              :state = '!$v.loanData.productId.$error'
            )
              v-loading(loader = 'products:fetch')
                .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
                template(#error)
                  .error-message(v-if = '!$v.loanData.productId.required') {{ $t('common:required') }}
                fi-select.animated.fadeIn(
                  v-model.number = '$v.loanData.productId.$model'
                  :options       = 'productsBySector'
                  :placeholder   = '$t("productPh")'
                  :class         = '{ "is-invalid": $v.loanData.productId.$error }'
                  required
                  sm
                )
            fi-form-field(
              v-if   = 'isLeasingGroup || isHirePurchase'
              :label = '$t("sellers")'
              :state = '!$v.loanData.sellerIds.$error'
            )
              template(#error)
                .error-message(v-if = '!$v.loanData.sellerIds.required') {{ $t('sellerRequired') }}
              .row
                .col
                  .input-group.input-group-sm
                    fi-customer-select(
                      :placeholder  = '$t("sellerPh")'
                      :class        = '{ "is-invalid": $v.loanData.sellerIds.$error }'
                      clearOnSelect
                      @input        = 'addSeller'
                    )
              .row
                .col
                  ul.list-group.list-group-flush
                    li.list-group-item(v-for = 'sellerId in loanData.sellerIds') {{ selectedSellers[sellerId] }}
                      button.btn.text-danger(@click.prevent = 'removeSeller(sellerId)')
                        i.cil-user-unfollow.font-lg


    .card.mb-0.border-bottom-0
      .card-header {{ $t('loanData') }}
      v-loading(loader = 'loan:product:fetch')
        .text-center.animated.fadeIn(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-3
        .card-body.animated.fadeIn
          .row
            .col-lg-6
              fi-form-field(
                v-if = '!isOverdraftProduct'
                :label = '$t("scheduleType")'
                :state = '!$v.loanData.scheduleType.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.loanData.scheduleType.required') {{ $t('common:required') }}
                fi-select(
                  v-model   = '$v.loanData.scheduleType.$model'
                  :options  = 'optionsFromClassifier("scheduleTypes", true)'
                  :class    = '{ "is-invalid": $v.loanData.scheduleType.$error }'
                  :disabled = '!loanProduct.scheduleTypeIdOverride'
                  required
                  sm
                )

              fi-form-field(
                :label = '$t("amount")'
                :state = '!$v.loanData.amount.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.loanData.amount.required') {{ $t('common:required') }}
                  .error-message(v-if = '!$v.loanData.amount.gte') {{ formatRangeError('gte', $v.loanData.amount.$params.gte.value) }}
                  .error-message(v-if = '!$v.loanData.amount.lte') {{ formatRangeError('lte', $v.loanData.amount.$params.lte.value) }}

                fi-money-input.form-control.form-control-sm(
                  v-model.number = '$v.loanData.amount.$model'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.loanData.amount.$error }'
                  :disabled      = 'loanProduct.amountMin === loanProduct.amountMax'
                )
              template(v-if = 'isLeasingGroup')
                fi-form-field(
                  v-if   = 'loanProduct.principalWithVAT'
                  :label = '$t("amountWithVat")'
                )
                  v-loading(loader = 'taxation:convert:save')
                    i.fa.fa-spinner.fa-pulse(slot = 'spinner')
                    fi-money-input.form-control.form-control-sm(
                      v-model.number = 'amountWithVat'
                      type           = 'text'
                      disabled
                    )

                fi-form-field(
                  :label = '$t("upfrontAmount")'
                  :state = '!($v.loanData.upfrontAmount.$error || $v.loanData.upfrontAmountCalculationType.$error)'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.upfrontAmountCalculationType.required') {{ $t('common:required') }}
                    .error-message(v-if = '!$v.loanData.upfrontAmount.required') {{ $t('common:required') }}
                    .error-message(v-if = '!$v.loanData.upfrontAmount.gte') {{ formatRangeError('gte', $v.loanData.upfrontAmount.$params.gte.value, percentageUpfrontAmount) }}
                    .error-message(v-if = '!$v.loanData.upfrontAmount.lte') {{ formatRangeError('lte', $v.loanData.upfrontAmount.$params.lte.value, percentageUpfrontAmount) }}

                  .form-row
                    .col
                      fi-select(
                        v-model   = '$v.loanData.upfrontAmountCalculationType.$model'
                        :options  = 'leasingCalculationTypes'
                        :class    = '{ "is-invalid": $v.loanData.upfrontAmountCalculationType.$error }'
                        :disabled = '!loanProduct.upfrontAmountCalculationTypeIdOverride'
                        required
                        sm
                      )
                    .col
                      percent-input.form-control.form-control-sm(
                        v-if           = 'percentageUpfrontAmount'
                        v-model.number = '$v.loanData.upfrontAmount.$model'
                        type           = 'text'
                        :class         = '{ "is-invalid": $v.loanData.upfrontAmount.$error }'
                        :disabled      = '!loanProduct.upfrontAmountOverride'
                      )
                      fi-money-input.form-control.form-control-sm(
                        v-else
                        v-model.number = '$v.loanData.upfrontAmount.$model'
                        type           = 'text'
                        :class         = '{ "is-invalid": $v.loanData.upfrontAmount.$error }'
                        :disabled      = '!loanProduct.upfrontAmountOverride'
                      )
                    .col
                      .form-control-plaintext(v-if = 'percentageUpfrontAmount') {{ upfrontAmountInform | money }}
                      .form-control-plaintext(v-else) {{ upfrontAmountInform | percent }}

                fi-form-field(
                  :label = '$t("residualAmount")'
                  :state = '!($v.loanData.residualAmount.$error || $v.loanData.residualAmountCalculationType.$error)'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.residualAmountCalculationType.required') {{ $t('common:required') }}
                    .error-message(v-if = '!$v.loanData.residualAmount.required') {{ $t('common:required') }}
                    .error-message(v-if = '!$v.loanData.residualAmount.gte') {{ formatRangeError('gte', $v.loanData.residualAmount.$params.gte.value, percentageResidualAmount) }}
                    .error-message(v-if = '!$v.loanData.residualAmount.lte') {{ formatRangeError('lte', $v.loanData.residualAmount.$params.lte.value, percentageResidualAmount) }}
                  .form-row
                    .col
                      fi-select(
                        v-model   = '$v.loanData.residualAmountCalculationType.$model'
                        :options  = 'leasingCalculationTypes'
                        :class    = '{ "is-invalid": $v.loanData.residualAmountCalculationType.$error }'
                        :disabled = '!loanProduct.residualAmountCalculationTypeIdOverride'
                        required
                        sm
                      )
                    .col
                      percent-input.form-control.form-control-sm(
                        v-if           = 'percentageResidualAmount'
                        v-model.number = '$v.loanData.residualAmount.$model'
                        type           = 'text'
                        :class         = '{ "is-invalid": $v.loanData.residualAmount.$error }'
                        :disabled = '!loanProduct.residualAmountOverride'
                      )
                      fi-money-input.form-control.form-control-sm(
                        v-else
                        v-model.number = '$v.loanData.residualAmount.$model'
                        type           = 'text'
                        :class         = '{ "is-invalid": $v.loanData.residualAmount.$error }'
                        :disabled = '!loanProduct.residualAmountOverride'
                      )
                    .col
                      .form-control-plaintext(v-if = 'percentageResidualAmount') {{ residualAmountInform | money }}
                      .form-control-plaintext(v-else) {{ residualAmountInform | percent }}

              fi-form-field(
                :label = '$t("startDate")'
                :state = '!$v.loanData.periodStart.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.loanData.periodStart.required') {{ $t('common:required') }}
                fi-datepicker(
                  v-model   = '$v.loanData.periodStart.$model'
                  :class    = '{ "is-invalid": $v.loanData.periodStart.$error }'
                  :min-date = 'systemData.nextDayChange'
                  sm
                )

              fi-form-field(
                :label = '$t("endDate")'
                :state = '!$v.loanData.periodEnd.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.loanData.periodEnd.required') {{ $t('common:required') }}
                fi-datepicker(
                  v-model         = '$v.loanData.periodEnd.$model'
                  :class          = '{ "is-invalid": $v.loanData.periodEnd.$error }'
                  :disabled-dates = 'disabledDays'
                  :min-date       = 'periodMinDate'
                  :max-date       = 'periodMaxDate'
                  sm
                )
              fi-form-field(
                :label = '$t("interestRate")'
                :state = '!$v.interestGroup.$anyError'
              )
                template(#error)
                  .form-row
                    .col
                      .error-message(v-if = '!$v.loanData.interestRate.required') {{ $t('common:required') }}
                      .error-message(v-if = '!$v.loanData.interestRate.gte') {{ formatRangeError('gte', loanProduct.interestRateMin, true) }}
                      .error-message(v-if = '!$v.loanData.interestRate.lte') {{ formatRangeError('lte', loanProduct.interestRateMax, true) }}
                    .col
                      .error-message(v-if = '!$v.loanData.dayCountConvention.required') {{ $t('common:required') }}
                .form-row
                  .col: percent-input.form-control.form-control-sm(
                    v-model.number = '$v.loanData.interestRate.$model'
                    type           = 'text'
                    :class         = '{ "is-invalid": $v.loanData.interestRate.$error }'
                    :disabled      = 'loanProduct.interestRateMin === loanProduct.interestRateMax'
                  )
                  .col: fi-select(
                    v-model   = '$v.loanData.dayCountConvention.$model'
                    :options  = 'optionsFromClassifier("DayCountConvention")'
                    sm
                  )

              fi-form-field(
                :label = '$t("interestBaseRate")'
                :state = '!$v.interestGroup.$anyError'
              )
                template(#error)
                  .form-row
                    .col
                      .error-message(v-if = '!$v.loanData.interestRate.required') {{ $t('common:required') }}
                      .error-message(v-if = '!$v.loanData.interestRate.gte') {{ formatRangeError('gte', loanProduct.interestRateMin, true) }}
                      .error-message(v-if = '!$v.loanData.interestRate.lte') {{ formatRangeError('lte', loanProduct.interestRateMax, true) }}
                    .col
                      .error-message(v-if = '!$v.loanData.dayCountConvention.required') {{ $t('common:required') }}
                .form-row
                  .col: percent-input.form-control.form-control-sm(
                    v-model.number = 'loanData.variableInterestRate'
                    type           = 'text'
                    :decimalLimit  = 3
                    :disabled      = 'interestRateName === "FIXED"'
                  )
                  .col: fi-select(
                    v-model   = 'variableInterestIdGetter'
                    :options  = 'interestRatesDataValues'
                    required
                    sm
                  )
                  //- .col: percent-input.form-control.form-control-sm(
                  //-   v-model.number = 'loanData.variableInterestRate'
                  //-   type           = 'text'
                  //-   :decimalLimit  = 3
                  //-   :disabled      = 'interestRateName === "FIXED"'
                  //- )
                  .col: div.cell-center {{ 'Σ ' }} {{ (loanData.interestRate + loanData.variableInterestRate) || loanData.interestRate || 0 | percent }}
              fi-form-field(
                :label = '$t("penaltyRate")'
                :state = '!$v.penaltyGroup.$anyError'
              )
                template(#error)
                  .form-row
                    .col
                      .error-message(v-if = '!$v.loanData.penaltyInterestRate.required') {{ $t('common:required') }}
                      .error-message(v-if = '!$v.loanData.penaltyInterestRate.gte') {{ formatRangeError('gte', loanProduct.penaltyInterestRateMin, true) }}
                      .error-message(v-if = '!$v.loanData.penaltyInterestRate.lte') {{ formatRangeError('lte', loanProduct.penaltyInterestRateMax, true) }}
                    .col
                      .error-message(v-if = '!$v.loanData.penaltyDayCountConvention.required') {{ $t('common:required') }}
                .form-row
                  .col: percent-input.form-control.form-control-sm(
                    v-model.number = '$v.loanData.penaltyInterestRate.$model'
                    type           = 'text'
                    :class         = '{ "is-invalid": $v.loanData.penaltyInterestRate.$error }'
                    :disabled      = 'loanProduct.penaltyInterestRateMin === loanProduct.penaltyInterestRateMax'
                  )
                  .col: fi-select(
                    v-model.number = '$v.loanData.penaltyDayCountConvention.$model'
                    :options       = 'optionsFromClassifier("dayCountConventions", true)'
                    :class         = '{ "is-invalid": $v.loanData.penaltyDayCountConvention.$error }'
                    :disabled      = '!loanProduct.penaltyDayCountConventionIdOverride'
                    required
                    sm
                  )

              fi-form-field(
                :label = '$t("accountExternalId")'
                :state = '!$v.loanData.accountExternalId.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.loanData.accountExternalId.required') {{ $t('common:required') }}
                fi-select(
                  v-model.trim = '$v.loanData.accountExternalId.$model'
                  :options     = 'bankAccountOptions'
                  :class       = '{ "is-invalid": $v.loanData.accountExternalId.$error }'
                  sm
                  required
                )
                input.form-control.form-control-sm(
                  v-model = '$v.loanData.accountExternalId.$model'
                  :class  = '{ "is-invalid": $v.loanData.accountExternalId.$error }'
                )

            .col-lg-6
              fi-form-field.animated.fadeIn(
                v-if   = 'isBalloonScheduleType'
                :label = '$t("amortizationPeriod")'
                :state = '!$v.loanData.amortizationPeriod.$error'
              )
                template(#error)
                  .error-message(v-if = '!$v.loanData.amortizationPeriod.required') {{ $t('common:required') }}
                input.form-control.form-control-sm(
                  v-model = '$v.loanData.amortizationPeriod.$model'
                  :class  = '{ "is-invalid": $v.loanData.amortizationPeriod.$error }'
                )

              template(v-if = '!(isOverdraftProduct || isBulletScheduleType)')
                fi-form-field(
                  :label = '$t("principalFreq")'
                  :state = '!$v.loanData.principalPaymentPeriod.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.principalPaymentPeriod.required') {{ $t('common:required') }}
                  fi-select(
                    v-model   = '$v.loanData.principalPaymentPeriod.$model'
                    :options  = 'principalPaymentPeriods'
                    :class    = '{ "is-invalid": $v.loanData.principalPaymentPeriod.$error }'
                    :disabled = '!loanProduct.principalPaymentPeriodIdOverride'
                    required
                    sm
                  )
                fi-form-field(
                  :label = '$t("firstPrincipalDay")'
                  :state = '!$v.loanData.principalFirst.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.principalFirst.required') {{ $t('common:required') }}
                  fi-datepicker(
                    v-model         = '$v.loanData.principalFirst.$model'
                    :class          = '{ "is-invalid": $v.loanData.principalFirst.$error }'
                    :disabled-dates = '!isLeasingGroup ? lastMonthDays : {}'
                    :disabled       = 'isPrincipalTenor'
                    :enabled-dates  = 'isLeasingGroup ? interestPeriodEnabledDatesEnd : {}'
                    sm
                  )
              template(v-if = 'isCapitalCompanyLeasing && loanProduct.principalWithVAT')
                fi-form-field(
                  :label = '$t("principalVatPeriod")'
                  :state = '!$v.loanData.principalVatAmountPeriod.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.principalVatAmountPeriod.required') {{ $t('common:required') }}
                  fi-select(
                    v-model.number = 'loanData.principalVatAmountPeriod'
                    :class         = '{ "is-invalid": $v.loanData.principalVatAmountPeriod.$error }'
                    :options       = 'optionsFromClassifier("scheduleVatAmountPeriod")'
                    required
                    sm
                  )

              template(v-if = '!isOverdraftProduct')
                fi-form-field(
                  :label = '$t("interestFreq")'
                  :state = '!$v.loanData.interestPaymentPeriod.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.interestPaymentPeriod.required') {{ $t('common:required') }}
                  fi-select(
                    v-model.number = 'loanData.interestPaymentPeriod'
                    :options       = 'optionsFromClassifier("Frequency")'
                    :class         = '{ "is-invalid": $v.loanData.interestPaymentPeriod.$error }'
                    :disabled      = '!loanProduct.interestPaymentPeriodIdOverride'
                    required
                    sm
                  )

                fi-form-field(
                  :label = '$t("firstInterestDay")'
                  :state = '!$v.loanData.interestFirst.$error'
                )
                  template(#error)
                    .error-message(v-if = '!$v.loanData.interestFirst.required') {{ $t('common:required') }}
                  fi-datepicker(
                    v-model         = 'loanData.interestFirst'
                    :class          = '{ "is-invalid": $v.loanData.interestFirst.$error }'
                    :disabled-dates = '!isLeasingGroup ? lastMonthDays : {}'
                    :disabled       = 'isInterestTenor'
                    :enabled-dates  = 'isLeasingGroup ? interestPeriodEnabledDatesEnd : {}'
                    sm
                  )
              fi-fee-item(
                v-for       = "(fee, index) in $v.loanData.fees.$each.$iter"
                :key        = 'index'
                :fee        = 'fee'
                :amount     = 'loanData.amount || 0'
                :fee-configs = 'feeConfigs'
                :min-date = 'isOverdraftProduct ? $v.loanData.periodStart.$model : new Date()'
                editing
              )

              fi-form-field(
                v-if   = '!feeDisabled(guaranteeFee)'
                :label = 'guaranteeFee.feeType.human'
                :state = '!$v.guaranteeFeeGroup.$error'
              )
                template(#error)
                  .form-row
                    .col
                    .col
                      .error-message(v-if = '!$v.loanData.guaranteeFee.required') {{ $t('common:required') }}
                      .error-message(v-if = '!$v.loanData.guaranteeFee.gte') {{ formatRangeError('gte', $v.loanData.guaranteeFee.$params.gte.value, percentageGuaranteeFee) }}
                      .error-message(v-if = '!$v.loanData.guaranteeFee.lte') {{ formatRangeError('lte', $v.loanData.guaranteeFee.$params.lte.value, percentageGuaranteeFee) }}
                    .col
                      .error-message(v-if = '!$v.loanData.guaranteeFeePaymentDate.required') {{ $t('common:required') }}
                .form-row
                  .col: fi-select(
                    v-model.number = 'loanData.guaranteeFeeMethodId'
                    :options       = 'guaranteeCalculationTypes'
                    :class         = '{ "is-invalid": $v.loanData.guaranteeFeeMethodId.$error }'
                    :disabled      = '!guaranteeFee.feeOverride'
                    required
                    sm
                  )
                  .col
                    percent-input.form-control.form-control-sm(
                      v-if           = 'percentageGuaranteeFee'
                      v-model.number = 'loanData.guaranteeFee'
                      type           = 'text'
                      :class         = '{ "is-invalid": $v.loanData.guaranteeFee.$error }'
                      :disabled      = '!feeEditable(guaranteeFee)'
                    )
                    fi-money-input.form-control.form-control-sm(
                      v-else
                      v-model.number = 'loanData.guaranteeFee'
                      type           = 'text'
                      :class         = '{ "is-invalid": $v.loanData.guaranteeFee.$error }'
                      :disabled      = '!feeEditable(guaranteeFee)'
                    )
                  .col: fi-datepicker(
                    v-model = 'loanData.guaranteeFeePaymentDate'
                    :class  = '{ "is-invalid": $v.loanData.guaranteeFeePaymentDate.$error }'
                    :min-date = 'isOverdraftProduct ? $v.loanData.periodStart.$model : ""'
                    sm
                  )

    .card
      .card-body
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          |{{ $t("common:save") }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'onCancel'
        ) {{ $t("common:cancel") }}
</template>


<script>
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { rules, validation, validators } from '@/validation'
import {
  excludeLastMonthDays,
  feeDisabledMixin,
  feeEditableMixin,
  isCapitalLeasing,
  isFeePercentage,
  isLoanLeasing,
  isOverdraft
} from '@/mixins'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiMultiselect from '@/components/FiMultiselect'
import FiDatepicker from '@/components/FiDatepicker'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import debounce from 'lodash/debounce'
import { calculationTypes, convertFee, convertFeeRange, customerName, feeDisabled, feePercentage, sortClassifiers } from '@/helpers'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import FiFeeItem from '@/components/FiFeeItem'
import cloneDeep from 'lodash/cloneDeep'

const overdraftFields = [
  'accountExternalId',
  'fees',
  'amount',
  'customerBorrowerId',
  'dayCountConvention',
  'interestFirst',
  'interestPaymentPeriod',
  'interestRate',
  'penaltyInterestRate',
  'periodEnd',
  'periodStart',
  'productId',
  'guaranteeFee',
  'guaranteeFeeMethodId',
  'guaranteeFeePaymentDate'
]

const loanFields = [
  'accountExternalId',
  'fees',
  'amortizationPeriod',
  'amount',
  'customerBorrowerId',
  'dayCountConvention',
  'interestPaymentPeriod',
  'interestRate',
  'interestFirst',
  'penaltyDayCountConvention',
  'penaltyInterestRate',
  'periodEnd',
  'periodStart',
  'principalFirst',
  'principalPaymentPeriod',
  'principalVatAmountPeriod',
  'productId',
  'scheduleType',
  'residualAmount',
  'residualAmountCalculationType',
  'upfrontAmount',
  'upfrontAmountCalculationType',
  'sellerIds',
  'variableInterestId',
  'variableInterestRate'
]

export default {
  name: 'loan-new',

  components: {
    FiFeeItem,
    FiCustomerSelect,
    FiMultiselect,
    FiSelect,
    FiFormField,
    FiDatepicker,
    PercentInput,
    FiMoneyInput
  },

  mixins: [
    validation,
    feeEditableMixin,
    feeDisabledMixin,
    excludeLastMonthDays,
    isOverdraft,
    isLoanLeasing,
    isCapitalLeasing,
    isFeePercentage
  ],

  props: {
    customer: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  data () {
    return {
      amountWithVat: null,
      selectedCustomer: null,
      selectedSellers: [],
      rates: [],
      interestRateName: '',
      interestRatesData: [],
      loanData: {
        fees: [],
        accountExternalId: '',
        amortizationPeriod: null,
        amount: null,
        customerBorrowerId: '',
        dayCountConvention: '',
        interestPaymentPeriod: '',
        interestRate: null,
        interestFirst: '',
        penaltyDayCountConvention: '',
        penaltyInterestRate: null,
        periodEnd: undefined,
        periodStart: undefined,
        principalFirst: null,
        principalPaymentPeriod: '',
        principalVatAmountPeriod: null,
        productId: '',
        scheduleType: '',
        residualAmount: null,
        residualAmountCalculationType: null,
        upfrontAmount: null,
        upfrontAmountCalculationType: null,
        sellerIds: [],
        guaranteeFee: null,
        guaranteeFeeMethodId: '',
        guaranteeFeePaymentDate: null,
        variableInterestId: null,
        variableInterestRate: null,
        variableInterestFrom: null,
        variableInterestUntil: null
      }
    }
  },

  computed: {
    ...mapGetters('loans', ['loanProductFees']),
    ...mapGetters('products', ['optionsFromProductsBySectorAndSegment']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    ...mapState('loans', ['loanProduct', 'loan', 'systemData']),
    ...mapGetters('settings', ['settingByKey', 'isLimitEnabled']),
    ...mapState('products', ['feeConfigs']),
    ...mapState('interests', ['interestRates', 'variableInterests']),
    agreementFee () {
      return this.loanProductFees?.AGREEMENT ?? {}
    },
    monthlyServiceFee () {
      return this.loanProductFees?.ADMINISTRATION ?? {}
    },
    guaranteeFee () {
      return this.loanProductFees?.GUARANTEE ?? {}
    },
    saving () {
      return this.$vueLoading.isLoading('loan:save')
    },
    percentageResidualAmount () {
      return feePercentage(this.loanData.residualAmountCalculationType)
    },
    percentageUpfrontAmount () {
      return feePercentage(this.loanData.upfrontAmountCalculationType)
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
        this.loanData.customerBorrowerId = selected?.id ?? ''
        this.loanData.accountExternalId = selected.defaultExternalAccount?.accountExternalNumber
      }
    },
    disabledDates () {
      return [
        function (date) {
          return date.getDate() >= 28 && date.getDate() <= 31
        }
      ]
    },
    disabledDays () {
      return /GUARANTEE/i.test(this.loanProduct.group) ? null : this.lastMonthDays
    },
    convertedGuaranteeFeeRange () {
      return convertFeeRange({ ...this.guaranteeFee, feeCalculationType: this.classifierById('feeCalculationType', this.loanData.guaranteeFeeMethodId).code }, this.loanData.amount)
    },
    convertedResidualRange () {
      const { residualAmountMin: feeMin, residualAmountMax: feeMax } = this.loanProduct
      return convertFeeRange({ feeMin, feeMax, feeCalculationType: this.loanData.residualAmountCalculationType }, this.loanData.amount)
    },
    convertedUpfrontRange () {
      const { upfrontAmountMin: feeMin, upfrontAmountMax: feeMax } = this.loanProduct
      return convertFeeRange({ feeMin, feeMax, feeCalculationType: this.loanData.upfrontAmountCalculationType }, this.loanData.amount)
    },
    principalPaymentPeriods () {
      const interestPeriodId = this.classifierByName('paymentPeriodTypes', this.loanData.interestPaymentPeriod)?.id
      const availablePeriodsNames = this.optionsFromClassifier('paymentPeriodTypes')
        .filter(period => period.value >= interestPeriodId)
        .map(period => period.text)
      return this.optionsFromClassifier('Frequency').filter(period => availablePeriodsNames.includes(period.text) || !period.value)
    },
    upfrontAmountInform () {
      if (this.percentageUpfrontAmount) {
        return this.loanData.amount * this.loanData.upfrontAmount
      } else {
        return (this.loanData.upfrontAmount / this.loanData.amount * 100).toFixed(2) + '%'
      }
    },
    residualAmountInform () {
      if (this.percentageResidualAmount) {
        return this.loanData.amount * this.loanData.residualAmount
      } else {
        return (this.loanData.residualAmount / this.loanData.amount * 100).toFixed(2) + '%'
      }
    },
    percentageGuaranteeFee () {
      return feePercentage(this.classifierById('feeCalculationType', this.loanData.guaranteeFeeMethodId).code)
    },
    leasingCalculationTypes () {
      return calculationTypes(
        { feeType: { code: 'LEASING' }, feeCalculationType: { classifier: 'feeCalculationType' } },
        this.feeConfigs,
        this.classifierList('feeCalculationType')
      )
    },
    guaranteeCalculationTypes () {
      return calculationTypes(
        this.guaranteeFee,
        this.feeConfigs,
        this.classifierList('feeCalculationType'),
        true
      )
    },
    productsBySector () {
      const { sectorId, segmentId } = this.selectedCustomerModel || {}
      return sortClassifiers(this.optionsFromProductsBySectorAndSegment({ sectorId, segmentId }))
    },
    isPrincipalTenor () {
      return /TENOR/i.test(this.loanData.principalPaymentPeriod)
    },
    isInterestTenor () {
      return /TENOR/i.test(this.loanData.interestPaymentPeriod)
    },
    isBalloonScheduleType () {
      return /BALLOON/i.test(this.loanData.scheduleType)
    },
    isBulletScheduleType () {
      return /BULLET/i.test(this.loanData.scheduleType)
    },
    periodUnit () {
      return this.classifierById('periodUnitType', this.loanProduct.periodUnitTypeId)
    },
    periodMinDate () {
      return this.$moment(this.loanData.periodStart).add(this.loanProduct.periodMin, this.periodUnit.name).toDate()
    },
    periodMaxDate () {
      return this.$moment(this.loanData.periodStart).add(this.loanProduct.periodMax, this.periodUnit.name).toDate()
    },
    isHirePurchase () {
      return this.loanProduct.hirePurchaseInfo
    },
    bankAccountOptions () {
      return (this.selectedCustomer?.externalBankAccounts || [])
        .map(({ accountExternalNumber }) => ({ value: accountExternalNumber, text: accountExternalNumber }))
    },
    interestPeriodEnabledDatesEnd () {
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.loanData.interestPaymentPeriod) ?? {}
      const interestRange = this.$moment.range(this.interestPeriodMinDate, this.$v.loanData.periodEnd.$model).snapTo('day')
      let range = []
      switch (interestPeriod.name) {
        case 'MONTHLY':
          range = interestRange.by('months')
          break
        case 'QUARTERLY':
          range = interestRange.by('quarters')
          break
        case 'SEMIANNUAL':
          range = interestRange.by('months', { step: 6 })
          break
        case 'ANNUAL':
          range = interestRange.by('years')
          break
      }
      // this.addDate()
      return [...Array.from(range)].map(date => date.toDate())
    },
    interestPeriodMinDate () {
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.loanData.interestPaymentPeriod)?.name
      let period = 1
      switch (interestPeriod) {
        case 'QUARTERLY':
          period = 3
          break
        case 'SEMIANNUAL':
          period = 6
          break
        case 'ANNUAL':
          period = 12
          break
      }
      const minDate = this.$moment(this.$v.loanData.periodEnd.$model)
      if (typeof this.$v.loanData.periodStart.$model === 'string') {
        const startYear = this.$v.loanData.periodStart.$model.split('-')[0]
        const startMonth = this.$v.loanData.periodStart.$model.split('-')[1]
        if (typeof minDate._i === 'string') {
          const mapMinDate = minDate._i.split('-')
          mapMinDate[0] = startYear
          mapMinDate[1] = startMonth
          minDate._i = mapMinDate.join('-')
        }
      }
      while (this.$moment(minDate).subtract(1, 'months').isSameOrAfter(this.$v.loanData.periodStart)) {
        minDate.subtract(period, 'months')
      }
      return minDate
    },
    interestRatesDataValues () {
      if (this.rates.length) {
        return this.rates.map((rate) => rate.name)
      } else {
        return ['FIXED']
      }
    },
    variableInterestIdGetter: {
      get () {
        return this.interestRateName
      },
      set (value) {
        this.loanData.variableInterestId = this.interestRatesData?.find((rate) => rate.name === value)?.id || null
        this.loanData.variableInterestRate = this.interestRates?.find((rate) => rate.variableInterest.id === this.loanData.variableInterestId)?.rate
        this.interestRateName = this.interestRates?.find((rate) => rate.name === value)?.name || value
      }
    }
  },

  watch: {
    async 'loanData.productId' () {
      this.loanData.variableInterestId = null
      this.loanData.variableInterestRate = null
      this.loanData.variableInterestFrom = null
      this.loanData.variableInterestUntil = null
      this.fillProductData()
      await this.loadVariableInterestsRate()
      await this.loadVariableInterests()
      const activeInterestRate = this.loanProduct.productInterestDefinitionDtos.find((interestDefinition) => interestDefinition.default === true)
      this.interestRatesData = this.variableInterests.filter((rate) =>
        this.loanProduct.productInterestDefinitionDtos.find((interestDefinition) =>
          interestDefinition.variableInterestId === rate.id
        )
      )
      if (!this.loanProduct.productInterestDefinitionDtos.length) {
        this.interestRatesData = this.variableInterests.filter((interest) => interest.name === 'FIXED')
        this.interestRateName = 'FIXED'
      }
      this.rates = this.interestRatesData
      if (activeInterestRate) {
        const activeInterestRateInfo = this.interestRatesData.find((interest) => interest.id === activeInterestRate.variableInterestId)
        this.loanData.variableInterestId = activeInterestRateInfo.id
        this.loanData.variableInterestRate = this.interestRates?.find((rate) => rate.variableInterest.id === this.loanData.variableInterestId)?.rate
        this.interestRateName = activeInterestRateInfo.name
      }
    },
    'loanData.scheduleType' (value, oldValue) {
      if (!oldValue) {
        return
      }
      if (this.isBulletScheduleType) {
        this.loanData.principalPaymentPeriod = 'TENOR'
      } else {
        this.fillDates(this.loanData.periodStart)
      }
    },
    'loanData.periodStart': 'fillDates',
    'loanData.periodEnd' (val) {
      if (this.isPrincipalTenor) {
        this.loanData.principalFirst = val
      }

      if (this.isInterestTenor) {
        this.loanData.interestFirst = val
      }
    },
    'loanData.interestPaymentPeriod' (val) {
      if (this.isBulletScheduleType) {
        return
      }
      const interestPeriodId = this.classifierByName('paymentPeriodTypes', val)?.id
      const principalPeriodId = this.classifierByName('paymentPeriodTypes', this.loanData.principalPaymentPeriod)?.id
      if (principalPeriodId < interestPeriodId) {
        this.loanData.principalPaymentPeriod = val
      }
      if (this.isInterestTenor) {
        this.loanData.interestFirst = this.loanData.periodEnd
      }
    },
    'loanData.principalPaymentPeriod' () {
      if (this.isPrincipalTenor) {
        this.loanData.principalFirst = this.loanData.periodEnd
      }
    },
    percentageGuaranteeFee (val, oldVal) {
      if (typeof oldVal === 'undefined' || val === oldVal) {
        return
      }

      this.loanData.guaranteeFee = convertFee({
        feeValue: this.loanData.guaranteeFee,
        isPercentage: val,
        amountValue: this.loanData.amount
      })
    },
    async 'loanData.amount' () {
      if (this.loanProduct.principalWithVat) {
        this.onAmountChange()
      }
    }
  },

  validations () {
    const loanData = pick(rules.loanRules, Object.keys(this.loanData))
    return {
      loanData: merge({}, loanData, {
        fees: {
          $each: {
            date: {},
            feeType: {
              classifier: {},
              code: {}
            },
            feeCalculationType: {
              classifier: {},
              code: {}
            },
            feeAmount: {
              required: validators.requiredUnless(function (fee) {
                return feeDisabled(fee)
              }),
              min: validators.minValueRef(function (fee) {
                return convertFeeRange(fee, this.loanData.amount).feeMin ?? -Infinity
              }),
              max: validators.maxValueRef(function (fee) {
                return convertFeeRange(fee, this.loanData.amount).feeMax ?? Infinity
              })
            }
          }
        },
        amortizationPeriod: {
          required: validators.requiredIf(function () {
            return this.isBalloonScheduleType
          })
        },
        amount: {
          gte: validators.gte(this.loanProduct.amountMin),
          lte: validators.lte(Math.min(...[this.loanProduct.amountMax, ...(this.isLimitEnabled ? [this.selectedCustomer?.creditLimit] : [])]))
        },
        interestRate: {
          gte: validators.gte(this.loanProduct.interestRateMin),
          lte: validators.lte(this.loanProduct.interestRateMax)
        },
        penaltyInterestRate: {
          gte: validators.gte(this.loanProduct.penaltyInterestRateMin),
          lte: validators.lte(this.loanProduct.penaltyInterestRateMax)
        },
        guaranteeFee: {
          required: validators.requiredUnless(function () {
            return this.feeDisabled(this.guaranteeFee)
          }),
          gte: validators.gte(this.convertedGuaranteeFeeRange.feeMin),
          lte: validators.lte(this.convertedGuaranteeFeeRange.feeMax)
        },
        guaranteeFeeMethodId: {
          required: validators.requiredUnless(function () {
            return this.feeDisabled(this.guaranteeFee)
          })
        },
        guaranteeFeePaymentDate: {
          required: validators.requiredUnless(function () {
            return this.feeDisabled(this.guaranteeFee)
          })
        },
        principalFirst: {
          required: validators.requiredIf(function () {
            return !(this.isOverdraftProduct || this.isBulletScheduleType)
          })
        },
        interestFirst: {
          required: validators.requiredIf(function () {
            return !(this.isOverdraftProduct || this.isBulletScheduleType)
          })
        },
        principalPaymentPeriod: {
          required: validators.requiredIf(function () {
            return !(this.isOverdraftProduct || this.isBulletScheduleType)
          })
        },
        residualAmount: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          }),
          gte: validators.gte(this.convertedResidualRange.feeMin),
          lte: validators.lte(this.convertedResidualRange.feeMax)
        },
        residualAmountCalculationType: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        upfrontAmount: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          }),
          gte: validators.gte(this.convertedUpfrontRange.feeMin),
          lte: validators.lte(this.convertedUpfrontRange.feeMax)
        },
        upfrontAmountCalculationType: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        sellerIds: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          }),
          $each: {}
        },
        principalVatAmountPeriod: {
          required: validators.requiredIf(function () {
            return this.isCapitalCompanyLeasing && this.loanProduct.principalWithVAT
          })
        }
      }),
      guaranteeFeeGroup: ['loanData.guaranteeFee', 'loanData.guaranteeFeePaymentDate'],
      interestGroup: ['loanData.dayCountConvention', 'loanData.interestRate'],
      penaltyGroup: ['loanData.penaltyInterestRate', 'loanData.penaltyDayCountConvention']
    }
  },

  async created () {
    await this.loadProducts({ group: ['LOAN', 'OVERDRAFT', 'LEASING', 'GUARANTEE'] })
    this.CLEAR_LOAN()
    if (this.customer) {
      this.loanData.customerBorrowerId = this.customer.id
      this.loanData.accountExternalId = this.customer.defaultExternalAccount?.accountExternalNumber
      this.selectedCustomer = this.customer
    }
  },

  methods: {
    ...mapActions('loans', ['loadProduct', 'createLoan', 'createOverdraft']),
    ...mapActions('products', ['loadProducts', 'loadFeeConfig']),
    ...mapActions('taxation', ['convertAmount']),
    ...mapActions('interests', ['loadVariableInterestsRate', 'loadVariableInterests']),
    ...mapMutations('loans', ['CLEAR_LOAN']),
    onAmountChange: debounce(
      async function () {
        this.amountWithVat = await this.convertAmount({
          amountData: {
            amount: this.loanData.amount,
            amountWithVat: false
          }
        })
      }, 500
    ),
    addDate () {
      this.$v.loanData.principalFirst.$model = this.interestPeriodMinDate._i
      this.loanData.interestFirst = this.interestPeriodMinDate._i
    },
    async fillProductData () {
      await this.loadProduct({ id: this.loanData.productId })
      await this.loadFeeConfig({ group: this.loanProduct.group })
      this.loanData = {
        ...this.loanData,
        ...pick(
          this.loanProduct,
          Object.keys(this.loanData)
        ),
        dayCountConvention: this.classifierById('dayCountConventions', this.loanProduct.dayCountConventionId).name,
        penaltyDayCountConvention: this.classifierById('dayCountConventions', this.loanProduct.penaltyDayCountConventionId).name,
        interestPaymentPeriod: this.classifierById('paymentPeriodTypes', this.loanProduct.interestPaymentPeriodId).name,
        principalPaymentPeriod: this.classifierById('paymentPeriodTypes', this.loanProduct.principalPaymentPeriodId).name,
        scheduleType: this.classifierById('scheduleTypes', this.loanProduct.scheduleTypeId).name,
        residualAmountCalculationType: this.loanProduct.residualAmountCalculationType?.code,
        upfrontAmountCalculationType: this.loanProduct.upfrontAmountCalculationType?.code,
        guaranteeFee: this.guaranteeFee?.feeAmount,
        guaranteeFeeMethodId: this.classifierByName(this.guaranteeFee?.feeCalculationType?.classifier, this.guaranteeFee?.feeCalculationType?.code)?.id,
        fees: [this.agreementFee, this.monthlyServiceFee].filter(fee => !feeDisabled(fee))
          .map(fee => ({
            ...cloneDeep(fee),
            date: this.$moment().toDate()
          }))
      }
      this.loanData.periodStart = this.$moment().toDate()
      if (this.disabledDates.some(isDisabled => isDisabled(this.loanData.periodStart))) {
        this.loanData.periodStart = this.$moment().add(1, 'month').date(1).toDate()
      }
      this.$v.$reset()
    },
    fillDates (periodStart) {
      if (this.loanProduct) {
        const startDate = () => this.$moment(periodStart, this.$moment.HTML5_FMT.DATE)
        this.loanData.periodEnd = startDate().add(this.loanProduct.period, this.periodUnit.name).toDate()
        if (this.isOverdraftProduct) {
          this.loanData.guaranteeFeePaymentDate = this.$moment.min(startDate().add(this.loanProduct.firstPrincipalPayment, this.periodUnit.name), this.$moment(this.loanData.periodEnd)).toDate()
          this.$v.loanData.fees.$each.$iter[0].date.$model = this.$moment.min(startDate().add(this.loanProduct.firstPrincipalPayment, this.periodUnit.name), this.$moment(this.loanData.periodEnd)).toDate()
        } else {
          this.loanData.principalFirst = this.$moment.min(startDate().add(this.loanProduct.firstPrincipalPayment, this.periodUnit.name), this.$moment(this.loanData.periodEnd)).toDate()
          this.loanData.interestFirst = this.$moment.min(startDate().add(this.loanProduct.firstInterestPayment, this.periodUnit.name), this.$moment(this.loanData.periodEnd)).toDate()
        }
      }
    },
    async onSubmit () {
      if (this.validate()) {
        const updatedData = {
          ...this.loanData,
          fees: this.loanData.fees.map(({ feeType, feeCalculationType, feeAmount, date }) => ({
            date,
            feeAmount,
            feeCalculationType: feeCalculationType.code,
            feeType: feeType.code
          }))
        }
        if (this.isOverdraftProduct) {
          await this.createOverdraft({ loanData: pick(updatedData, overdraftFields) })
        } else {
          await this.createLoan({ loanData: pick(updatedData, loanFields) })
        }
        this.$router.push({ name: 'Loan', params: { id: this.loan.id } })
      }
    },
    onCancel () {
      this.$router.push({ name: 'Loans' })
    },
    addSeller (seller) {
      this.selectedSellers = {
        ...this.selectedSellers,
        [seller.id]: customerName(seller)
      }
      this.loanData.sellerIds = Object.keys(this.selectedSellers)
    },
    removeSeller (sellerId) {
      delete this.selectedSellers[sellerId]
      this.loanData.sellerIds = Object.keys(this.selectedSellers)
    },
    feeCalculationTypes (fee) {
      return calculationTypes(
        fee,
        this.feeConfigs,
        this.classifierList('feeCalculationType')
      )
    }
  }
}
</script>


<i18n>
en:
  title:                         "New loan"
  customer:                      "Customer"
  customerPh:                    "Select customer"
  product:                       "Product"
  sellers:                       "Sellers"
  sellerPh:                      "Select seller"
  addSeller:                     "Add seller"
  productPh:                     "Select product"
  loanData:                      "Loan data"
  amount:                        "Amount"
  startDate:                     "Start date"
  endDate:                       "End date"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty rate"
  scheduleType:                  "Schedule type"
  accountExternalId:             "Payout bank account"
  amortizationPeriod:            "Amortization period"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  interestFreq:                  "Interest frequency"
  firstInterestDay:              "1st interest day"
  gteToday:                      "Loan start date cannot be in past"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  amountWithVat:                 "Amount with VAT"
  loanLimitWithVat:              "Limit with VAT"
  sellerRequired:                "At least one seller is required"
  principalVatPeriod:            "Principal VAT period"
et:
  title:                         "New loan"
  customer:                      "Customer"
  customerPh:                    "Select customer"
  product:                       "Product"
  productPh:                     "Select product"
  sellers:                       "Sellers"
  sellerPh:                      "Select seller"
  addSeller:                     "Add seller"
  loanData:                      "Loan data"
  amount:                        "Amount"
  startDate:                     "Start date"
  endDate:                       "End date"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty rate"
  scheduleType:                  "Schedule type"
  accountExternalId:             "Payout bank account"
  amortizationPeriod:            "Amortization period"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  interestFreq:                  "Interest frequency"
  firstInterestDay:              "1st interest day"
  gteToday:                      "Loan start date cannot be in past"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  amountWithVat:                 "Amount with VAT"
  loanLimitWithVat:              "Limit with VAT"
  sellerRequired:                "At least one seller is required"
  principalVatPeriod:            "Principal VAT period"
ru:
  title:                         "New loan"
  customer:                      "Customer"
  customerPh:                    "Select customer"
  product:                       "Product"
  productPh:                     "Select product"
  sellers:                       "Sellers"
  sellerPh:                      "Select seller"
  addSeller:                     "Add seller"
  loanData:                      "Loan data"
  amount:                        "Amount"
  startDate:                     "Start date"
  endDate:                       "End date"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty rate"
  scheduleType:                  "Schedule type"
  accountExternalId:             "Payout bank account"
  amortizationPeriod:            "Amortization period"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  interestFreq:                  "Interest frequency"
  firstInterestDay:              "1st interest day"
  gteToday:                      "Loan start date cannot be in past"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  amountWithVat:                 "Amount with VAT"
  loanLimitWithVat:              "Limit with VAT"
  sellerRequired:                "At least one seller is required"
  principalVatPeriod:            "Principal VAT period"
</i18n>
