<template lang="pug">
.loan-overview
  v-loading(loader = 'loan:customer:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    customer-note-list(:comments = 'loanComments')
    .row(v-if = 'loanCustomer')
      .col-sm-6.col-lg-4: loan-overview-widget(
        :title = '$t("loanStatus")'
        :value = 'loanStatus.human'
        :color = 'loanStatusColor(loanStatus.name)'
      )
        loan-status-dropdown.fa-pull-right(
          :statuses = 'loan.availableStatuses'
          @submit   = 'changeStatus'
        )
      loan-sign-modal(
        v-if      = 'showSignModal'
        v-model   = 'showSignModal'
        :loan     = 'loan'
        :callback = 'setSigned'
        :loader   = '`loan:${loan.id}:save`'
      )
      loan-initiate-outgoing-payment-modal(
        v-if        = 'showInitiatePaymentModal'
        v-model     = 'showInitiatePaymentModal'
        :loan       = 'loan'
        :product    = 'loanProduct'
        :callback   = 'setSigned'
        :loader     = '$vueLoading.isLoading(`loan:${loan.id}:save`)'
        :schedule   = 'loanSchedule'
        :payout-amount = 'payoutAmount'
        free-input
      )
      loan-activate-modal(
        v-if        = 'loanSchedule'
        v-model     = 'showActivateModal'
        :schedule   = 'loanSchedule'
        :callback   = 'setStatus'
      )
      fi-confirm-modal(
        v-model      = 'showConfirmModal'
        :ok-callback = 'setStatus'
        :message     = 'confirmMessage'
        loader       = 'loan:status:save'
      )
      .col-sm-6.col-lg-4: loan-overview-widget(
        :title = '$t("totalBalance")'
        :value = 'loanCustomer.customerOverallBalance || 0 | money'
        color  = 'primary'
      )
        router-link.text-white(
          :to = '{ name: "Debts", query: { criteria: loanCustomer.idCode } }'
        ).fa-pull-right.text-right
          h4.m-0 {{ loan.totalDebt | money }}
          p.m-0 {{ $t('totalDebt') }}

      .col-sm-6.col-lg-4: loan-overview-widget(
        :color = 'loan.totalPrepayment ? "success" : "primary"'
        :title = '$t("prepayment")'
        :value = 'loan.account.balance | money'
      )
        router-link.fa-pull-right.text-white(
          :to = '{ name: "CustomerPaymentInfo", params: { id: loanCustomer.id } }'
        )
          h4.m-0 {{ loan.totalPrepayment | money }}
          p.m-0 {{ $t("totalPrepayment") }}
      template(v-if = 'isActive')
        .col-sm-6.col-lg: loan-overview-widget(
          :title = '$t("availableLimit")'
          :value = 'loanFinancial && loanFinancial.availableLimit || 0 | money'
          color  = 'primary'
        )
        .col-sm-6.col-lg: loan-overview-widget(
          :title = '$t("loanBalance")'
          :value = 'loanFinancial && loanFinancial.balance || 0 | money'
          color  = 'primary'
        )
          .fa-pull-right.text-right
            h4.m-0 {{ loan.financial.debts | money }}
            p.m-0 {{ $t('loanDebt') }}
        .col-sm-6.col-lg: loan-overview-widget(
          :title = '$t("interestAccrued")'
          :value = 'loanFinancial && loanFinancial.interestAccruedBalance || 0 | money'
          color  = 'primary'
        )
    .row
      .col-lg-4
        v-loading(:loader = 'scheduleLoader')
          template(#spinner)
            .text-center: i.fa.fa-spinner.fa-pulse.fa-lg.m-4
          loan-overview-details(
            v-if           = 'loanSchedule'
            :loan          = 'loan'
            :product       = 'loanProduct'
            :loan-schedule = 'loanSchedule'
            :fee-configs    = 'feeConfigs'
            :overdraft     = 'isOverdraftProduct'
            :leasing       = 'isLeasingGroup'
          )
        loan-customer(
          v-if          = 'loan.customerBorrower'
          :customer     = 'loan.customerBorrower'
          :customerType = '$t("borrower")'
        )
        loan-customer(
          :customer     = 'loan.manager'
          :customerType = '$t("manager")'
        )
          template(v-if = 'editable')
            button.btn.text-danger(
              v-if   = 'loan.manager'
              @click = 'setManager()'
            )
              i.fa.fa-cog.fa-pulse(v-if = '$vueLoading.isLoading(`loan:${loan.id}:save`)')
              i.cil-user-unfollow(v-else)
            application-assign-dropdown.colored(
              :application  = 'loan'
              :loader       = '`loan:${loan.id}:save`'
              no-caret
              @assign       = 'setManager'
            )

        v-loading(:loader = '["customer:fetch", "loan:relations:fetch"]')
          .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
          template(v-if = 'loanRelations')
            loan-customer(
              v-for         = 'relation in loanRelations'
              :key          = 'relation.id'
              :customer     = 'relation'
              :customerType = 'classifierByName(relation.relatedCustomerType.classifier, relation.relatedCustomerType.code).human'
            )
        v-loading(loader = 'loan:customer:fetch')
          .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
          template(v-if = 'loanCustomer')
            loan-customer(
              v-for         = 'relation in loanCustomer.relations'
              :key          = 'relation.id'
              :customer     = 'relation.referredCustomer'
              :customerType = 'classifierById("relationTypes", relation.relationTypeId).human'
            )
      v-loading.col-lg-8(:loader = 'scheduleLoader')
        template(#spinner)
          .text-center: i.fa.fa-spinner.fa-pulse.fa-lg.m-4
        component(
          v-if             = 'loanSchedule'
          :is              = 'scheduleComponent'
          :schedule        = 'loanSchedule'
          :schedule-status = 'scheduleStatus'
          :leasing         = 'isLeasingGroup'
        )
</template>


<script>
import FiConfirmModal from '@/components/FiConfirmModal'
import FiTable from '@/components/FiTable'
import { loanStatuses } from '@/const'
import { isLoanLeasing, isOverdraft } from '@/mixins'
import ApplicationAssignDropdown from '@/views/applications/ApplicationAssignDropdown'
import CustomerNoteList from '@/views/customers/CustomerNoteList'
import LoanActivateModal from '@/views/loans/LoanActivateModal'
import LoanInitiateOutgoingPaymentModal from '@/views/loans/LoanInitiateOutgoingPaymentModal'
import LoanSignModal from '@/views/loans/LoanSignModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoanCustomer from './LoanOverview/LoanOverviewCustomer'
import LoanOverviewDetails from './LoanOverview/LoanOverviewDetails'
import LoanOverviewWidget from './LoanOverview/LoanOverviewWidget'
import LoanStatusDropdown from './LoanStatusDropdown'

const LoanOverviewScheduleOverdraft = () => import('@/views/loans/LoanOverview/LoanOverviewScheduleOverdraft')
const LoanOverviewSchedule = () => import('@/views/loans/LoanOverview/LoanOverviewSchedule')

export default {
  name: 'loan-overview',

  components: {
    LoanInitiateOutgoingPaymentModal,
    FiConfirmModal,
    LoanActivateModal,
    LoanSignModal,
    ApplicationAssignDropdown,
    FiTable,
    CustomerNoteList,
    LoanOverviewWidget,
    LoanStatusDropdown,
    LoanOverviewDetails,
    LoanCustomer
  },

  mixins: [isOverdraft, isLoanLeasing],

  i18nOptions: {},

  data () {
    return {
      newStatusCode: '',
      showSignModal: false,
      showActivateModal: false,
      showConfirmModal: false,
      changeStatusErrorAction: null,
      confirmMessage: '',
      showInitiatePaymentModal: false
    }
  },

  computed: {
    ...mapState('loans', [
      'loan',
      'loanCustomer',
      'loanProduct',
      'loanRelations',
      'loanComments',
      'loanSchedule',
      'loanPaymentRelations',
      'loanFinancial'
    ]),
    ...mapState('products', ['productsList', 'feeConfigs']),
    ...mapGetters('loans', ['loanStatusColor', 'scheduleById']),
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    ...mapGetters('customers', ['customerName']),
    payoutAmount () {
      const { amount, amountWithVat } = this.loanSchedule
      return this.isLeasingGroup ? amountWithVat : amount
    },
    scheduleStatus () {
      return this.classifierById(
        this.loanSchedule.status.classifier,
        this.loanSchedule.status.id
      )
    },
    loanStatus () {
      return this.classifierById(
        this.loan.status.classifier,
        this.loan.status.id
      )
    },
    scheduleComponent () {
      return this.isOverdraftProduct
        ? LoanOverviewScheduleOverdraft
        : LoanOverviewSchedule
    },
    isActive () {
      const { inserted, signed, pending, deleted, matured } = loanStatuses
      return ![inserted, signed, pending, deleted, matured].includes(this.loan.status.code)
    },
    isDelayed () {
      const { delayed, collector, matured } = loanStatuses
      return [delayed, collector, matured].includes(this.loan.status.code)
    },
    editable () {
      return [loanStatuses.matured].every(status => this.loan.status.id !== status)
    },
    scheduleLoader () {
      return ['overdraft:terms:fetch', 'loan:schedule:fetch']
    },
    penaltyRateChange () {
      return this.loanSchedule?.penaltyRateChange ?? {}
    }
  },

  async created () {
    await this.loadManagers()
    await this.loadComments({ filters: { active: true, hasAction: false } })
    await this.loadLoanRelations({ loanId: this.loan.id })
    await this.loadLoanFinancial()
    await this.loadFeeConfig({ group: this.loanProduct.group })
    if (this.isOverdraftProduct) {
      await this.loadLoanOverdraftTerm({ termId: 'active' })
    } else {
      await this.loadLoanSchedule({ scheduleId: 'active' })
    }
  },

  methods: {
    ...mapActions('products', ['loadFeeConfig']),
    ...mapActions('loans', [
      'updateLoanTerms',
      'updateLoanStatus',
      'updateLoanOverdraftStatus',
      'loadComments',
      'loadLoanRelations',
      'signLoanOverdraft',
      'updateOverdraft',
      'loadLoanSchedule',
      'loadLoanOverdraftTerm',
      'loadLoanFinancial'
    ]),
    ...mapActions('classifiers', ['loadManagers']),
    changeStatus ({ code }) {
      if (this.loanPaymentRelations[0]?.vatEnable && code === 'PENDING' && this.loanPaymentRelations[0]?.amount > this.loanSchedule.amountWithVat) {
        this.$toasted.error(`Total payout amount should be no more than ${this.loanSchedule.amount}`)
        return
      }
      if (!this.loanPaymentRelations[0]?.vatEnable && code === 'PENDING' && this.loanPaymentRelations[0]?.amount > this.loanSchedule.amount) {
        this.$toasted.error(`Total payout amount should be no more than ${this.loanSchedule.amount}`)
        return
      }
      const { pending, signed, active } = loanStatuses
      switch (code) {
        case pending:
          if (this.loanProduct.outgoingPaymentsEnabled) {
            this.showInitiatePaymentModal = this.loanProduct.outgoingPaymentsEnabled
          } else {
            this.confirmMessage = ''
            this.showConfirmModal = true
          }
          break
        case signed:
          this.showSignModal = true
          break
        case active:
          if (this.loanStatus.id === pending) {
            this.showActivateModal = true
          } else {
            this.confirmMessage = ''
            this.showConfirmModal = true
          }
          break
        // case agreed:
        //   this.confirmMessage = this.$t('agreedConfirm')
        //   this.showConfirmModal = true
        //   break
        default:
          this.confirmMessage = ''
          this.showConfirmModal = true
      }
      this.newStatusCode = code
    },
    async setStatus ({ periodStart, done } = {}) {
      if (this.isOverdraftProduct) {
        await this.updateLoanOverdraftStatus({ statusId: this.newStatusCode })
        if (this.newStatusCode === 'ACTIVE' && this.isOverdraftProduct) {
          await this.loadLoanOverdraftTerm({ termId: 'active' })
        }
      } else {
        await this.updateLoanStatus({
          statusId: this.newStatusCode,
          periodStart
        })
      }
      // eslint-disable-next-line no-unused-expressions
      done?.()
    },
    setManager ({ id = this.loan.id, customerId = '' } = {}) {
      const data = { managerId: customerId }
      if (this.isOverdraftProduct) {
        this.updateOverdraft({ id, overdraftData: data })
      } else {
        this.updateLoanTerms({ id, loanData: data })
      }
    },
    async setSigned ({ paymentData: { id = this.loan.id, ...paymentData } = {}, done, contractData } = {}) {
      if (this.isOverdraftProduct) {
        await this.signLoanOverdraft({ overdraftId: id, overdraftData: contractData ?? paymentData })
      } else {
        await this.updateLoanTerms({ id, loanData: { ...contractData ?? paymentData, status: this.newStatusCode } })
      }

      // eslint-disable-next-line no-unused-expressions
      done?.()
    }
  }
}
</script>


<i18n>
en:
  loanStatus:        "Contract status"
  loanBalance:       "Contract balance"
  availableLimit:    "Contract available limit"
  interestAccrued:   "Interest accrued"
  loanDebt:          "Contract debt"
  totalDebt:         "Customer total debt"
  totalBalance:      "Customer total balance"
  prepayment:        "Prepayment balance"
  totalPrepayment:   "Customer total prepayment"
  collateral:        "Collateral"
  borrower:          "Borrower"
  manager:           "Manager"
  coBorrower:        "Co-Borrower"
  warrantor:         "Warrantor"
  agreedConfirm:     "Are you sure you want to write-off loan?"
  penaltyPeriod:     "Penalty rate change period"
  penaltyRateChange: "Penalty rate change"
et:
  loanStatus:        "Contract status"
  loanBalance:       "Contract balance"
  availableLimit:    "Contract available limit"
  interestAccrued:   "Interest accrued"
  loanDebt:          "Contract debt"
  totalDebt:         "Customer total debt"
  totalBalance:      "Customer total balance"
  prepayment:        "Prepayment balance"
  totalPrepayment:   "Customer total prepayment"
  collateral:        "Collateral"
  borrower:          "Borrower"
  manager:           "Manager"
  coBorrower:        "Co-Borrower"
  warrantor:         "Warrantor"
  agreedConfirm:     "Are you sure you want to write-off loan?"
  penaltyPeriod:     "Penalty rate change period"
  penaltyRateChange: "Penalty rate change"
ru:
  loanStatus:        "Contract status"
  loanBalance:       "Contract balance"
  availableLimit:    "Contract available limit"
  interestAccrued:   "Interest accrued"
  loanDebt:          "Contract debt"
  totalDebt:         "Customer total debt"
  totalBalance:      "Customer total balance"
  prepayment:        "Prepayment balance"
  totalPrepayment:   "Customer total prepayment"
  collateral:        "Collateral"
  borrower:          "Borrower"
  manager:           "Manager"
  coBorrower:        "Co-Borrower"
  warrantor:         "Warrantor"
  agreedConfirm:     "Are you sure you want to write-off loan?"
  penaltyPeriod:     "Penalty rate change period"
  penaltyRateChange: "Penalty rate change"
</i18n>
