<template lang="pug">
.loan-collateral-mortgage-fields-display.animated.fadeIn
  fi-data-field(:label = '$t("type")') {{ classifierById('mortgageTypes', mortgageData.mortgageTypeId).human }}
  fi-data-field(:label = '$t("amount")') {{ mortgageData.amount | money }}
  fi-data-field(:label = '$t("setDate")') {{ $t('common:formatDate', { value: mortgageData.setDate }) }}
  fi-data-field(:label = '$t("pledgee")') {{ pledgeeById(mortgageData.pledgeeId).name }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'

export default {
  name: 'loan-collateral-mortgage-fields-display',

  components: { FiDataField },

  props: {
    mortgageData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('pledgees', ['pledgeeById'])
  }
}
</script>


<i18n>
en:
  type:    "Type"
  amount:  "Amount"
  setDate: "Set date"
  pledgee: "Pledgee"
et:
  type:    "Type"
  amount:  "Amount"
  setDate: "Set date"
  pledgee: "Pledgee"
ru:
  type:    "Type"
  amount:  "Amount"
  setDate: "Set date"
  pledgee: "Pledgee"
</i18n>
