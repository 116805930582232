<template lang="pug">
.card.loan-overview-customer
  .card-body
    .row
      .col
        h4.m-0(v-if = 'customer'): router-link(:to = '{ name: "Customer", params: { id: customer.id || customer.customerId } }') {{ customerName }} ({{ customer.idCode }})
        p.m-0 {{ customerType }}
        template(v-if = 'customer')
          p.m-0: small.text-muted {{ phoneNumber }}
          p.m-0: small.text-muted {{ email }}
      .col-auto.pl-0
        slot

</template>


<script>
import { customerName, findCustomerMainContact } from '@/helpers'

export default {
  name: 'loan-overview-customer',

  props: {
    customer: {
      type: Object,
      default: null
    },
    customerType: {
      type: String,
      required: true
    }
  },

  computed: {
    email () {
      const { emails, email } = this.customer
      return findCustomerMainContact(emails, email)
    },
    phoneNumber () {
      const { phoneNumbers, phone } = this.customer
      return findCustomerMainContact(phoneNumbers, phone)
    },
    customerName () {
      return customerName(this.customer)
    }
  }
}
</script>
