<template lang="pug">
b-modal.loan-terms-relations-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t(title)'
  size    = 'xl'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("customer")'
      :state     = '!$v.relationData.customerId.$error'
      label-cols = 4
    )
      template(#error)
        .error-message(v-if = '!$v.relationData.customerId.required') {{ $t('common:required') }}
      fi-customer-select(
        v-model      = 'selectedCustomerModel'
        :placeholder = '$t("common:selectBlank")'
        :class       = '{ "is-invalid": $v.relationData.customerId.$error }'
      )
    .form-row
      .col-sm-12.mb-3
        .offset-sm-4.my-2 - {{ $t('or') }} -
        customer-new-brief(
          v-if    = 'createNewCustomer'
          @cancel = 'createNewCustomer = false'
          @submit = 'onCreateCustomer'
        )
        button.btn.btn-link.px-0.offset-sm-4(
          v-else
          @click.prevent = 'createNewCustomer = true'
        ) {{ $t('createCustomer') }}
    fi-form-field(
      :label     = '$t("amount")'
      :state     = '!$v.relationData.guaranteedAmount.$error'
      label-cols = 4
    )
      template(#error)
        .error-message(v-if = '!$v.relationData.guaranteedAmount.required') {{ $t('common:required') }}
      fi-money-input.form-control.form-control-sm(
        v-model.number = '$v.relationData.guaranteedAmount.$model'
        :class         = '{ "is-invalid": $v.relationData.guaranteedAmount.$error }'
      )
    fi-form-field(
      v-if = 'isGuarantor'
      :label     = '$t("guaranteedPercentageFromAmount")'
      :info      = '$t("regularNotice")'
      label-cols = 4
    )
      percent-input.form-control.form-control-sm(
        v-model = 'relationData.guaranteedPercentageFromAmount'
      )
    fi-form-field(
      :label     = '$t("validFrom")'
      label-cols = 4
    )
      fi-datepicker(
        v-model = 'relationData.validFrom'
        sm
      )
    fi-form-field(
      :label     = '$t("validUntil")'
      label-cols = 4
    )
      fi-datepicker(
        v-model = 'relationData.validUntil'
        sm
      )
  template(#modal-footer)
    button.btn.btn-primary(:disabled      = 'saving'
                           type           = 'submit'
                           @click.prevent = 'onSubmit')
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(:disabled      = 'saving'
                             @click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiMultiselect from '@/components/FiMultiselect'
import PercentInput from '@/components/PercentInput'
import FiModal from '@/components/FiModal'
import FiDatepicker from '@/components/FiDatepicker'
import { validation, validators } from '@/validation'
import pick from 'lodash/pick'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import api from '@/api'

export default {
  name: 'loan-terms-relations-modal',

  components: {
    FiCustomerSelect,
    FiMoneyInput,
    FiFormField,
    FiMultiselect,
    FiModal,
    FiDatepicker,
    PercentInput,
    CustomerNewBrief
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    relation: {
      type: Object,
      default: () => ({})
    },
    loader: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      selectedCustomer: null,
      createNewCustomer: false,
      relationData: {
        customerId: '',
        guaranteedAmount: 0,
        relatedCustomerType: '',
        guaranteedPercentageFromAmount: 0,
        validFrom: '',
        validUntil: ''
      }
    }
  },

  computed: {
    title () {
      return this.relation.relationId ? 'edit' : 'add'
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    isGuarantor () {
      return this.relation.relatedCustomerType?.code === 'GUARANTOR' || this.relation.relatedCustomerType === 'GUARANTOR'
    },
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        this.relationData.customerId = (selected && selected.id) || ''
      }
    }
  },

  validations: {
    relationData: {
      customerId: {
        required: validators.required
      },
      guaranteedAmount: {
        required: validators.required
      }
    }
  },

  methods: {
    async onSubmit () {
      const { relationId } = this.relation
      if (this.validate()) {
        this.$emit('update', {
          relationId,
          relation: this.relationData,
          done: () => this.$refs.modal.hide()
        })
      }
    },
    async onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedCustomerModel = customer
    },
    async resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
      this.relationData = {
        ...this.relationData,
        ...pick(this.relation, Object.keys(this.relationData))
      }
      if (this.relation.relationId) {
        this.relationData.relatedCustomerType = this.relation.relatedCustomerType.id
        this.selectedCustomer = (await api.getCustomer({ id: this.relation.customerId })).data
      }
      this.$v.$reset()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    }
  }
}
</script>


<i18n>
en:
  add:            "Add relation"
  edit:           "Edit relation"
  customer:       "Customer"
  amount:         "Amount"
  guaranteedPercentageFromAmount:         "Guaranteed percentage from amount"
  validFrom:      "Valid from"
  validUntil:     "Valid until"
  createCustomer: "Create customer"
  regularNotice:  "Used only fee guarantee calculation"
et:
  add:            "Add relation"
  edit:           "Edit relation"
  customer:       "Customer"
  amount:         "Amount"
  guaranteedPercentageFromAmount:         "Guaranteed percentage from amount"
  validFrom:      "Valid from"
  validUntil:     "Valid until"
  createCustomer: "Create customer"
  regularNotice:  "Used only fee guarantee calculation"
ru:
  add:            "Add relation"
  edit:           "Edit relation"
  customer:       "Customer"
  amount:         "Amount"
  guaranteedPercentageFromAmount:         "Guaranteed percentage from amount"
  validFrom:      "Valid from"
  validUntil:     "Valid until"
  createCustomer: "Create customer"
  regularNotice:  "Used only fee guarantee calculation"
</i18n>
