<template lang='pug'>
  .card.loan-payment-relation-list
    .card-header.border-bottom-0 {{ $t('title') }}
      .fa-pull-right
        .btn-group.btn-group-sm
          button.btn.btn-primary.animated.fadeIn(
            v-if           = '!newForm'
            @click.prevent = 'newForm = true'
          ) {{ $t('newLink') }}
    loan-payment-relation-form.border-top.card-body.animated.fadeIn(
      v-if     = 'newForm'
      v-model  = 'newForm'
      :loading = '$vueLoading.isLoading("paymentRelation:create:save")'
      @submit  = 'newPaymentRelation'
    )
    loan-payment-relation-list-item.border-top.card-body(
      v-for = 'paymentRelation in paymentRelations'
      :key  = 'paymentRelation.relationId'
      :payment-relation = 'paymentRelation'
      :editable.sync = 'editable'
      v-on = 'itemListeners'
    )
</template>


<script>
import LoanPaymentRelationListItem from '@/views/loans/LoanTerms/LoanPaymentRelationListItem'
import LoanPaymentRelationForm from '@/views/loans/LoanTerms/LoanPaymentRelationForm'
import pick from 'lodash/pick'

export default {
  name: 'loan-payment-relation-list',

  components: { LoanPaymentRelationListItem, LoanPaymentRelationForm },

  props: {
    paymentRelations: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    newForm: false,
    editable: true
  }),

  computed: {
    itemListeners () {
      return pick(this.$listeners, ['save', 'remove'])
    }
  },

  methods: {
    newPaymentRelation (paymentRelationData) {
      this.$emit('create', {
        done: () => {
          this.newForm = false
        },
        paymentRelationData
      })
    }
  }
}
</script>


<i18n>
en:
  title:           "Contract payout"
  beneficiary:     "Existing customer"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  description:     "Description"
  referenceNumber: "Reference number"
  paymentWay:      "Payment destination"
  newLink:         "+ New payout relation"
  removeConfirm:   "Are you sure to remove relation?"
et:
  title:           "Contract payout"
  beneficiary:     "Existing customer"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  description:     "Description"
  referenceNumber: "Reference number"
  paymentWay:      "Payment destination"
  newLink:         "+ New payout relation"
  removeConfirm:   "Are you sure to remove relation?"
ru:
  title:           "Contract payout"
  beneficiary:     "Existing customer"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  description:     "Description"
  referenceNumber: "Reference number"
  paymentWay:      "Payment destination"
  newLink:         "+ New payout relation"
  removeConfirm:   "Are you sure to remove relation?"
</i18n>
