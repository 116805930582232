import { render, staticRenderFns } from "./LoanPaymentRelationForm.vue?vue&type=template&id=4514151b&lang=pug&"
import script from "./LoanPaymentRelationForm.vue?vue&type=script&lang=js&"
export * from "./LoanPaymentRelationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports