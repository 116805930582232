<template lang="pug">
.card.loan-overview-details
  .card-header {{ $t('loanDetails') }}
  .card-body
    fi-data-field(
      v-if = 'loan.signingDate'
      :label = '$t("signingDate")'
      label-cols = '5'
    ) {{ $t('common:formatDate', { value: loan.signingDate }) }}
    fi-data-field(:label = '$t("reference")' label-cols = '5')
      router-link(
        :to  = '{ name: "CustomerPaymentInfo", params: { id: loan.customerBorrower.id, reference: loan.account.referenceNumber } }'
      ) {{ loan.account.referenceNumber }}
    fi-data-field(
      v-if   = '!overdraft'
      :label = '$t("creditAmount")'
      label-cols = '5'
    ) {{ schedule.amount | money }}
    fi-data-field(
      v-if = 'leasing && product.principalWithVat'
      :label = '$t("loanAmountVAT")'
      label-cols = '5'
    )
      .form-row
        .col {{ schedule.amountWithVat | money }}
        .col
        .col {{ schedule.vatRate | percent }}
    fi-data-field(:label = '$t("loanLimit")' label-cols = '5') {{ loan.amountLimit | money }}
    template(v-if = 'leasing')
      fi-data-field(:label = '$t("upfrontAmount")' label-cols = '5')
        .form-row
          template(v-if = 'percentageUpfrontAmount')
            .col {{ schedule.upfrontAmount | percent }}
            .col
            .col {{ upfrontAmountInform | money }}
          template(v-else)
            .col {{ upfrontAmountInform | percent }}
            .col
            .col {{ schedule.upfrontAmount | money }}
      fi-data-field(:label = '$t("residualAmount")' label-cols = '5')
        .form-row
          template(v-if = 'percentageResidualAmount')
            .col {{ schedule.residualAmount | percent }}
            .col
            .col {{ residualAmountInform | money }}
          template(v-else)
            .col {{ residualAmountInform | percent }}
            .col
            .col {{ schedule.residualAmount | money }}
    fi-data-field(:label = '$t("startDate")' label-cols = '5') {{ $t('common:formatDate', { value: loan.periodStart }) }}
    fi-data-field(:label = '$t("endDate")' label-cols = '5') {{ $t('common:formatDate', { value: loan.periodEnd }) }}
    fi-data-field(
      v-if   = 'overdraft'
      :label = '$t("invoiceDay")'
      label-cols = '5'
    ) {{ loan.invoiceDay }}
    fi-data-field(:label = '$t("interestRate")' label-cols = '5')
      .form-row
        .col {{ schedule.interestRate | percent }}
        .col
        .col {{ dayCountConvention ? dayCountConvention.human : '' }}
    fi-data-field(:label = '$t("interestBaseRate")' label-cols = '5')
      .form-row
        .col {{ schedule.variableInterestRate | percent('0.000 %') }}
        .col {{ interestName }}
        .col {{ 'Σ ' }}{{ (schedule.interestRate + schedule.variableInterestRate) || schedule.interestRate || 0 | percent('0.000 %') }}
    fi-data-field(:label = '$t("penaltyRate")' label-cols = '5') {{ loan.penaltyInterestRate | percent }}
    fi-data-field(
      v-if   = 'penaltyRateChange'
      :label = '$t("tempPenaltyRate")'
      label-cols = '5'
    )
      .form-row
        .col-3 {{ penaltyRateChange.penaltyRate | percent }}
        .col {{ $t('common:formatDate', { value: penaltyRateChange.startDate }) }}
        .col {{ $t('common:formatDate', { value: penaltyRateChange.endDate }) }}
    fi-fee-item(
      v-for         = '(fee, index) in availableFees'
      :key          = 'index'
      :fee          = 'fee'
      :amount       = 'loan.amountLimit'
      label-cols    = '5'
      :fee-configs  = 'feeConfigs'
    )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapGetters } from 'vuex'
import { feeDisabledMixin } from '@/mixins'
import { convertFee, feePercentage } from '@/helpers'
import FiFeeItem from '@/components/FiFeeItem'

export default {
  name: 'loan-overview-details',

  components: { FiFeeItem, FiDataField },

  mixins: [feeDisabledMixin],

  props: {
    loan: {
      type: Object,
      required: true
    },
    overdraft: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      required: true
    },
    leasing: {
      type: Boolean,
      default: false
    },
    loanSchedule: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName', 'optionsFromClassifier']),
    ...mapGetters('settings', ['settingByKey']),
    dayCountConvention () {
      return this.classifierByName(this.schedule.dayCountConvention.classifier, this.schedule.dayCountConvention.code) ?? {}
    },
    percentageUpfrontAmount () {
      return feePercentage(this.schedule?.upfrontAmountCalculationType?.code)
    },
    percentageResidualAmount () {
      return feePercentage(this.schedule?.residualAmountCalculationType?.code)
    },
    upfrontAmountInform () {
      return convertFee({
        feeValue: this.schedule.upfrontAmount,
        isPercentage: !this.percentageUpfrontAmount,
        amountValue: this.schedule?.amount
      })
    },
    residualAmountInform () {
      return convertFee({
        feeValue: this.schedule.residualAmount,
        isPercentage: !this.percentageResidualAmount,
        amountValue: this.schedule?.amount
      })
    },
    schedule () {
      return this.loanSchedule
    },
    penaltyRateChange () {
      return this.schedule.penaltyRateChange
    },
    availableFees () {
      const excludedFees = ['TERMINATION', 'AUTO_EXTENSION']
      return this.schedule.fees.filter(
        ({ feeType }) => !excludedFees.includes(feeType.code.$model ?? feeType.code)
      )
    },
    interestName () {
      if (this.schedule.variableInterestDto?.name) {
        return this.schedule.variableInterestDto.name
      } else {
        return 'FIXED'
      }
    }
  }
}
</script>


<i18n>
en:
  loanDetails:                   "Contract details"
  signingDate:                   "Signing date"
  reference:                     "Reference"
  creditAmount:                    "Contract amount"
  loanAmountVAT:                 "Contract amount with VAT"
  loanLimit:                     "Contract limit"
  startDate:                     "Start date"
  endDate:                       "End date"
  invoiceDay:                    "Invoice day"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty rate"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  tempPenaltyRate:               "Temporary penalty interest"
et:
  loanDetails:                   "Contract details"
  signingDate:                   "Signing date"
  reference:                     "Reference"
  creditAmount:                    "Contract amount"
  loanAmountVAT:                 "Contract amount with VAT"
  loanLimit:                     "Contract limit"
  startDate:                     "Start date"
  endDate:                       "End date"
  invoiceDay:                    "Invoice day"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty rate"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  tempPenaltyRate:               "Temporary penalty interest"
ru:
  loanDetails:                   "Contract details"
  signingDate:                   "Signing date"
  reference:                     "Reference"
  creditAmount:                    "Contract amount"
  loanAmountVAT:                 "contract amount with VAT"
  loanLimit:                     "Contract limit"
  startDate:                     "Start date"
  endDate:                       "End date"
  invoiceDay:                    "Invoice day"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty rate"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  tempPenaltyRate:               "Temporary penalty interest"
</i18n>
