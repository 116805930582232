<template lang="pug">
fi-modal.loan-collateral-mortgage-new-modal(ref     = 'modal'
                                            v-model = 'showModal'
                                            :title  = '$t("title")'
                                            size    = 'md'
                                            lazy
                                            @shown  = 'resetData'
                                            @hide   = 'onHide')
  form.form-horizontal(@submit.prevent = 'onSubmit')
    loan-collateral-mortgage-fields-edit(:mortgage-data = 'mortgageData')
  template(#modal-footer)
    button.btn.btn-primary(:disabled      = 'saving'
                           @click.prevent = 'onSubmit')
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(:disabled      = 'saving'
                             @click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>


<script>
import LoanCollateralMortgageFieldsEdit from './LoanCollateralMortgageFieldsEdit'
import FiModal from '@/components/FiModal'
import { mapActions } from 'vuex'

export default {
  name: 'loan-collateral-mortgage-new-modal',

  components: {
    LoanCollateralMortgageFieldsEdit,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      mortgageData: {
        mortgageTypeId: '',
        amount: null,
        setDate: '',
        pledgeeId: ''
      }
    }
  },

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('loan:mortgage:save')
    }
  },

  methods: {
    ...mapActions('loans', ['createLoanMortgage']),
    resetData () {
      Object.assign(this.$data, this.$options.data())
    },
    onHide (e) {
      if (this.saving) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      await this.createLoanMortgage({ mortgageData: { ...this.mortgageData } })
      this.$refs.modal.hide()
    }
  }
}
</script>


<i18n>
en:
  title: "New mortgage"
et:
  title: "New mortgage"
ru:
  title: "New mortgage"
</i18n>
