<template lang = 'pug'>
fi-modal.loan-schedule-termination-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label = '$t("periodEnd")'
      :state = '!$v.loanData.periodEnd.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.loanData.periodEnd.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model   = 'loanData.periodEnd'
        :class    = '{ "is-invalid": $v.loanData.periodEnd.$error }'
        :min-date = 'terminationMinDate'
        :max-date = 'loan.periodEnd'
        :disabled = 'isOverdraftProduct'
        sm
      )
    fi-form-field(
      :label     = '$t("terminationFee")'
      :state     = '!$v.loanData.terminationFee.$error'
    )
      template(#error)
        .error-message(v-if = '!$v.loanData.terminationFee.required') {{ $t('common:required') }}
        .error-message(v-if = '!$v.loanData.terminationFee.gte') {{ $t('gte', { value: $v.loanData.terminationFee.$params.gte.value }) }}
        .error-message(v-if = '!$v.loanData.terminationFee.lte') {{ $t('lte', { value: $v.loanData.terminationFee.$params.lte.value }) }}
      .form-row
        .col: fi-select(
          v-model.number = 'loanData.terminationFeeCalculationTypeId'
          :options       = 'calculationTypes'
          :disabled      = '!terminationFee.feeOverride'
          sm
          required
        )
        .col
          percent-input.form-control.form-control-sm(
            v-if           = 'percentageTerminationFee'
            v-model.number = 'loanData.terminationFee'
            :class         = '{ "is-invalid": $v.loanData.terminationFee.$error }'
            :disabled      = '!feeEditable(terminationFee)'
          )
          fi-money-input.form-control.form-control-sm(
            v-else
            v-model.number = 'loanData.terminationFee'
            :class         = '{ "is-invalid": $v.loanData.terminationFee.$error }'
            :disabled      = '!feeEditable(terminationFee)'
          )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'loader'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import { feeEditableMixin, isOverdraft } from '@/mixins'
import { validation, validators } from '@/validation/index'
import { mapGetters, mapState } from 'vuex'
import { calculationTypes, convertFee, convertFeeRange, feePercentage } from '@/helpers'

export default {
  name: 'loan-termination-modal',

  components: {
    FiSelect,
    FiFormField,
    FiDatepicker,
    FiMoneyInput,
    PercentInput,
    FiModal
  },

  mixins: [
    validation,
    isOverdraft,
    feeEditableMixin
  ],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    okAction: {
      type: Function,
      default: null
    },
    loader: {
      type: Boolean,
      required: true
    },
    terminationFee: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data () {
    return {
      loanData: {
        periodEnd: '',
        terminationFee: null,
        terminationFeeCalculationTypeId: ''
      }
    }
  },

  computed: {
    ...mapState('loans', ['loan', 'loanProduct', 'systemData']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById', 'classifierByName', 'classifierList']),
    ...mapGetters('settings', ['settingByKey']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    calculationTypes () {
      return calculationTypes(this.terminationFee, this.feeConfigs, this.classifierList('feeCalculationType'), true)
    },
    percentageTerminationFee () {
      return feePercentage(this.classifierById('feeCalculationType', this.loanData.terminationFeeCalculationTypeId || this.loan.terminationFeeCalculationTypeId).code)
    },
    convertedTerminationFeeRange () {
      return convertFeeRange({ ...this.terminationFee, feeCalculationType: this.classifierById('feeCalculationType', this.loanData.terminationFeeCalculationTypeId || this.loan.terminationFeeCalculationTypeId).code }, this.loan.financial.balance)
    },
    terminationMinDate () {
      return this.$moment.max(
        this.$moment(this.systemData.nextDayChange),
        this.$moment(this.loan.periodStart)
      ).toDate()
    },
    feeCalcType () {
      const { feeCalculationType } = this.terminationFee
      return this.classifierByName(feeCalculationType.classifier, feeCalculationType.code) ?? {}
    }
  },

  watch: {
    percentageTerminationFee (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }
      this.loanData.terminationFee = convertFee({
        feeValue: this.loanData.terminationFee,
        isPercentage: this.percentageTerminationFee,
        amountValue: this.loan.financial.balance
      })
    }
  },

  validations () {
    return {
      loanData: {
        periodEnd: {
          required: validators.requiredIf(function () {
            return this.feeRequired
          })
        },
        terminationFee: {
          required: validators.required,
          gte: validators.gte(this.convertedTerminationFeeRange.feeMin),
          lte: validators.lte(this.convertedTerminationFeeRange.feeMax)
        }
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      if (this.isOverdraftProduct) {
        this.loanData.periodEnd = this.$moment().format('YYYY-MM-DD')
      }
      this.loanData.terminationFee = this.terminationFee.feeAmount
      this.loanData.terminationFeeCalculationTypeId = this.feeCalcType.id
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    async onSubmit () {
      if (this.validate()) {
        if (this.okAction) {
          this.okAction({ id: this.loan.id, data: this.loanData })
        } else {
          this.$emit('submit', { id: this.loan.id, data: this.loanData })
        }

        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  title:          "Full repayment"
  periodEnd:      "Repayment date"
  terminationFee: "Termination fee"
  gte:            "Field value should be greater or equal to {{value}}"
  lte:            "Field value should be less or equal to {{value}}"
et:
  title:          "Full repayment"
  periodEnd:      "Repayment date"
  terminationFee: "Termination fee"
  gte:            "Field value should be greater or equal to {{value}}"
  lte:            "Field value should be less or equal to {{value}}"
ru:
  title:          "Full repayment"
  periodEnd:      "Repayment date"
  terminationFee: "Termination fee"
  gte:            "Field value should be greater or equal to {{value}}"
  lte:            "Field value should be less or equal to {{value}}"
</i18n>
