<template lang='pug'>
.card.loan-overview-schedule
  .card-header {{ $t('title') }} ({{ scheduleStatus.human }})
    .fa-pull-right.animated.fadeIn(v-if ='flexible && scheduleStatus.code === "PENDING"')
      a.animated.fadeIn(
        v-if           = 'editable && !editing'
        href           = '#'
        @click.prevent = 'setEditing(true)'
      ): i.far.fa-edit.fa-lg
      .animated.fadeIn(v-else-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          @click    = 'onSubmit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled = 'saving'
          @click    = 'setEditing(false)'
        ) {{ $t('common:cancel') }}
  loan-schedule-invoice-list(
    :invoices = 'schedule.loanInvoices'
    :editing  = 'editing'
    :flexible  = 'flexible'
    :vat      = 'loanProduct.principalWithVat'
    @input    = 'setPrincipals'
  )
</template>


<script>
import LoanScheduleInvoiceList from '@/views/loans/LoanSchedule/LoanScheduleInvoiceList'
import { mapActions, mapState } from 'vuex'
import { isLoanLeasing } from '@/mixins'
import merge from 'lodash/merge'
import reduce from 'lodash/reduce'

export default {
  name: 'loan-overview-schedule',

  components: { LoanScheduleInvoiceList },

  mixins: [isLoanLeasing],

  props: {
    scheduleStatus: {
      type: Object,
      required: true
    },
    flexible: Boolean,
    editable: Boolean,
    schedule: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    editing: false,
    flexiblePrincipalsData: {}
  }),

  computed: {
    ...mapState('loans', ['loan']),
    saving () {
      return this.$vueLoading.isLoading('loan:schedule:principals:save')
    },
    principalsDataValidated () {
      return reduce(
        merge(
          {},
          this.schedule.loanInvoices
            .slice(0, -1)
            .map(invoice => ({ amount: invoice.principal, paymentDate: invoice.paymentDate }))
            .reduce((result, invoice, index) => ({ ...result, [index]: invoice }), {}),
          this.flexiblePrincipalsData
        ),
        (sum, item) => sum + (item.amount ? item.amount : 0),
        0
      ) < this.schedule.amount
    }
  },

  watch: {
    'schedule.loanInvoices' () {
      this.setEditing(false)
    }
  },

  methods: {
    ...mapActions('loans', ['updateLoanSchedulePrincipals']),
    setEditing (editing = !this.editing) {
      this.editing = editing
      this.$emit('update:editable', this.editing)
      this.flexiblePrincipalsData = {}
    },
    async onSubmit () {
      if (this.principalsDataValidated) {
        try {
          await this.updateLoanSchedulePrincipals({ principalsData: Object.values(this.flexiblePrincipalsData) })
          this.setEditing(false)
        } catch (e) {
          this.$toasted.error(e)
        }
      } else {
        this.$toasted.error(this.$t('principalsSumError'))
      }
    },
    setPrincipals (e) {
      this.flexiblePrincipalsData = { ...this.flexiblePrincipalsData, ...e }
    }
  }
}
</script>


<i18n>
en:
  title:              "Schedule"
  principalsSumError: "Sum of contract principals is greater than contract amount"
et:
  title:              "Schedule"
  principalsSumError: "Sum of contract principals is greater than contract amount"
ru:
  title:              "Schedule"
  principalsSumError: "Sum of contract principals is greater than contract amount"
</i18n>
