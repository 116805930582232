  <template lang="pug">
.loan-overdraft-term-details.card
  .card-header {{ $t('title') }}
    button.btn.btn-sm.btn-primary.fa-pull-right(
      v-if           = 'editable && !editing && status.name === "PENDING"'
      href           = '#'
      @click.prevent = 'toggleEditing'
    ) {{ $t('common:edit') }}
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')
      template(v-if = 'editing')
        fi-data-field(
          v-if   = 'loan.status.code !== "INSERTED"'
          :label = '$t("balance")'
        ) {{ loan.financial.balance | money }}

        fi-form-field(
          :label = '$t("limit")'
          :state = '!$v.termData.limit.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.termData.limit.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.termData.limit.gte') {{ $t('gte', { value: product.amountMin }) }}
            .error-message(v-if = '!$v.termData.limit.lte') {{ $t('lte', { value: product.amountMax }) }}
          fi-money-input.form-control.form-control-sm(
            v-model.number = 'termData.limit'
            :class         = '{ "is-invalid": $v.termData.limit.$error }'
            :disabled      = 'product.amountMin === product.amountMax'
          )
        fi-form-field(
          :label = '$t("dateStartEnd")'
          :state = '!$v.periodGroup.$error'
        )
          template(#error)
            .form-row
              .col
                .error-message(v-if = '!$v.termData.periodStart.required') {{ $t('common:required') }}
              .col
                .error-message(v-if = '!$v.termData.periodEnd.required') {{ $t('common:required') }}
          .form-row
            .col
              fi-datepicker(
                v-model   = 'termData.periodStart'
                :class    = '{ "is-invalid": $v.termData.periodStart.$error }'
                :min-date = 'startDateAllowed'
                sm
              )
            .col
              fi-datepicker(
                v-model         = 'termData.periodEnd'
                :min-date       = 'periodMinDate'
                :max-date       = 'periodMaxDate'
                :class          = '{ "is-invalid": $v.termData.periodEnd.$error }'
                sm
              )
        fi-data-field(:label = '$t("invoiceDay")') {{ loan.invoiceDay }}

        fi-form-field(
          :label = '$t("interestRate")'
          :state = '!$v.termData.interestRate.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.termData.interestRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.termData.interestRate.gte') {{ $t('gte', { value: product.interestRateMin }) }}
            .error-message(v-if = '!$v.termData.interestRate.lte') {{ $t('lte', { value: product.interestRateMax }) }}
          .form-row
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'termData.interestRate'
              type           = 'text'
              :class         = '{ "is-invalid": $v.termData.interestRate.$error }'
              :disabled      = 'product.interestRateMin === product.interestRateMax'
            )
            .col: input.form-control-plaintext(
              :value = 'dayCountConvention.human'
              disabled
            )

        template(v-if='false')
          fi-data-field(:label = '$t("interestFreq")') {{ interestPaymentPeriod.human }}

        fi-form-field(
          :label = '$t("penaltyRate")'
          :state = '!$v.termData.penaltyRate.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.termData.penaltyRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.termData.penaltyRate.gte') {{ $t('gte', { value: product.penaltyInterestRateMin }) }}
            .error-message(v-if = '!$v.termData.penaltyRate.lte') {{ $t('lte', { value: product.penaltyInterestRateMax }) }}
          .form-row
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'termData.penaltyRate'
              type           = 'text'
              :class         = '{ "is-invalid": $v.termData.penaltyRate.$error }'
              :disabled      = 'product.penaltyInterestRateMin === product.penaltyInterestRateMax'
            )
            .col: input.form-control-plaintext(:value = 'penaltyDayCountConvention.human'
                                               disabled)

        fi-fee-item(
          v-for         = '(fee, index) in availableFees'
          :key          = 'index'
          :fee          = 'fee'
          :amount       = '$v.termData.limit.$model'
          :fee-configs   = 'feeConfigs'
          :min-date     = 'minDate(fee)'
          editing
        )

        .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

      template(v-else)
        fi-data-field(
          v-if   = 'loan.status.code !== "INSERTED"'
          :label = '$t("balance")'
        ) {{ loan.financial.balance | money }}
        fi-data-field(:label = '$t("limit")') {{ term.limit | money }}
        fi-data-field(:label = '$t("dateStartEnd")')
          .form-row
            .col {{ $t('common:formatDate', { value: term.periodStart }) }}
            .col {{ $t('common:formatDate', { value: term.periodEnd }) }}
        fi-data-field(:label = '$t("invoiceDay")') {{ loan.invoiceDay }}
        fi-data-field(:label = '$t("interestRate")')
          .form-row
            .col {{ term.interestRate | percent }}
            .col {{ dayCountConvention.human }}
        template(v-if='false')
          fi-data-field(:label = '$t("interestFreq")') {{ interestPaymentPeriod.human }}
        fi-data-field(:label = '$t("penaltyRate")')
          .form-row
            .col {{ term.penaltyRate | percent }}
            .col {{ penaltyDayCountConvention.human }}
        fi-fee-item(
          v-for         = '(fee, index) in availableFees'
          :key          = 'index'
          :fee          = 'fee'
          :amount       = 'term.limit'
          :fee-configs   = 'feeConfigs'
        )

      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import { loanStatuses } from '@/const'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import { rules, validation, validators } from '@/validation/index'
import { excludeLastMonthDays } from '@/mixins'
import FiFeeItem from '@/components/FiFeeItem'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'loan-overdraft-term-details',

  components: {
    FiFeeItem,
    FiSelect,
    FiDataField,
    FiFormField,
    FiDatepicker,
    PercentInput,
    FiMoneyInput
  },

  mixins: [
    validation,
    excludeLastMonthDays
  ],

  props: {
    loan: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    term: {
      type: Object,
      required: true
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    },
    editable: Boolean
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      error: null,
      termData: {
        limit: null,
        interestRate: null,
        penaltyRate: null,
        periodStart: '',
        periodEnd: '',
        status: '',
        fees: []
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'optionsFromClassifier', 'classifierByName']),
    ...mapGetters('loans', ['scheduleById']),
    ...mapState('loans', ['systemData']),
    dayCountConvention () {
      return this.classifierById(this.term.dayCountConvention.classifier, this.term.dayCountConvention.id)
    },
    penaltyDayCountConvention () {
      return this.classifierById(this.term.penaltyDayCountConvention.classifier, this.term.penaltyDayCountConvention.id)
    },
    scheduleType () {
      return this.classifierById(this.term.scheduleType.classifier, this.term.scheduleType.id)
    },
    status () {
      return (this.term.status && this.classifierById(this.term.status.classifier, this.term.status.id)) || {}
    },
    saving () {
      return this.$vueLoading.isLoading('overdraft:term:save')
    },
    startDateAllowed () {
      return this.loan.status.id === loanStatuses.active && this.systemData.nextDayChange
    },
    periodUnit () {
      return this.classifierById('periodUnitType', this.product.periodUnitTypeId)
    },
    periodMinDate () {
      return this.$moment(this.termData.periodStart).add(this.product.periodMin, this.periodUnit.name).toDate()
    },
    periodMaxDate () {
      return this.$moment(this.termData.periodStart).add(this.product.periodMax, this.periodUnit.name).toDate()
    },
    availableFees () {
      const fees = (this.editing ? Object.values(this.$v.termData.fees.$each.$iter) : this.term.fees)
      return fees.filter(
        ({ feeType }) => {
          return (this.loan.status.code !== 'ACTIVE' ||
            (
              (feeType.code.$model ?? feeType.code) !== 'TERMINATION' &&
              (feeType.code.$model ?? feeType.code) !== 'AUTO_EXTENSION'
            )
          )
        }
      )
    }
  },

  validations () {
    return {
      termData: merge({}, pick(rules.overdraftRules, Object.keys(this.termData)), {
        limit: {
          gte: validators.gte(this.product.amountMin),
          lte: validators.lte(this.product.amountMax)
        },
        interestRate: {
          gte: validators.gte(this.product.interestRateMin),
          lte: validators.lte(this.product.interestRateMax)
        },
        penaltyRate: {
          gte: validators.gte(this.product.penaltyInterestRateMin),
          lte: validators.lte(this.product.penaltyInterestRateMax)
        }
      }),
      periodGroup: ['termData.periodStart', 'termData.periodEnd']
    }
  },

  methods: {
    ...mapActions('loans', ['updateLoanOverdraftTerms']),
    toggleEditing () {
      this.editing = !this.editing
      if (this.editing) {
        this.resetData()
      }
      this.$emit('update:editable', this.editing)
    },
    resetData () {
      this.$v.$reset()
      this.error = null
      this.termData = cloneDeep({
        ...merge(
          {},
          this.termData,
          pick(this.term, Object.keys(this.termData))
        ),
        status: this.status.name
      })
    },
    async onSubmit () {
      if (this.validate()) {
        try {
          this.error = null
          await this.updateLoanOverdraftTerms({
            overdraftId: this.loan.id,
            termsData: {
              ...this.termData,
              fees: this.termData.fees.map(({ date, feeAmount, feeType, feeCalculationType }) => ({
                date,
                feeAmount,
                feeType: feeType.code,
                feeCalculationType: feeCalculationType.code
              }))
            },
            termId: this.term.id
          })
          this.toggleEditing()
        } catch (e) {
          this.error = e
        }
      }
    },
    minDate (fee) {
      return fee.feeType.code.$model === 'AGREEMENT' || fee.feeType.code.$model === 'GUARANTEE' ? this.$moment.max([this.$moment(this.systemData.nextDayChange), this.$moment(this.termData.periodStart)]).toDate() : null
    }
  }
}
</script>


<i18n>
en:
  title:             "Term details"
  balance:           "Contract balance"
  limit:             "Contract limit"
  dateStartEnd:      "Start/End date"
  invoiceDay:        "Invoice day"
  interestRate:      "Interest rate"
  penaltyRate:       "Penalty rate"
  scheduleType:      "Schedule type"
  principalFreq:     "Principal frequency"
  firstPrincipalDay: "1st principal day"
  interestFreq:      "Interest frequency"
  paybackAmount:     "Early payback amount"
  loanBetween:       "Contract amount must be less than contract limit"
  principalFirstErr: "Principal first payment day must be between {{ begin }} and {{ end }}"
  gteToday:          "Schedule start date cannot be in past"
  gte:               "Field value should be greater or equal to {{value}}"
  lte:               "Field value should be less or equal to {{value}}"
et:
  title:             "Term details"
  balance:           "Contract balance"
  limit:             "Contract limit"
  dateStartEnd:      "Start/End date"
  invoiceDay:        "Invoice day"
  interestRate:      "Interest rate"
  penaltyRate:       "Penalty rate"
  scheduleType:      "Schedule type"
  principalFreq:     "Principal frequency"
  firstPrincipalDay: "1st principal day"
  interestFreq:      "Interest frequency"
  paybackAmount:     "Early payback amount"
  loanBetween:       "Contract amount must be less than contract limit"
  principalFirstErr: "Principal first payment day must be between {{ begin }} and {{ end }}"
  gteToday:          "Schedule start date cannot be in past"
  gte:               "Field value should be greater or equal to {{value}}"
  lte:               "Field value should be less or equal to {{value}}"
ru:
  title:             "Term details"
  balance:           "Contract balance"
  limit:             "Contract limit"
  dateStartEnd:      "Start/End date"
  invoiceDay:        "Invoice day"
  interestRate:      "Interest rate"
  penaltyRate:       "Penalty rate"
  scheduleType:      "Schedule type"
  principalFreq:     "Principal frequency"
  firstPrincipalDay: "1st principal day"
  interestFreq:      "Interest frequency"
  paybackAmount:     "Early payback amount"
  loanBetween:       "Contract amount must be less than contract limit"
  principalFirstErr: "Principal first payment day must be between {{ begin }} and {{ end }}"
  gteToday:          "Schedule start date cannot be in past"
  gte:               "Field value should be greater or equal to {{value}}"
  lte:               "Field value should be less or equal to {{value}}"
</i18n>
