<template lang="pug">
fi-table.loan-schedule-usage-statement-list(:fields      = 'fields'
                                            :items       = 'transactions'
                                            :empty-label = '$t("notFound")'
                                            responsive)
    template(#default = '{ items }')
      loan-schedule-usage-statement-list-item(v-for        = 'transaction in items'
                                              :key         = 'transaction.id'
                                              :transaction = 'transaction')
</template>

<script>
import LoanScheduleUsageStatementListItem from '@/views/loans/LoanSchedule/LoanScheduleUsageStatementListItem'
import FiTable from '@/components/FiTable'
import { mapActions, mapState } from 'vuex'

const toAccountTypes = ['LEDGER_PRINCIPAL_BALANCE', 'PREPAYMENT']
const fromAccountTypes = ['SYSTEM', 'NOSTRO']
export default {
  name: 'loan-schedule-usage-statement-list',

  components: {
    LoanScheduleUsageStatementListItem,
    FiTable
  },

  computed: {
    ...mapState('loans', ['loanTransactions']),
    transactions () {
      return this.loanTransactions.filter(transaction => toAccountTypes.includes(transaction.toAccountType) && fromAccountTypes.includes(transaction.fromAccountType))
    },
    fields () {
      return [
        {
          key: 'time',
          label: this.$t('date')
        },
        {
          key: 'description',
          label: this.$t('description')
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        }
      ]
    }
  },

  created () {
    this.loadLoanTransactions({ filters: { accountTypes: [...toAccountTypes, fromAccountTypes], size: 50 } })
  },

  methods: {
    ...mapActions('loans', ['loadLoanTransactions'])
  }
}
</script>


<i18n>
en:
  date:        "Date"
  description: "Description"
  amount:      "Amount"
  notFound:    "No transactions found"
et:
  date:        "Date"
  description: "Description"
  amount:      "Amount"
  notFound:    "No transactions found"
ru:
  date:        "Date"
  description: "Description"
  amount:      "Amount"
  notFound:    "No transactions found"
</i18n>
