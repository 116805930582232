<template lang='pug'>
.loan-payment-relation-form.animated.fadeIn
  form(
    novalidate
    @submit.prevent = 'submitForm'
  )
    loan-payment-relation-fields(
      :payment-relation = '$v.paymentRelationData'
      :accounts         = 'bankAccountOptions'
      @select-customer  = 'selectCustomer'
    )
    button.btn.btn-primary(
      :disabled = 'loading'
      type      = 'submit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loading')
    | &nbsp;
    button.btn.btn-secondary(
      :disabled      = 'loading'
      @click.prevent = 'close'
    ) {{ $t('common:cancel') }}
</template>

<script>
import { validation, validators } from '@/validation'
import LoanPaymentRelationFields from '@/views/loans/LoanTerms/LoanPaymentRelationFields'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'
import { paymentRelationData } from '@/const'

export default {
  name: 'loan-payment-relation-form',

  components: { LoanPaymentRelationFields },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    paymentRelation: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: (vm) => ({
    selectedCustomer: null,
    paymentRelationData: {
      ...paymentRelationData,
      ...pick(vm.paymentRelation, Object.keys(paymentRelationData))
    }
  }),

  computed: {
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['classifierByName', 'classifierById']),
    editing () {
      return !!this.paymentRelation
    },
    bankAccountOptions () {
      const { externalBankAccounts = [], accounts = [] } = this.selectedCustomer ?? {}
      return (this.classifierById('paymentWays', this.paymentRelationData.paymentWayId).name === 'external'
        ? externalBankAccounts.map(({ accountExternalNumber }) => accountExternalNumber)
        : accounts.filter(({ accountTypeId }) => /prepayment/i.test(this.classifierById('accountTypes', accountTypeId).name))
          .map(({ number }) => number)).filter(Boolean) ?? []
    }
  },

  watch: {
    selectedCustomer: {
      handler (customer) {
        this.paymentRelationData = {
          ...this.paymentRelationData,
          ...(customer ? {
            beneficiaryName: this.customerName(customer),
            beneficiaryExternalAccountNumber: this.paymentRelationData?.beneficiaryExternalAccountNumber ?? this.bankAccountOptions[0],
            beneficiaryIdCode: customer?.idCode,
            customerId: customer?.id
          } : { customerId: undefined })
        }
      }
    },

    'paymentRelationData.paymentWayId' () {
      this.paymentRelationData.beneficiaryExternalAccountNumber = this.bankAccountOptions?.[0] ?? ''
    }
  },

  validations: {
    paymentRelationData: {
      amount: {
        required: validators.required
      },
      beneficiaryExternalAccountNumber: {
        required: validators.required
      },
      beneficiaryIdCode: {},
      beneficiaryName: {
        required: validators.required
      },
      customerId: {},
      subtractFeeAccount: {},
      description: {},
      referenceNumber: {},
      paymentWayId: {
        required: validators.required
      }
    }
  },

  created () {
    if (this.paymentRelation) {
      this.selectCustomer({
        id: this.paymentRelation?.customerId,
        customerName: this.paymentRelation?.beneficiaryName,
        idCode: this.paymentRelation?.beneficiaryIdCode
      })
    }
  },

  methods: {
    submitForm () {
      if (this.validate()) {
        this.$emit('submit', this.paymentRelationData)
      }
    },
    close () {
      this.$emit('input', false)
    },
    selectCustomer (customer) {
      this.selectedCustomer = customer
    }
  }
}
</script>
