<template lang='pug'>
fi-modal.loan-initiate-outgoing-payment-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :state = '!$v.loanData.payableAfterTime.$error'
      :label = '$t("payableAfterTime")'
    )
      template(#error)
        .error-message(v-if = '!$v.loanData.payableAfterTime.required') {{ $t('common:required') }}
      input.form-control.form-control-sm(
        v-model = '$v.loanData.payableAfterTime.$model'
        :class  = '{ "is-invalid": $v.loanData.payableAfterTime.$error }'
      )

    fi-table(
      :fields = 'fields'
      :items  = 'loanData.loanRelatedPayments'
      loader  = 'loan:paymentRelations:fetch'
    )
      template(#actions)
        button.btn.btn-sm.btn-link(@click.prevent = 'addPayout'): i.fa.fa-plus.fa-lg
      template(#default = '{ items }')
        tr(v-for = '(item, index) in items')
          td {{ item.beneficiaryName }}
          td {{ classifierById('paymentWays', item.paymentWayId).human }}
          td {{ item.beneficiaryExternalAccountNumber }}
          td.money {{ item.amount | money }}
          td
            button.btn.btn-sm.btn-link(@click.prevent = 'editPayout(index)'): i.far.fa-edit.fa-lg
            button.btn.btn-sm.btn-link.text-danger(@click.prevent = 'removePayout(index)'): i.far.fa-trash-alt.fa-lg
      template(#custom-footer)
        tr
          td.text-right.font-weight-bold(:colspan = 'fields.length - 2') Total payment amount:
          td.money {{ loanData.loanRelatedPayments.reduce((sum, { amount }) => sum + amount, 0) | money }}
          td
        tr(v-if = '$v.splitDifference.$invalid')
          td(:colspan = 'fields.length')
            .error-message.alert-danger.alert {{ $t('amountError', { amount: totalPayoutAmount }) }}
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'loader'
      @click.prevent = 'onSubmit'
    )
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'loader')
    button.btn.btn-secondary(
      :disabled      = 'loader'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
  loan-payout-relation-modal(
    v-if              = 'showPayoutModal'
    v-model           = 'showPayoutModal'
    :payment-relation = 'currentRelation'
    :callback         = 'updatePayouts'
  )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDatepicker from '@/components/FiDatepicker'
import FiModal from '@/components/FiModal'
import FiTable from '@/components/FiTable'
import { validation, validators } from '@/validation'
import LoanPayoutRelationModal from '@/views/loans/LoanPayoutRelationModal'
import { mapActions, mapState } from 'vuex'
import { isOverdraft } from '@/mixins'
import unionWith from 'lodash/unionWith'
import sortBy from 'lodash/sortBy'
import BigNumber from 'bignumber.js'
import { paymentRelationData } from '@/const'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'loan-initiate-outgoing-payment-modal',

  components: {
    FiTable,
    LoanPayoutRelationModal,
    FiFormField,
    FiDatepicker,
    FiModal
  },

  mixins: [validation, isOverdraft],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    loan: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    },
    loader: {
      type: Boolean
    },
    schedule: {
      type: Object,
      required: true
    },
    payoutAmount: {
      type: Number,
      default: 0
    },
    freeInput: {
      type: Boolean
    }
  },

  i18nOptions: {},

  data () {
    return {
      showPayoutModal: false,
      currentRelation: null,
      loanData: {
        payableAfterTime: '',
        loanRelatedPayments: []
      }
    }
  },

  computed: {
    ...mapState('loans', ['systemData', 'loanPaymentRelations']),
    totalPayoutAmount () {
      return this.payoutAmount
    },
    splitDifference () {
      return this.loanData.loanRelatedPayments.reduce((sum, { amount }) => sum.minus(amount), new BigNumber(this.totalPayoutAmount)).toNumber()
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    disabledDates () {
      return [
        date => this.$moment(date).startOf('day').isSameOrBefore(this.$moment(this.systemData.nextDayChange).startOf('day'))
      ]
    },
    fields () {
      return [
        {
          label: this.$t('beneficiaryName'),
          key: 'beneficiaryName'
        },
        {
          label: this.$t('paymentWay'),
          key: 'paymentWayId'
        },
        {
          label: this.$t('beneficiaryIBAN'),
          key: 'beneficiaryExternalAccountNumber'
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          class: 'money'
        },
        {
          key: 'actions'
        }
      ]
    }
  },

  validations: {
    loanData: {
      payableAfterTime: {
        required: validators.required
      }
    },
    splitDifference: {
      max (value) {
        return validators.between(0, this.totalPayoutAmount)(value)
      }
    }
  },

  methods: {
    ...mapActions('loans', ['loadPaymentRelations']),
    async resetData () {
      await this.loadPaymentRelations({ loanId: this.loan.id })
      const payout = new BigNumber(this.totalPayoutAmount)
      const { amount, amountLimit, limit } = this.schedule
      Object.assign(this.$data, this.$options.data.apply(this))
      const data = this.loanPaymentRelations.find(({ paymentWayId }) => paymentWayId === 2) || this.loanPaymentRelations[0]
      this.loanData = {
        // loanRelatedPayments: [...this.loanPaymentRelations],
        loanRelatedPayments: [data],
        payableAfterTime: this.product.outgoingPaymentDelayHours
      }
      this.$v.$reset()

      if (!this.freeInput) {
        this.loanData.loanRelatedPayments.forEach((relation, index) => this.updatePayouts({ ...relation, id: index + 1, amount: new BigNumber(relation.amount).dividedBy(amount ?? amountLimit ?? limit).multipliedBy(payout).toNumber() }))
      }
    },
    onHide (e) {
      if (this.loader) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      const { hide } = this?.$refs?.modal ?? {}
      if (this.validate()) {
        await this.callback({
          paymentData: {
            ...this.loanData,
            loanRelatedPayments: this.loanData.loanRelatedPayments.map(({ id, ...relation }) => relation)
          },
          done: () => hide?.()
        })
      }
    },
    addPayout () {
      this.currentRelation = { ...paymentRelationData, id: this.loanData.loanRelatedPayments.length + 1 }
      this.showPayoutModal = true
    },
    editPayout (index) {
      this.currentRelation = { ...this.loanData.loanRelatedPayments[index], id: index + 1 }
      this.showPayoutModal = true
    },
    updatePayouts (payout) {
      this.loanData.loanRelatedPayments = sortBy(unionWith([payout], this.loanData.loanRelatedPayments, (val, otherVal) => val.id === otherVal.id || val.beneficiaryExternalAccountNumber === otherVal.beneficiaryExternalAccountNumber), ['relationId', 'id'])
      const payments = cloneDeep(this.loanData.loanRelatedPayments)
      if (!this.freeInput) {
        payments[0].amount = new BigNumber(payments[0].amount).plus(this.splitDifference).toNumber()
        if (payments[0].amount < 0) {
          return
        }
      }


      this.loanData.loanRelatedPayments = payments
    },
    removePayout (index) {
      this.loanData.loanRelatedPayments.splice(index, 1)
    }
  }
}
</script>


<i18n>
en:
  title:            "Initiate payment"
  payoutAccount:    "Payout bank account"
  paymentWay:       "Payment way"
  payableAfterTime: "Payable after time"
  periodStartError: "Start date should be after {{ systemDate }}. Please edit schedule."
  beneficiaryName:  "Beneficiary name"
  beneficiaryIBAN:  "Beneficiary IBAN"
  amount:           "Amount"
  payoutRelations:  "Payouts"
  amountError:      "Total payout amount should be no more than {{ amount }}"
et:
  title:            "Initiate payment"
  payoutAccount:    "Payout bank account"
  paymentWay:       "Payment way"
  payableAfterTime: "Payable after time"
  periodStartError: "Start date should be after {{ systemDate }}. Please edit schedule."
  beneficiaryName:  "Beneficiary name"
  beneficiaryIBAN:  "Beneficiary IBAN"
  amount:           "Amount"
  payoutRelations:  "Payouts"
  amountError:      "Total payout amount should be no more than {{ amount }}"
ru:
  title:            "Initiate payment"
  payoutAccount:    "Payout bank account"
  paymentWay:       "Payment way"
  payableAfterTime: "Payable after time"
  periodStartError: "Start date should be after {{ systemDate }}. Please edit schedule."
  beneficiaryName:  "Beneficiary name"
  beneficiaryIBAN:  "Beneficiary IBAN"
  amount:           "Amount"
  payoutRelations:  "Payouts"
  amountError:      "Total payout amount should be no more than {{ amount }}"
</i18n>
