<template lang="pug">
.loan-schedule-parameters.card
  .card-header {{ $t('title') }}
    a.fa-pull-right.animated.fadeIn(
      v-if           = 'editable && !editing && schedule.status.id === "PENDING"'
      href           = '#'
      :title         = '$t("common:edit")'
      @click.prevent = 'toggleEditing()'
    ): i.far.fa-edit.fa-lg
  .card-body
    form.form-horizontal(
      @submit.prevent = 'onSubmit'
      novalidate
    )
      template(v-if = 'editing')
        fi-form-field(
          :label = '$t("amount")'
          :state = '!$v.scheduleData.amount.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.amount.gte') {{ $t('gte', { value: $v.scheduleData.amount.$params.gte.value }) }}
            .error-message(v-if = '!$v.scheduleData.amount.lte') {{ $t('amountGtProductErr', { value: $v.scheduleData.amount.$params.lte.value }) }}
          .form-row
            .col: fi-money-input.form-control-plaintext(
              v-model.number = 'scheduleData.amount'
              :class         = '{ "is-invalid": $v.scheduleData.amount.$error }'
              readonly
            )
            .col-auto: button.btn.btn-primary(
              @click.prevent = 'showIncreaseAmountModal = true'
              :disabled      = 'isEnded || scheduleData.amount === limitAvailable'
            ): i.far.fa-plus-square.fa-lg
        fi-form-field(
          v-if       = 'isLeasingGroup && product.principalWithVat'
          :label     = '$t("amountVAT")'
        )
          .form-row
            .col
              v-loading(loader = 'taxation:convert:save')
                template(#spinner)
                  i.fa.fa-spinner.fa-pulse
                fi-money-input.form-control.form-control-sm(
                  v-model.number  = 'amountWithVat'
                  type            = 'text'
                  disabled
                )
            .col
              fi-select(
                v-if    = 'vatTaxes && loan.status.code === ("INSERTED" || "SIGNED" || "PENDING" || "MATURED" || "DELETED")'
                v-model = 'scheduleData.vatRate'
                :options       = 'optionsFromClassifierTax'
                :disabled = 'loan.status.code !== ("INSERTED" || "SIGNED" || "PENDING" || "MATURED" || "DELETED")'
                sm
              )
              percent-input(
                v-else
                v-model        = 'scheduleData.vatRate'
                :class         = '{ "is-invalid": $v.scheduleData.upfrontAmount.$error }'
                disabled
              )
        fi-form-field(
          :label    = '$t("limit")'
          :state    = '!$v.scheduleData.amountLimit.$error'
          :disabled = 'disableAmountLimitInput'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.amountLimit.required') {{ $t('common:required') }}

          fi-money-input.form-control.form-control-sm(
            v-model.number = 'scheduleData.amountLimit'
            :class         = '{ "is-invalid": $v.scheduleData.amountLimit.$error }'
          )

        template(v-if = 'isLeasingGroup')
          fi-form-field(
            :label     = '$t("upfrontAmount")'
            :state     = '!$v.upfrontAmountGroup.$error'
          )
            template(#error)
              .form-row
                .col
                  .error-message(v-if = '!$v.scheduleData.upfrontAmountCalculationType.required') {{ $t('common:required') }}
                .col
                  .error-message(v-if = '!$v.scheduleData.upfrontAmount.required') {{ $t('common:required') }}
            .form-row
              .col
                fi-select(
                  v-model.number = 'scheduleData.upfrontAmountCalculationType'
                  :options       = 'leasingCalculationTypes'
                  :class         = '{ "is-invalid": $v.scheduleData.upfrontAmountCalculationType.$error }'
                  :disabled      = '!product.upfrontAmountCalculationTypeIdOverride'
                  required
                  sm
                )
              .col
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentageUpfrontAmount'
                  v-model.number = 'scheduleData.upfrontAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.upfrontAmount.$error }'
                  :disabled      = '!scheduleData.upfrontAmountCalculationType || !product.upfrontAmountOverride'
                )
                fi-money-input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'scheduleData.upfrontAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.upfrontAmount.$error }'
                  :disabled      = '!scheduleData.upfrontAmountCalculationType || !product.upfrontAmountOverride'
                )
              .col
                .form-control-plaintext(v-if = 'percentageUpfrontAmount') {{ upfrontAmountInform | money }}
                .form-control-plaintext(v-else) {{ upfrontAmountInform | percent }}

          fi-form-field(
            :label     = '$t("residualAmount")'
            :state     = '!$v.residualAmountGroup.$error'
          )
            template(#error)
              .form-row
                .col
                  .error-message(v-if = '!$v.scheduleData.residualAmountCalculationType.required') {{ $t('common:required') }}
                .col
                  .error-message(v-if = '!$v.scheduleData.residualAmount.required') {{ $t('common:required') }}
            .form-row
              .col
                fi-select(
                  v-model.number = 'scheduleData.residualAmountCalculationType'
                  :options       = 'leasingCalculationTypes'
                  :class         = '{ "is-invalid": $v.scheduleData.residualAmountCalculationType.$error }'
                  :disabled      = '!product.residualAmountCalculationTypeIdOverride'
                  required
                  sm
                )
              .col
                percent-input.form-control.form-control-sm(
                  v-if           = 'percentageResidualAmount'
                  v-model.number = 'scheduleData.residualAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.residualAmount.$error }'
                  :disabled      = '!scheduleData.residualAmountCalculationType || !product.residualAmountOverride'
                )
                fi-money-input.form-control.form-control-sm(
                  v-else
                  v-model.number = 'scheduleData.residualAmount'
                  :class         = '{ "is-invalid": $v.scheduleData.residualAmount.$error }'
                  :disabled      = '!scheduleData.residualAmountCalculationType || !product.residualAmountOverride'
                )
              .col
                .form-control-plaintext(v-if = 'percentageResidualAmount') {{ residualAmountInform | money }}
                .form-control-plaintext(v-else) {{ residualAmountInform | percent }}

        fi-form-field(
          :label    = '$t("scheduleType")'
          :disabled = 'isEnded || !product.scheduleTypeIdOverride')
          fi-select(
            v-model  = 'scheduleData.scheduleType'
            :options = 'optionsFromClassifier("LoanScheduleType")'
            sm
          )

        fi-form-field(
          :label    = '$t("dateStartEnd")'
          :state    = '!$v.periodGroup.$error'
          :disabled = 'isEnded'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.periodEnd.gte') {{ $t('periodEndErr') }}
            .error-message(v-if = '!$v.scheduleData.periodStart.gte') {{ $t('gteToday') }}

          .form-row
            .col: fi-datepicker(
              v-model        = 'scheduleData.periodStart'
              :class         = '{ "is-invalid": $v.scheduleData.periodStart.$error }'
              :min-date = 'isAnnuityOrBalloon ? null : systemData.nextDayChange'
              :enabled-dates = 'annuityBalloonEnabledDates'
              sm
            )
            .col: fi-datepicker(
              v-model         = 'scheduleData.periodEnd'
              :min-date       = 'periodMinDate'
              :max-date       = 'periodMaxDate'
              :class          = '{ "is-invalid": $v.scheduleData.periodEnd.$error }'
              sm
            )

        fi-form-field(
          :label    = '$t("interestRate")'
          :state    = '!$v.scheduleData.interestRate.$error'
          :disabled = 'isEnded'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.interestRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.scheduleData.interestRate.gte') {{ $t('gte', { value: product.interestRateMin }) }}
            .error-message(v-if = '!$v.scheduleData.interestRate.lte') {{ $t('lte', { value: product.interestRateMax }) }}
          .form-row
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'scheduleData.interestRate'
              type           = 'text'
              :class         = '{ "is-invalid": $v.scheduleData.interestRate.$error }'
              :disabled      = 'product.interestRateMin === product.interestRateMax'
            )
            .col: fi-select(
              v-model   = 'scheduleData.dayCountConvention'
              :options  = 'optionsFromClassifier("DayCountConvention")'
              :disabled = 'true'
              sm
            )

        fi-form-field(
          :label    = '$t("interestBaseRate")'
          :state    = '!$v.scheduleData.interestRate.$error'
          :disabled = 'isEnded'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.interestRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.scheduleData.interestRate.gte') {{ $t('gte', { value: product.interestRateMin }) }}
            .error-message(v-if = '!$v.scheduleData.interestRate.lte') {{ $t('lte', { value: product.interestRateMax }) }}
          .form-row
            //- .col: percent-input.form-control.form-control-sm(
            //-   v-model.number = 'scheduleData.interestRate'
            //-   type           = 'text'
            //-   :class         = '{ "is-invalid": $v.scheduleData.interestRate.$error }'
            //-   :disabled      = 'product.interestRateMin === product.interestRateMax'
            //- )
            .col: percent-input.form-control.form-control-sm(
              v-model.number = 'scheduleData.variableInterestRate'
              type           = 'text'
              :decimalLimit  = 3
              :disabled      = 'interestRateName === "FIXED"'
              )
            .col: fi-select(
              v-model   = 'variableInterestIdGetter'
              :options  = 'interestRatesDataValues'
              :disabled = '!interestRatesData.length'
              :required = 'true'
              sm
            )
            //- .col: percent-input.form-control.form-control-sm(
            //-   v-model.number = 'scheduleData.variableInterestRate'
            //-   type           = 'text'
            //-   :decimalLimit  = 3
            //-   :disabled      = 'interestRateName === "FIXED"'
            //- )
            .col: div.cell-center {{ 'Σ ' }} {{ (scheduleData.interestRate + scheduleData.variableInterestRate) || scheduleData.interestRate | percent }}

        template(v-if = '!bulletScheduleType')
          fi-form-field(
            :label    = '$t("firstPrincipalDay")'
            :state    = '!$v.scheduleData.principalFirst.$error'
            :disabled = 'isEnded || isPrincipalTenor'
          )
            template(#error)
              .error-message(v-if = '!$v.scheduleData.principalFirst.between') {{ $t('principalFirstErr', { begin: $v.scheduleData.principalFirst.$params.between.min, end: $v.scheduleData.principalFirst.$params.between.max }) }}
              .error-message(v-if = '!$v.scheduleData.principalFirst.required') {{ $t('common:required') }}
            fi-datepicker(
              v-model         = 'scheduleData.principalFirst'
              :class          = '{ "is-invalid": $v.scheduleData.principalFirst.$error }'
              :min-date       = 'scheduleData.periodStart'
              :max-date       = 'scheduleData.periodEnd'
              sm
            )

          fi-form-field(
            :label    = '$t("principalFreq")'
            :disabled = 'isEnded || !product.principalPaymentPeriodIdOverride'
          )
            fi-select(
              v-model  = 'scheduleData.principalPaymentPeriod'
              :options = 'principalPaymentPeriods'
              required
              sm
            )

        fi-form-field(
          :label    = '$t("firstInterestDay")'
          :state    = '!$v.scheduleData.interestFirst.$error'
          :disabled = 'isEnded || isInterestTenor'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.interestFirst.between') {{ $t('interestFirstErr', { begin: $v.scheduleData.interestFirst.$params.between.min, end: $v.scheduleData.interestFirst.$params.between.max }) }}
          fi-datepicker(
            v-model         = 'scheduleData.interestFirst'
            :class          = '{ "is-invalid": $v.scheduleData.interestFirst.$error }'
            :enabled-dates  = 'interestPeriodEnabledDates'
            sm
          )

        fi-form-field(
          :label    = '$t("interestFreq")'
          :disabled = 'isEnded || !product.interestPaymentPeriodIdOverride'
        )
          fi-select(
            v-model  = 'scheduleData.interestPaymentPeriod'
            :options = 'optionsFromClassifier("Frequency")'
            required
            sm
          )

        fi-form-field(
          v-if   = 'isCapitalCompanyLeasing && schedule.principalVatAmountPeriod'
          :label = '$t("principalVatPeriod")'
        ) {{ principalVatPeriod.human }}
        fi-form-field(
          :label = '$t("penaltyRate")'
          :state = '!$v.scheduleData.penaltyInterestRate.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.scheduleData.penaltyInterestRate.required') {{ $t('common:required') }}
            .error-message(v-if = '!$v.scheduleData.penaltyInterestRate.gte') {{ $t('gte', { value: product.penaltyInterestRateMin }) }}
            .error-message(v-if = '!$v.scheduleData.penaltyInterestRate.lte') {{ $t('lte', { value: product.penaltyInterestRateMax }) }}
          percent-input.form-control.form-control-sm(
            v-model.number = 'scheduleData.penaltyInterestRate'
            type           = 'text'
            :class         = '{ "is-invalid": $v.scheduleData.penaltyInterestRate.$error }'
            :disabled      = 'product.penaltyInterestRateMin === product.penaltyInterestRateMax'
          )

        template(v-if = 'isDelayed')
          fi-form-field(
            :label    = '$t("tempPenaltyRate")'
            :state    = '!$v.penaltyRateChangeGroup.$error'
          )
            template(#error)
              .form-row
                .col
                  .error-message(v-if = '!$v.scheduleData.penaltyRateChange.penaltyRate.required') {{ $t('common:required') }}
                .col
                  .error-message(v-if = '!$v.scheduleData.penaltyRateChange.startDate.required') {{ $t('common:required') }}
                .col
                  .error-message(v-if = '!$v.scheduleData.penaltyRateChange.endDate.required') {{ $t('common:required') }}
            .form-row
              .col-3
                percent-input.form-control.form-control-sm(
                  v-model.number = 'scheduleData.penaltyRateChange.penaltyRate'
                  type           = 'text'
                  :class         = '{ "is-invalid": $v.scheduleData.penaltyRateChange.penaltyRate.$error }'
                )
              .col: fi-datepicker(
                v-model   = 'scheduleData.penaltyRateChange.startDate'
                :min-date = 'systemData.nextDayChange'
                :class    = '{ "is-invalid": $v.scheduleData.penaltyRateChange.startDate.$error }'
                sm
              )
              .col: fi-datepicker(
                v-model = 'scheduleData.penaltyRateChange.endDate'
                :min-date = 'scheduleData.penaltyRateChange.startDate'
                :class  = '{ "is-invalid": $v.scheduleData.penaltyRateChange.endDate.$error }'
                sm
              )

        template(v-if = 'balloonScheduleType')
          fi-form-field(
            :label    = '$t("amortizationPeriod")'
            :state    = '!$v.scheduleData.amortizationPeriod.$error'
            :disabled = 'isEnded'
          )
            template(#error)
              .error-message(v-if = '!$v.scheduleData.amortizationPeriod.required') {{ $t('common:required') }}
            input.form-control.form-control-sm(
              v-model.number = 'scheduleData.amortizationPeriod'
              :class         = '{ "is-invalid": $v.scheduleData.amortizationPeriod.$error }'
            )

        template(v-if   = 'feeEnabled')
          fi-form-field(
            :label = '$t("feeTypeName")'
          )
            .input-group.input-group-sm
              fi-select(
                v-model.number = 'selectedFee'
                :options       = 'optionsFromFees'
                sm
              )
              .input-group-append
                button.btn.btn-primary(
                  :disabled      = '!selectedFee'
                  @click.prevent = 'addFee'
                ) {{ $t('common:add') }}
        fi-fee-item(
          v-for         = 'fee in availableFees'
          :key          = 'fee.feeType.code.$model'
          :fee          = 'fee'
          :amount       = '$v.scheduleData.amount.$model'
          :fee-configs   = 'feeConfigs'
          :min-date     = 'minDate(fee)'
          editing
        )
          template(#edit-append = '{ isCustom }' )
            .col-auto(v-if           = 'isCustom')
              button.btn.btn-sm.btn-link.text-danger(
                @click.prevent = 'removeFee(fee.feeType.code.$model)'
              )
                i.far.fa-trash-alt.fa-lg

        //- fi-form-field(
        //-   :label     = '$t("guaranteedPercentageFromAmount")'
        //- )
        //-   percent-input.form-control.form-control-sm(
        //-     v-model = 'scheduleData.guaranteedPercentageFromAmount'
        //-   )
        .form-text.alert.alert-danger.animated.fadeIn(v-if = 'error') {{ error }}

        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing()'
        ) {{ $t('common:cancel') }}

      template(v-else)
        fi-data-field(
          :label     = '$t("amount")'
          label-cols = '5'
        )
          div(:class = 'markChanged("amount")') {{ schedule.amount | money }}
        fi-data-field(
          v-if       = 'isLeasingGroup && product.principalWithVat'
          :label     = '$t("amountVAT")'
          label-cols = '5'
        )
          .form-row
            .col {{ schedule.amountWithVat | money }}
            .col
            .col {{ schedule.vatRate | percent }}
        fi-data-field(
          :label     = '$t("limit")'
          label-cols = '5'
        )
          div(:class = 'markChanged("amountLimit")') {{ schedule.amountLimit | money }}
        template(v-if = 'isLeasingGroup')
          fi-data-field(
            :label     = '$t("upfrontAmount")'
            label-cols = '5'
          )
            .form-row
              template(v-if = 'percentageUpfrontAmount')
                .col {{ schedule.upfrontAmount | percent }}
                .col
                .col {{ upfrontAmountInform | money }}
              template(v-else)
                .col {{ upfrontAmountInform | percent }}
                .col
                .col {{ schedule.upfrontAmount | money }}

          fi-data-field(
            :label     = '$t("residualAmount")'
            label-cols = '5'
          )
            .form-row
              template(v-if = 'percentageResidualAmount')
                .col {{ schedule.residualAmount | percent }}
                .col
                .col {{ residualAmountInform | money }}
              template(v-else)
                .col {{ residualAmountInform | percent }}
                .col
                .col {{ schedule.residualAmount | money }}
        fi-data-field(
          :label     = '$t("scheduleType")'
          label-cols = '5'
        )
          div(:class = 'markChanged("scheduleType.id")') {{ scheduleType.human }}
        fi-data-field(
          :label     = '$t("dateStartEnd")'
          label-cols = '5'
        )
          .form-row
            .col(:class = 'markChanged("periodStart")') {{ $t('common:formatDate', { value: schedule.periodStart }) }}
            .col
            .col(:class = 'markChanged("periodEnd")') {{ $t('common:formatDate', { value: schedule.periodEnd }) }}
        fi-data-field(
          :label     = '$t("interestRate")'
          label-cols = '5'
        )
          .form-row
            .col(:class = 'markChanged("interestRate")') {{ schedule.interestRate | percent }}
            .col
            .col {{ dayCountConvention ? dayCountConvention.human : '' }}
        fi-data-field(
          :label     = '$t("interestBaseRate")'
          label-cols = '5'
        )
          .form-row
            //- .col(:class = 'markChanged("interestRate")') {{ schedule.interestRate | percent }}
            .col(:class = 'markChanged("variableInterestRate")') {{ schedule.variableInterestRate || 0 | percent('0.000 %') }}
            .col(:class = 'markChanged("variableInterestDto.id")') {{ variableInterestName }}
            //- .col(:class = 'markChanged("variableInterestRate")') {{ schedule.variableInterestRate || 0 | percent('0.000 %') }}
            .col(:class = 'markChanged("variableInterestRate")') {{ 'Σ ' }} {{ (schedule.interestRate + schedule.variableInterestRate) || schedule.interestRate | percent('0.000 %') }}

        template(v-if = '!bulletScheduleType')
          fi-data-field(
            :label     = '$t("firstPrincipalDay")'
            label-cols = '5'
          )
            div(:class = 'markChanged("principalFirst")') {{ $t('common:formatDate', { value: schedule.principalFirst }) }}
          fi-data-field(
            :label     = '$t("principalFreq")'
            label-cols = '5'
          )
            div(:class = 'markChanged("principalPaymentPeriod.id")') {{ principalPaymentPeriod.human }}
        fi-data-field(
          :label     = '$t("firstInterestDay")'
          label-cols = '5'
        )
          div(:class = 'markChanged("interestFirst")') {{ $t('common:formatDate', { value: schedule.interestFirst }) }}
        fi-data-field(
          :label     = '$t("interestFreq")'
          label-cols = '5'
        )
          div(:class = 'markChanged("interestPaymentPeriod.id")') {{ interestPaymentPeriod.human }}
        template(v-if   = 'isCapitalCompanyLeasing && schedule.principalVatAmountPeriod')
          fi-data-field(
            :label     = '$t("principalVatPeriod")'
            label-cols = '5'
          ) {{ principalVatPeriod.human }}
        fi-data-field(
          :label     = '$t("penaltyRate")'
          label-cols = '5'
        )
          div(:class = 'markChanged("penaltyInterestRate")') {{ schedule.penaltyInterestRate | percent }}
        template(v-if = 'balloonScheduleType')
          fi-form-field(
            :label     = '$t("amortizationPeriod")'
            label-cols = '5'
          ) {{ schedule.amortizationPeriod }}
        template(v-if = 'isDelayed && penaltyRateChange.penaltyRate')
          fi-data-field(
            :label     = '$t("tempPenaltyRate")'
            label-cols = '5'
          )
            .form-row
              .col-3(:class = 'markChanged("penaltyRateChange.penaltyRate")') {{ penaltyRateChange.penaltyRate | percent }}
              .col(:class = 'markChanged("penaltyRateChange.startDate")') {{ $t('common:formatDate', { value: penaltyRateChange.startDate }) }}
              .col(:class = 'markChanged("penaltyRateChange.endDate")') {{ $t('common:formatDate', { value: penaltyRateChange.endDate }) }}
        fi-fee-item(
          v-for               = '(fee, index) in availableFees'
          :key                = 'index'
          :fee                = 'fee'
          :amount             = 'schedule.amount'
          :fee-configs        = 'feeConfigs'
          label-cols          = '5'
          :activeSchedule     = 'loanActiveSchedule[0]'
        )
        //- fi-form-field(
        //-   :label     = '$t("guaranteedPercentageFromAmount")'
        //- )
        //-   div {{ schedule.guaranteedPercentageFromAmount | percent }}
  loan-schedule-parameters-amount-increase-modal(
    v-model        = 'showIncreaseAmountModal'
    :schedule-data = 'scheduleData'
    :product       = 'product'
    :limit-available = 'limitAvailable'
    @submit        = 'applyAmountIncrease'
  )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import PercentInput from '@/components/PercentInput'
import FiMoneyInput from '@/components/FiMoneyInput'
import LoanScheduleParametersAmountIncreaseModal
  from '@/views/loans/LoanSchedule/LoanScheduleParametersAmountIncreaseModal'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import { rules, validation, validators } from '@/validation'
import {
  excludeLastMonthDays,
  feeDisabledMixin,
  feeEditableMixin,
  formatRangeError,
  isCapitalLeasing,
  isLoanLeasing
} from '@/mixins'
import { calculationTypes, convertFee, convertFeeRange, feeDisabled, feePercentage } from '@/helpers'
import { loanStatuses } from '@/const'
import FiFeeItem from '@/components/FiFeeItem'
import debounce from 'lodash/debounce'

export default {
  name: 'loan-schedule-parameters',

  components: {
    FiFeeItem,
    LoanScheduleParametersAmountIncreaseModal,
    FiSelect,
    FiDataField,
    FiFormField,
    FiDatepicker,
    PercentInput,
    FiMoneyInput
  },

  mixins: [
    validation,
    excludeLastMonthDays,
    feeDisabledMixin,
    feeEditableMixin,
    isLoanLeasing,
    isCapitalLeasing,
    formatRangeError
  ],

  props: {
    schedule: {
      type: Object,
      required: true
    },
    loan: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    flexible: {
      type: Boolean
    },
    customer: {
      type: Object,
      default: () => ({})
    },
    feeConfigs: {
      type: Object,
      default: () => ({})
    }
  },

  i18nOptions: {},

  data () {
    return {
      amountWithVat: null,
      vatTaxes: null,
      editing: false,
      error: null,
      showIncreaseAmountModal: false,
      selectedFee: '',
      interestRateName: '',
      scheduleData: {
        amount: null,
        amountLimit: null,
        amortizationPeriod: null,
        earlyPaybackAmount: null,
        increaseLoanAmount: null,
        interestFirst: '',
        interestPaymentPeriod: '',
        interestRate: null,
        penaltyInterestRate: null,
        periodStart: '',
        periodEnd: '',
        principalPaymentPeriod: '',
        principalFirst: '',
        scheduleType: '',
        upfrontAmount: null,
        upfrontAmountCalculationType: null,
        residualAmount: null,
        residualAmountCalculationType: null,
        vatRate: null,
        penaltyRateChange: {
          endDate: '',
          penaltyRate: null,
          startDate: ''
        },
        fees: [],
        variableInterestId: this.schedule.variableInterestDto?.id || null,
        variableInterestRate: null,
        variableInterestFrom: null,
        variableInterestUntil: null
      },
      interestRatesData: []
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'optionsFromClassifier', 'classifierByName', 'classifierList']),
    ...mapGetters('loans', ['scheduleById', 'activeSchedule', 'loanProductFees']),
    ...mapGetters('settings', ['settingByKey', 'isLimitEnabled']),
    ...mapState('loans', ['systemData', 'loanActiveSchedule']),
    ...mapState('interests', ['interestRates', 'variableInterests']),
    dayCountConvention () {
      return this.classifierByName(this.schedule.dayCountConvention.classifier, this.schedule.dayCountConvention.code) ?? {}
    },
    interestPeriodMinDate () {
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod)?.name
      let period = 1
      switch (interestPeriod) {
        case 'QUARTERLY':
          period = 3
          break
        case 'SEMIANNUAL':
          period = 6
          break
        case 'ANNUAL':
          period = 12
          break
      }
      const minDate = this.$moment(this.scheduleData.principalFirst)
      while (this.$moment(minDate).subtract(period, 'months').isSameOrAfter(this.scheduleData.principalFirst)) {
        minDate.subtract(period, 'months')
      }
      return minDate
    },
    interestPeriodEnabledDates () {
      if (this.isEnded || this.isPrincipalTenor) {
        return {
          start: new Date(this.scheduleData.periodStart),
          end: new Date(this.scheduleData.periodEnd)
        }
      }
      const interestPeriod = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod) ?? {}
      const interestRange = this.$moment.range(this.interestPeriodMinDate, this.scheduleData.periodEnd).snapTo('day')
      let range = []
      switch (interestPeriod.name) {
        case 'MONTHLY':
          range = interestRange.by('months')
          break
        case 'QUARTERLY':
          range = interestRange.by('quarters')
          break
        case 'SEMIANNUAL':
          range = interestRange.by('months', { step: 6 })
          break
        case 'ANNUAL':
          range = interestRange.by('years')
          break
      }
      return [this.$moment(this.scheduleData.periodStart), this.$moment(this.scheduleData.periodEnd), ...Array.from(range)].map(date => date.toDate())
    },
    isAnnuityOrBalloon () {
      return ['BALLOON', 'ANNUITY'].includes(this.scheduleData.scheduleType)
    },
    optionsFromFees () {
      return this.product.fees.filter(({ feeType }) => {
        const feeTypeLocal = Object.keys(this.feeConfigs).includes(feeType?.code ?? feeType) ? feeType?.code ?? feeType : 'CUSTOM_FEE'
        return feeTypeLocal === 'CUSTOM_FEE' && !this.scheduleData.fees.some(fee => fee.feeType.code === feeType.code)
      }).map(
        ({ id, feeType }) => (
          {
            value: feeType.code,
            text: this.classifierByName(feeType.classifier, feeType.code)?.human
          }))
    },
    balloonScheduleType () {
      return this.editing ? this.scheduleData.scheduleType === 'BALLOON' : this.schedule.scheduleType.id === 'BALLOON'
    },
    bulletScheduleType () {
      return this.editing ? this.scheduleData.scheduleType === 'BULLET' : this.schedule.scheduleType.id === 'BULLET'
    },
    annuityBalloonEnabledDates () {
      return (this.isAnnuityOrBalloon && !this.isEnded && [...this.pendingInvoicesDates, this.$moment().format('YYYY-MM-DD')].map(date => Date.parse(date))) || []
    },
    pendingInvoicesDates () {
      return this.schedule.loanInvoices.filter(invoice => invoice.status.id === 'PENDING').map(({ paymentDate }) => paymentDate) || []
    },
    scheduleType () {
      return this.classifierByName(this.schedule.scheduleType.classifier, this.schedule.scheduleType.code) ?? {}
    },
    principalPaymentPeriod () {
      return this.schedule.principalPaymentPeriod && (this.classifierByName(this.schedule.principalPaymentPeriod.classifier, this.schedule.principalPaymentPeriod.code) ?? {})
    },
    interestPaymentPeriod () {
      return this.classifierByName(this.schedule.interestPaymentPeriod.classifier, this.schedule.interestPaymentPeriod.code) ?? {}
    },
    saving () {
      return this.$vueLoading.isLoading('loan:schedule:save')
    },
    principalPaymentPeriods () {
      const interestPeriodId = this.classifierByName('paymentPeriodTypes', this.scheduleData.interestPaymentPeriod)?.id
      const availablePeriodsNames = this.optionsFromClassifier('paymentPeriodTypes')
        .filter(period => period.value >= interestPeriodId)
        .map(period => period.text)
      return this.optionsFromClassifier('Frequency').filter(period => availablePeriodsNames.includes(period.text) || !period.value)
    },
    upfrontAmountInform () {
      return convertFee({
        feeValue: this.scheduleData.upfrontAmount || this.schedule.upfrontAmount,
        isPercentage: !this.percentageUpfrontAmount,
        amountValue: this.scheduleData.amount || this.schedule.amount
      })
    },
    residualAmountInform () {
      return convertFee({
        feeValue: this.scheduleData.residualAmount || this.schedule.residualAmount,
        isPercentage: !this.percentageResidualAmount,
        amountValue: this.scheduleData.amount || this.schedule.amount
      })
    },
    percentageUpfrontAmount () {
      return feePercentage(this.editing ? this.scheduleData?.upfrontAmountCalculationType : this.schedule.upfrontAmountCalculationType?.code)
    },
    percentageResidualAmount () {
      return feePercentage(this.editing ? this.scheduleData?.residualAmountCalculationType : this.schedule.residualAmountCalculationType?.code)
    },
    leasingCalculationTypes () {
      return calculationTypes({ feeType: { code: 'LEASING' } }, this.feeConfigs, this.classifierList('feeCalculationType'))
    },
    disableAmountLimitInput () {
      return this.isEnded || (this.product.amountMin === this.product.amountMax) || (this.scheduleData.amountLimit === this.product.amountMax)
    },
    isInterestTenor () {
      return /TENOR/i.test(this.scheduleData.interestPaymentPeriod)
    },
    isPrincipalTenor () {
      return /TENOR/i.test(this.scheduleData.principalPaymentPeriod)
    },
    isEnded () {
      return this.scheduleData.periodEnd < this.systemData.lastDayChange
    },
    feePaymentMinDate () {
      return this.isEnded ? this.systemData.nextDayChange : this.scheduleData.periodStart
    },
    feePaymentMaxDate () {
      return this.isEnded ? null : this.scheduleData.periodEnd
    },
    periodStartMinDate () {
      return this.isEnded ? null : this.systemData.nextDayChange
    },
    feeEnabled () {
      return Boolean(this.product.fees?.length)
    },
    periodUnit () {
      return this.classifierById('periodUnitType', this.product.periodUnitTypeId)
    },
    periodMinDate () {
      return this.$moment(this.loan.periodStart).add(this.product.periodMin, this.periodUnit.name).toDate()
    },
    periodMaxDate () {
      return this.$moment(this.loan.periodStart).add(this.product.periodMax, this.periodUnit.name).toDate()
    },
    limitAvailable () {
      return Math.min(...[this.product.amountMax, ...(this.isLimitEnabled ? [this.loanCustomer?.creditLimit] : [])])
    },
    penaltyRateChange () {
      return this.schedule.penaltyRateChange ?? {}
    },
    isDelayed () {
      const { delayed, collector, matured } = loanStatuses
      return [delayed, collector, matured].includes(this.loan.status.code)
    },
    principalVatPeriod () {
      return this.classifierById(this.schedule.principalVatAmountPeriod?.classifier, this.schedule.principalVatAmountPeriod?.id)
    },
    availableFees () {
      const excludedFees = ['TERMINATION', 'AUTO_EXTENSION']
      return (this.editing ? Object.values(this.$v.scheduleData.fees.$each.$iter) : this.schedule.fees)
        .filter(
          ({ feeType }) => !excludedFees.includes(feeType.code.$model ?? feeType.code)
        )
    },
    optionsFromClassifierTax () {
      const options = []
      this.vatTaxes.forEach((current) => {
        options.push({ text: `${(current.rate) * 100} %`, value: current.rate })
      })
      return options
    },
    interestRatesDataValues () {
      if (this.interestRatesData.length) {
        return this.interestRatesData.map((rate) => rate.name)
      } else {
        return ['FIXED']
      }
    },
    variableInterestIdGetter: {
      get () {
        return this.interestRateName
      },
      set (value) {
        this.scheduleData.variableInterestId = this.interestRatesData?.find((rate) => rate.name === value)?.id || null
        this.scheduleData.variableInterestRate = this.interestRates?.find((rate) => rate.variableInterest.id === this.scheduleData.variableInterestId)?.rate
        this.interestRateName = this.interestRates?.find((rate) => rate.name === value)?.name || value
      }
    },
    variableInterestName () {
      return this.interestRates.find((rate) => rate.variableInterest.id === this.schedule.variableInterestDto?.id)?.variableInterest.name || 'FIXED'
    }
  },

  watch: {
    'schedule' () {
      this.toggleEditing(false)
    },
    'scheduleData.scheduleType' () {
      if (this.isEnded) {
        return
      }
      if (this.bulletScheduleType) {
        this.scheduleData.principalPaymentPeriod = 'TENOR'
      } else {
        this.scheduleData.interestFirst = this.findNearestPaymentDate()
        this.scheduleData.principalFirst = this.findNearestPaymentDate()
      }
    },
    'scheduleData.periodStart' (val, oldVal) {
      if (!oldVal && this.isEnded) {
        return
      }
      if (!this.isPrincipalTenor) {
        this.scheduleData.principalFirst = this.findNearestPaymentDate(val)
      }
    },
    'scheduleData.amount' () {
      if (this.product.principalWithVat) {
        this.onAmountChange()
      }
    },
    'scheduleData.interestPaymentPeriod' (val) {
      const interestPeriodId = this.classifierByName('paymentPeriodTypes', val)?.id
      const principalPeriodId = this.classifierByName('paymentPeriodTypes', this.scheduleData.principalPaymentPeriod)?.id
      if (principalPeriodId < interestPeriodId) {
        this.scheduleData.principalPaymentPeriod = val
      }
      if (this.isInterestTenor) {
        this.scheduleData.interestFirst = this.scheduleData.periodEnd
      }
    },
    'scheduleData.principalPaymentPeriod' () {
      if (this.isPrincipalTenor) {
        this.scheduleData.principalFirst = this.scheduleData.periodEnd
      }
    },
    'scheduleData.periodEnd' (val) {
      if (this.isPrincipalTenor) {
        this.scheduleData.principalFirst = val
      }
      if (this.isInterestTenor) {
        this.scheduleData.interestFirst = val
      }
    },
    percentageUpfrontAmount (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }
      this.scheduleData.upfrontAmount = convertFee({
        feeValue: this.scheduleData.upfrontAmount,
        isPercentage: value,
        amountValue: this.scheduleData.amount
      })
    },
    percentageResidualAmount (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }
      this.scheduleData.residualAmount = convertFee({
        feeValue: this.scheduleData.residualAmount,
        isPercentage: value,
        amountValue: this.scheduleData.amount
      })
    }
  },

  validations () {
    return {
      scheduleData: merge({}, pick(rules.loanRules, Object.keys(this.scheduleData)), {
        amortizationPeriod: {
          required: validators.requiredIf(function () {
            return this.balloonScheduleType
          })
        },
        amount: {
          gte: validators.gte(this.activeSchedule?.amount),
          lte: validators.lte(this.limitAvailable)
        },
        periodEnd: {
          gte: value => this.$moment(value).isSameOrAfter(this.scheduleData.periodStart, 'day')
        },
        principalFirst: {
          between: (value) => this.isEnded || validators.datesBetween(this.scheduleData.periodStart, this.scheduleData.periodEnd)(value),
          required: validators.requiredUnless(function () {
            return this.bulletScheduleType || this.isEnded
          })
        },
        interestFirst: {
          between: (value) => this.isEnded || validators.datesBetween(this.scheduleData.periodStart, this.scheduleData.periodEnd)(value),
          required: validators.requiredUnless(function () {
            return this.isEnded
          })
        },
        principalPaymentPeriod: {
          required: validators.requiredUnless(function () {
            return this.bulletScheduleType
          })
        },
        interestRate: {
          gte: validators.gte(this.product.interestRateMin),
          lte: validators.lte(this.product.interestRateMax)
        },
        penaltyInterestRate: {
          gte: validators.gte(this.product.penaltyInterestRateMin),
          lte: validators.lte(this.product.penaltyInterestRateMax)
        },
        residualAmount: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        residualAmountCalculationType: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        upfrontAmount: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        upfrontAmountCalculationType: {
          required: validators.requiredIf(function () {
            return this.isLeasingGroup
          })
        },
        penaltyRateChange: {
          endDate: {},
          penaltyRate: {},
          startDate: {}
        },
        fees: {
          $each: {
            date: {},
            feeType: {
              classifier: {},
              code: {}
            },
            feeCalculationType: {
              classifier: {},
              code: {}
            },
            feeAmount: {
              required: validators.requiredUnless(function (fee) {
                return feeDisabled(fee) && !this.availableFees.some(({ feeType }) => fee.feeType.code === feeType.code)
              }),
              min: validators.minValueRef(function (fee) {
                return (this.availableFees.some(({ feeType }) => fee.feeType.code === feeType.code) && convertFeeRange(fee, this.scheduleData.amount).feeMin) || -Infinity
              }),
              max: validators.maxValueRef(function (fee) {
                return (this.availableFees.some(({ feeType }) => fee.feeType.code === feeType.code) && convertFeeRange(fee, this.scheduleData.amount).feeMax) || Infinity
              })
            }
          }
        }
      }),
      periodGroup: ['scheduleData.periodStart', 'scheduleData.periodEnd'],
      penaltyPeriodGroup: ['scheduleData.penaltyRateChange.startDate', 'scheduleData.penaltyRateChange.endDate'],
      upfrontAmountGroup: ['scheduleData.upfrontAmount', 'scheduleData.upfrontAmountCalculationType'],
      residualAmountGroup: ['scheduleData.residualAmount', 'scheduleData.residualAmountCalculationType'],
      penaltyRateChangeGroup: ['scheduleData.penaltyRateChange.startDate', 'scheduleData.penaltyRateChange.endDate', 'scheduleData.penaltyRateChange.penaltyRate']
    }
  },

  async created () {
    await this.loadVariableInterestsRate()
    await this.loadVariableInterests()
    this.interestRatesData = this.variableInterests.filter((rate) =>
      this.product.productInterestDefinitionDtos.find((interestDefinition) =>
        interestDefinition.variableInterestId === rate.id
      )
    )
    if (!this.product.productInterestDefinitionDtos.length) {
      this.interestRatesData = this.variableInterests.filter((interest) => interest.name === 'FIXED')
      this.interestRateName = 'FIXED'
      this.scheduleData.variableInterestId = this.interestRatesData[0].id || 0
    } else {
      this.interestRateName = this.variableInterestName
    }
    this.vatTaxes = await this.getTaxRateVat({ countryId: this.product.creditIssuerId })
    this.resetData()
    // this.vatTaxes = await this.getTaxRateVat({ countryId: this.product.creditIssuerId })
  },

  methods: {
    ...mapActions('loans', ['updateLoanSchedule']),
    ...mapActions('taxation', ['convertAmount', 'getTaxRateVat']),
    ...mapActions('interests', ['loadVariableInterestsRate', 'loadVariableInterests']),
    onAmountChange: debounce(
      async function () {
        this.amountWithVat = await this.convertAmount({
          amountData: {
            amount: this.scheduleData.amount,
            amountWithVat: false
          }
        })
      }, 500
    ),
    findNearestPaymentDate (start = this.$moment().format('YYYY-MM-DD')) {
      return this.schedule?.loanInvoices?.find(({ paymentDate }) => paymentDate > start)?.paymentDate
    },
    toggleEditing (state = !this.editing) {
      this.editing = state
      this.resetData()
      this.$emit('update:editable', this.editing)
    },
    addFee () {
      this.scheduleData.fees.push({
        ...cloneDeep(this.loanProductFees[this.selectedFee]),
        date: new Date()
      })
      this.selectedFee = ''
    },
    removeFee (type) {
      this.scheduleData.fees = this.scheduleData.fees.filter(({ feeType }) => feeType.code !== type)
    },
    resetData () {
      if (this.$v) this.$v.$reset()
      this.error = null
      this.scheduleData = merge(
        {},
        this.$options.data.call(this).scheduleData,
        pick(this.schedule, Object.keys(this.scheduleData))
      )
      this.$set(this.scheduleData, 'scheduleType', this.schedule?.scheduleType?.id)
      this.$set(this.scheduleData, 'dayCountConvention', this.schedule?.dayCountConvention?.id)
      this.$set(this.scheduleData, 'principalPaymentPeriod', this.schedule?.principalPaymentPeriod?.id)
      this.$set(this.scheduleData, 'interestPaymentPeriod', this.schedule?.interestPaymentPeriod?.id)
      if (this.isLeasingGroup) {
        this.$set(this.scheduleData, 'upfrontAmountCalculationType', this.schedule?.upfrontAmountCalculationType?.id)
        this.$set(this.scheduleData, 'residualAmountCalculationType', this.schedule?.residualAmountCalculationType?.id)
      }
      if (this.loan.status.code === ('INSERTED' || 'SIGNED' || 'PENDING' || 'MATURED' || 'DELETED')) {
        if ((!this.schedule.vatRate && this.vatTaxes) || (this.vatTaxes && this.schedule.vatRate && !this.vatTaxes?.find(i => i.rate === this.schedule.vatRate))) {
          this.scheduleData.vatRate = (this.vatTaxes?.filter(i => i.isDefault === true))[0].rate
        }
      }
    },
    async onSubmit () {
      if (this.validate()) {
        try {
          this.error = null
          if (!this.scheduleData.variableInterestId) {
            const ratesData = this.variableInterests.filter((interest) => interest.name === 'FIXED')
            this.scheduleData.variableInterestId = ratesData[0].id || 0
          }
          if (this.interestRateName === 'FIXED') {
            this.scheduleData.variableInterestFrom = this.scheduleData.periodStart
            this.scheduleData.variableInterestUntil = this.scheduleData.periodEnd
          }
          await this.updateLoanSchedule(
            {
              id: this.loan.id,
              data: {
                ...this.scheduleData,
                fees: this.scheduleData.fees.map(({
                  feeAmount,
                  feeType,
                  feeCalculationType,
                  date
                }) => ({
                  feeAmount,
                  date,
                  feeType: feeType.code,
                  feeCalculationType: feeCalculationType.code
                }))
              }
            }
          )
        } catch (e) {
          this.error = e
        }
      }
    },
    markChanged (field) {
      return {
        'text-danger': get(this.schedule, field) !== get(this.loanActiveSchedule[0], field)
      }
    },
    applyAmountIncrease ({ increaseLoanAmount, amount }) {
      this.scheduleData.amount = amount
      this.scheduleData.increaseLoanAmount = increaseLoanAmount
    },
    feeCalculationTypes (fee) {
      if (!fee) return
      return calculationTypes(fee, this.feeConfigs, this.classifierList('feeCalculationType'), true)
    },
    minDate (fee) {
      return fee.feeType.code.$model === 'AGREEMENT' ? this.$moment.max([this.$moment(this.systemData.nextDayChange), this.$moment(this.scheduleData.periodStart)]).toDate() : null
    }
  }
}
</script>


<i18n>
en:
  title:                         "Schedule parameters"
  amount:                        "Contract amount"
  limit:                         "Contract limit"
  dateStartEnd:                  "Start/End date"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty interest"
  feeTypeName:                   "Fee name"
  feeAmountDate:                 "Fee amount and date"
  administrationFee:             "Administration fee"
  scheduleType:                  "Schedule type"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  firstInterestDay:              "1st interest day"
  interestFreq:                  "Interest frequency"
  paybackAmount:                 "Early payback amount"
  amortizationPeriod:            "Amortization period"
  between:                       "Field value should be between {{ min }} and {{ max }}"
  periodEndErr:                  "End date must not be earlier than start date"
  feeDateErr:                    "Fee date must be between start and end date"
  principalFirstErr:             "Principal first payment day must be between {{ begin }} and {{ end }}"
  interestFirstErr:              "Interest first payment day must be between {{ begin }} and {{ end }}"
  amountGtProductErr:            "Amount should not be greater than product max amount ({{ value }})"
  gteToday:                      "Schedule start date cannot be in past"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  tempPenaltyRate:               "Temporary penalty interest"
  principalVatPeriod:            "Principal VAT period"
  amountVAT:                     "Contract amount with VAT"
  guaranteedPercentageFromAmount:         "Guaranteed percentage from amount"
et:
  title:                         "Schedule parameters"
  amount:                        "Contract amount"
  limit:                         "Contract limit"
  dateStartEnd:                  "Start/End date"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty interest"
  feeTypeName:                   "Fee name"
  feeAmountDate:                 "Fee amount and date"
  administrationFee:             "Administration fee"
  scheduleType:                  "Schedule type"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  firstInterestDay:              "1st interest day"
  interestFreq:                  "Interest frequency"
  paybackAmount:                 "Early payback amount"
  amortizationPeriod:            "Amortization period"
  between:                       "Field value should be between {{ min }} and {{ max }}"
  periodEndErr:                  "End date must not be earlier than start date"
  feeDateErr:                    "Fee date must be between start and end date"
  principalFirstErr:             "Principal first payment day must be between {{ begin }} and {{ end }}"
  interestFirstErr:              "Interest first payment day must be between {{ begin }} and {{ end }}"
  amountGtProductErr:            "Amount should not be greater than product max amount ({{ value }})"
  gteToday:                      "Schedule start date cannot be in past"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  tempPenaltyRate:               "Temporary penalty interest"
  principalVatPeriod:            "Principal VAT period"
  amountVAT:                     "Contract amount with VAT"
  guaranteedPercentageFromAmount:         "Guaranteed percentage from amount"
ru:
  title:                         "Schedule parameters"
  amount:                        "Contract amount"
  limit:                         "Contract limit"
  dateStartEnd:                  "Start/End date"
  interestRate:                  "Interest rate"
  interestBaseRate:              "Interest base rate"
  penaltyRate:                   "Penalty interest"
  feeTypeName:                   "Fee name"
  feeAmountDate:                 "Fee amount and date"
  administrationFee:             "Administration fee"
  scheduleType:                  "Schedule type"
  principalFreq:                 "Principal frequency"
  firstPrincipalDay:             "1st principal day"
  firstInterestDay:              "1st interest day"
  interestFreq:                  "Interest frequency"
  paybackAmount:                 "Early payback amount"
  amortizationPeriod:            "Amortization period"
  between:                       "Field value should be between {{ min }} and {{ max }}"
  periodEndErr:                  "End date must not be earlier than start date"
  feeDateErr:                    "Fee date must be between start and end date"
  principalFirstErr:             "Principal first payment day must be between {{ begin }} and {{ end }}"
  interestFirstErr:              "Interest first payment day must be between {{ begin }} and {{ end }}"
  amountGtProductErr:            "Amount should not be greater than product max amount ({{ value }})"
  gteToday:                      "Schedule start date cannot be in past"
  gte:                           "Field value should be greater or equal to {{value}}"
  lte:                           "Field value should be less or equal to {{value}}"
  residualAmount:                "Residual amount"
  residualAmountCalculationType: "Residual amount method"
  upfrontAmount:                 "Upfront amount"
  upfrontAmountCalculationType:  "Upfront amount method"
  tempPenaltyRate:               "Temporary penalty interest"
  principalVatPeriod:            "Principal VAT period"
  amountVAT:                     "Contract amount with VAT"
  guaranteedPercentageFromAmount:         "Guaranteed percentage from amount"
</i18n>
