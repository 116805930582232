<template lang="pug">
.contract-terms-representatives-list
  .card
    .card-header {{ $t('title') }}
      .fa-pull-right
        .btn-group.btn-group-sm
          button.btn.btn-primary.animated.fadeIn(@click.prevent = 'newRelationModal = true') {{ $t('newLink') }}
    .card-body
      .table-responsive
        table.table.table-hover
          tbody
            tr(v-if = 'relations.length === 0'): td.text-center(colspan = 2) {{ $t('notFound') }}
            template(v-else)
              loan-terms-representatives-list-item(
                v-for     = 'relation in relations'
                :key      = 'relation.id'
                :relation = 'relation'
              )
    customer-relation-new-modal(
      v-model   = 'newRelationModal'
      loader    = 'customer:relation:save'
      :customer = 'customer'
      @submit   = 'saveRelation'
    )
</template>


<script>
import LoanTermsRepresentativesListItem from './ContractTermsRepresentativesListItem'
import CustomerRelationNewModal from '@/views/customers/CustomerRelationNewModal'
import { mapActions } from 'vuex'

export default {
  name: 'contract-terms-representatives-list',

  components: { CustomerRelationNewModal, LoanTermsRepresentativesListItem },

  props: {
    relations: {
      type: Array,
      default: () => []
    },
    customer: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    newRelationModal: false
  }),

  methods: {
    ...mapActions('customers', ['createCustomerRelation']),
    async saveRelation ({ data: { relationData, referredCustomerId }, done }) {
      await this.createCustomerRelation({ relationData, customerId: referredCustomerId, referredCustomerId: this.customer.id })
      // eslint-disable-next-line no-unused-expressions
      done?.()
      this.$emit('relation-saved')
    }
  }
}
</script>


<i18n>
en:
  title:    "Contract representatives"
  newLink:  "+ Add representative"
  notFound: "Representatives not found"
et:
  title:    "Contract representatives"
  newLink:  "+ Add representative"
  notFound: "Representatives not found"
ru:
  title:    "Contract representatives"
  newLink:  "+ Add representative"
  notFound: "Representatives not found"
</i18n>
