<template lang="pug">
.loan-view
  v-loading(:loader = '["loan:fetch", "loan:product:fetch"]')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
    .animated.fadeIn(v-if = 'showLoan')
      .loan-view-header
        h3.header
          .header-item: router-link(:to = '{ name: "Customer", params: { id: loan.customerBorrower.id.toString() } }') {{ customerName(loan.customerBorrower) }} ({{ loan.customerBorrower.idCode }})
          .header-item: router-link(:to = '{  name: "Loan", params: { id: loan.id.toString() }}')
            v-loading.d-inline-block(loader = 'loan:product:fetch')
              template(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg
              template(v-if = 'loanProduct') {{ loanProduct.name }} ({{ loan.agreementNumber }})
          .header-item: .badge(:class = '`badge-${loanStatusColor(status.name)}`') {{ status.human }}
          .header-item(v-if = 'loan.penaltyGracePeriod && loan.penaltyGracePeriod.start && loan.penaltyGracePeriod.end') {{ $t('gracePeriod') }} {{ $t('common:formatDate', { value: loan.penaltyGracePeriod.start }) }} - {{ $t('common:formatDate', { value: loan.penaltyGracePeriod.end }) }}
        .row
          .col
            ul.nav.nav-pills.mt-2.mb-2
              li.nav-item: router-link.nav-link(:to = '{ name: "LoanOverview" }') {{ $t('overview') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "LoanSchedule" }') {{ $t('schedule') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "LoanTerms" }') {{ $t('terms') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "LoanCollateral" }') {{ $t('collateral') }}
              li.nav-item: router-link.nav-link(v-if = 'isActive'
                                                :to  = '{ name: "LoanFinancials" }') {{ $t('financials') }}
              li.nav-item: router-link.nav-link(:to = '{ name: "LoanCommunication" }') {{ $t('communication') }}
              li.nav-item: router-link.nav-link(v-if = 'loan.applicationId'
                                                :to  = '{ name: "Application", params: { id: loan.applicationId } }') {{ $t('application') }}
          v-loading.col-auto(loader = 'loan:product:fetch')
            i.fa.fa-spinner.fa-pulse.fa-lg(slot = 'spinner')
            .btn-toolbar
              .btn-group
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("NotificationEmailTemplate")')
                  i.far.fa-bell.fa-fw
                button.animated.fadeIn.btn.btn-outline-primary(@click = 'showSendMessage("ContractEmailTemplate")')
                  i.far.fa-envelope.fa-fw
                  |&nbsp;
                  i.fa.fa-print.fa-fw

      router-view

      loan-send-message-modal(
        v-if       = 'showSendMessageModal'
        v-model    = 'showSendMessageModal'
        :contract  = 'loan'
        :product   = 'loanProduct'
        :customer  = 'loanCustomer'
        :type      = 'templateType'
        :schedules = 'loanSchedules'
        :schedule  = 'loanSchedule'
      )
</template>


<script>
import { loanStatuses, schedulePagination } from '@/const'
import { isOverdraft } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoanSendMessageModal from '@/views/loans/LoanSendMessageModal'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'

export default {
  name: 'loan-view',

  components: {
    LoanSendMessageModal
  },

  mixins: [isOverdraft],

  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },

  i18nOptions: {},

  data (vm) {
    return {
      showSendMessageModal: false,
      templateType: '',
      schedulePagination: { ...schedulePagination, ...pick(vm.$route.query, Object.keys(schedulePagination)) }
    }
  },

  computed: {
    ...mapState('loans', ['loan', 'loanProduct', 'loanCustomer', 'loanSchedule', 'loanSchedules']),
    ...mapGetters('customers', ['customerName']),
    ...mapGetters('classifiers', ['classifierById']),
    ...mapGetters('loans', ['loanStatusColor']),
    status () {
      return this.classifierById(this.loan?.status?.classifier, this.loan?.status?.id)
    },
    showLoan () {
      return !(isEmpty(this.loan) || isEmpty(this.loanProduct))
    },
    isActive () {
      const { inserted, signed, pending, deleted } = loanStatuses
      return ![inserted, signed, pending, deleted].includes(this.loan.status.code)
    },
    searchQuery () {
      return pickBy({ ...this.schedulePagination })
    }
  },

  async created () {
    await this.loadLoan({ id: this.id })
    await this.loadLoanCustomer()
    await this.loadLoanFinancial()
    this.loadCustomerRelations({ customerId: this.loanCustomer.id })
    if (this.isOverdraftProduct) {
      await this.loadLoanOverdraftTerms()
      await this.loadLoanOverdraftTerm({ termId: 'active' })
    } else {
      await this.loadLoanSchedules({ filters: this.searchQuery })
      await this.loadLoanSchedule({ scheduleId: 'active' })
    }
    await this.loadPaymentRelations({ loanId: this.id })
  },

  methods: {
    ...mapActions('loans', [
      'loadLoan',
      'loadLoanCustomer',
      'loadCustomerRelations',
      'loadLoanSchedules',
      'loadLoanOverdraftTerms',
      'loadLoanSchedule',
      'loadLoanOverdraftTerm',
      'loadPaymentRelations',
      'loadLoanFinancial'
    ]),
    showSendMessage (type) {
      this.templateType = type
      this.showSendMessageModal = true
    }
  }
}
</script>


<i18n>
en:
  overview:      "Overview"
  financials:    "Financials"
  schedule:      "Schedule"
  terms:         "Terms"
  collateral:    "Collateral"
  communication: "Communication"
  application:   "Application"
  gracePeriod:   "Penalty grace period"
et:
  overview:      "Overview"
  financials:    "Financials"
  schedule:      "Schedule"
  terms:         "Terms"
  collateral:    "Collateral"
  communication: "Communication"
  application:   "Application"
  gracePeriod:   "Penalty grace period"
ru:
  overview:      "Overview"
  financials:    "Financials"
  schedule:      "Schedule"
  terms:         "Terms"
  collateral:    "Collateral"
  communication: "Communication"
  application:   "Application"
  gracePeriod:   "Penalty grace period"
</i18n>


<style scoped lang="scss">
.loan-view-header {
  top: 101px;
  background-color: #e4e5e6;
  position: sticky;
  z-index: 1000;
}
</style>
