<template lang="pug">
.loan-collateral-asset.card.animated.fadeIn
  .card-header
    | {{ assetType.human }}
    .fa-pull-right(v-if = '!editing')
      button.btn.btn-link.py-0.btn-sm(@click.prevent = 'toggleEdit'): i.far.fa-edit.fa-lg
      button.btn.btn-link.py-0.btn-sm.text-danger(v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }')
        i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'removing')
        i.far.fa-trash-alt.fa-lg(v-else)
  .card-body
    form.form-horizontal(
      @submit.prevent = 'submit'
      novalidate
    )
      template(v-if = 'assetErrors.length && !editing')
        .alert.h5.animated.fadeIn(
          v-for    = 'error in assetErrors'
          :key     = 'error.type'
          :class   = '`alert-${error.type}`'
        ) {{error.text}}

      component(
        :is             = 'immovableFields'
        v-if            = 'assetType.name === "IMMOVABLE"'
        :asset-data     = 'assetData'
        :immovable-data = 'immovable'
        :asset-owner    = 'assetOwnerData'
        :state          = '$v'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      component(
        :is             = 'otherFields'
        v-else-if       = 'assetType.name === "OTHER"'
        :asset-data     = 'assetData'
        :other-data   = 'other'
        :state          = '$v'
        :seller         = 'sellerData'
        :asset-owner    = 'assetOwnerData'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      component(
        :is             = 'productFields'
        v-else-if       = 'assetType.name === "PRODUCT"'
        :asset-data     = 'assetData'
        :product-data   = 'product'
        :state          = '$v'
        :seller         = 'sellerData'
        :asset-owner    = 'assetOwnerData'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      component(
        :is             = 'vehicleFields'
        v-else-if       = 'assetType.name === "VEHICLE"'
        :asset-data     = 'assetData'
        :vehicle-data   = 'vehicle'
        :state          = '$v'
        :seller         = 'sellerData'
        :asset-owner    = 'assetOwnerData'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      component(
        :is             = 'tradeReceivablesFields'
        v-else-if       = 'assetType.name === "TRADE_RECEIVABLES"'
        :asset-data     = 'assetData'
        :add-data       = 'trade_receivables'
        :state          = '$v'
        :seller         = 'sellerData'
        :asset-owner    = 'assetOwnerData'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      component(
        :is             = 'securitiesFields'
        v-else-if       = 'assetType.name === "SECURITIES"'
        :asset-data     = 'assetData'
        :add-data       = 'securities'
        :state          = '$v'
        :seller         = 'sellerData'
        :asset-owner    = 'assetOwnerData'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      component(
        :is             = 'guaranteesFields'
        v-else-if       = 'assetType.name === "GUARANTEES"'
        :asset-data     = 'assetData'
        :add-data       = 'guarantees'
        :state          = '$v'
        :seller         = 'sellerData'
        :asset-owner    = 'assetOwnerData'
        :appraisal-partner-data         = 'appraisalPartnerData'
      )

      template(v-if = 'editing')
        button.btn.btn-primary(
          type      = 'submit'
          :disabled = 'saving'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'resetData'
        ) {{ $t('common:cancel') }}
</template>


<script>
import api from '@/api'
import { asset } from '@/models'
import { validation, validators } from '@/validation'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'

const LoanCollateralAssetImmovableFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsImmovable' */'./LoanCollateralAssetImmovableFieldsDisplay')
const LoanCollateralAssetImmovableFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsImmovable' */'./LoanCollateralAssetImmovableFieldsEdit')
const LoanCollateralAssetTradeReceivablesFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsTradeReceivables' */'./LoanCollateralAssetTradeReceivablesFieldsDisplay')
const LoanCollateralAssetTradeReceivablesFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsTradeReceivables' */'./LoanCollateralAssetTradeReceivablesFieldsEdit')
const LoanCollateralAssetGuaranteesFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsGuarantees' */'./LoanCollateralAssetGuaranteesFieldsDisplay')
const LoanCollateralAssetGuaranteesFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsGuarantees' */'./LoanCollateralAssetGuaranteesFieldsEdit')
const LoanCollateralAssetSecuritiesFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsSecurities' */'./LoanCollateralAssetSecuritiesFieldsDisplay')
const LoanCollateralAssetSecuritiesFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsSecurities' */'./LoanCollateralAssetSecuritiesFieldsEdit')
const LoanCollateralAssetProductFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsProduct' */'./LoanCollateralAssetProductFieldsEdit')
const LoanCollateralAssetProductFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsProduct' */'./LoanCollateralAssetProductFieldsDisplay')
const LoanCollateralAssetOtherFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsOther' */'./LoanCollateralAssetOtherFieldsEdit')
const LoanCollateralAssetOtherFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsOther' */'./LoanCollateralAssetOtherFieldsDisplay')
const LoanCollateralAssetVehicleFieldsDisplay = () => import(/* webpackChunkName: 'assetComponentsVehicle' */'./LoanCollateralAssetVehicleFieldsDisplay')
const LoanCollateralAssetVehicleFieldsEdit = () => import(/* webpackChunkName: 'assetComponentsVehicle' */'./LoanCollateralAssetVehicleFieldsEdit')


export default {
  name: 'loan-collateral-asset',

  mixins: [validation],

  props: {
    asset: {
      type: Object,
      required: true
    },
    seller: {
      type: Object,
      default: null
    }
  },

  inject: ['storeModule'],

  i18nOptions: {},

  data () {
    return {
      editing: false,
      sellerData: null,
      appraisalPartnerData: null,
      assetOwnerData: [],
      assetData: {
        ...asset.common
      },
      immovable: {
        ...asset.immovable
      },
      other: {
        ...asset.other
      },
      product: {
        ...asset.product
      },
      vehicle: {
        ...asset.vehicle
      },
      trade_receivables: {
        ...asset.trade_receivables
      },
      securities: {
        ...asset.securities
      },
      suarantees: {
        ...asset.guarantees
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    ...mapState('settings', ['settings']),
    ...mapGetters('customers', ['customerName']),
    assetType () {
      return this.classifierById('assetTypes', this.asset.assetTypeId)
    },
    assetErrors () {
      const errors = []
      if (this.$moment(this.assetData.insuredUntil).isBefore(this.$moment())) {
        errors.push({ type: 'danger', text: this.$t('insuranceExpired') })
      }
      if (this.assetData.insuranceMandatory && !(this.assetData.insuranceCompany && this.assetData.insuredUntil)) {
        errors.push({ type: 'warning', text: this.$t('insuranceMissing') })
      }
      return errors
    },
    tradeReceivablesFields () {
      return this.editing ? LoanCollateralAssetTradeReceivablesFieldsEdit : LoanCollateralAssetTradeReceivablesFieldsDisplay
    },
    securitiesFields () {
      return this.editing ? LoanCollateralAssetSecuritiesFieldsEdit : LoanCollateralAssetSecuritiesFieldsDisplay
    },
    guaranteesFields () {
      return this.editing ? LoanCollateralAssetGuaranteesFieldsEdit : LoanCollateralAssetGuaranteesFieldsDisplay
    },
    immovableFields () {
      return this.editing ? LoanCollateralAssetImmovableFieldsEdit : LoanCollateralAssetImmovableFieldsDisplay
    },
    productFields () {
      return this.editing ? LoanCollateralAssetProductFieldsEdit : LoanCollateralAssetProductFieldsDisplay
    },
    otherFields () {
      return this.editing ? LoanCollateralAssetOtherFieldsEdit : LoanCollateralAssetOtherFieldsDisplay
    },
    vehicleFields () {
      return this.editing ? LoanCollateralAssetVehicleFieldsEdit : LoanCollateralAssetVehicleFieldsDisplay
    },
    removing () {
      return this.$vueLoading.isLoading(`${this.storeModule}:asset:${this.asset.id}:remove`)
    },
    saving () {
      return this.$vueLoading.isLoading(`${this.storeModule}:asset:${this.asset.id}:save`)
    },
    assetNameKey () {
      return this.assetType.name?.toLowerCase()
    }
  },

  watch: {
    asset: {
      async handler () {
        this.sellerData = await this.getSeller()
        if (this.asset.appraisalPartnerId) {
          this.appraisalPartnerData = await this.getSeller(this.asset.appraisalPartnerId)
        } else {
          this.appraisalPartnerData = null
        }
      },
      immediate: true
    }
  },

  validations: {
    assetData: {
      value: {
        required: validators.required
      }
    }
  },

  async created () {
    this.assetOwnerData = asset?.assetsOwners
    await this.resetData()
  },

  methods: {
    ...mapActions({
      updateAsset (dispatch, payload) {
        return dispatch(`${this.storeModule}/updateAsset`, payload)
      },
      removeAsset (dispatch, payload) {
        return dispatch(`${this.storeModule}/removeAsset`, payload)
      }
    }),
    async getSeller (id) {
      if (id) {
        return (await api.getCustomer({ id: id })).data
      }
      if (!this.asset.sellerId) return null
      return (await api.getCustomer({ id: this.asset.sellerId })).data
    },
    toggleEdit () {
      this.editing = !this.editing
    },
    async resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.assetData = pick(this.asset, Object.keys(this.assetData))
      this.sellerData = await this.getSeller()
      if (this.asset.appraisalPartnerId) {
        this.appraisalPartnerData = await this.getSeller(this.asset.appraisalPartnerId)
      }
      this[this.assetNameKey] = {
        ...asset[this.assetNameKey],
        ...pick(this.asset, Object.keys(asset[this.assetNameKey]))
      }
      switch (this.assetType.name) {
        case 'IMMOVABLE' || 'TRADE_RECEIVABLES' || 'SECURITIES' || 'GUARANTEES':
          // this.immovable.warrantyCountryId = this.classifierByName('countries', this.settings.country.toLowerCase())?.id
          break
        case 'PRODUCT':
          this.product.assetGroup = this.asset.assetGroup?.code
          break
        case 'OTHER':
          this.other.assetGroup = this.asset.assetGroup?.code
          break
      }

      this.$v.$reset()
    },
    async submit () {
      if (this.validate()) {
        if (this[this.assetNameKey].assetsOwners) {
          this[this.assetNameKey].assetsOwners.forEach((current) => {
            delete current.assetEntity
            delete current.customer
          })
        }
        if (this.assetOwnerData.length) {
          this[this.assetNameKey].assetsOwners = this.assetOwnerData
        }
        await this.updateAsset({ id: this.asset.id, assetData: { ...this.assetData, ...this[this.assetNameKey] } })
        this.toggleEdit()
      }
    },
    async remove ({ done }) {
      await this.removeAsset({ id: this.asset.id })
      done()
    }
  }
}
</script>


<style lang="scss" scoped>
.alert {
  white-space: pre-line;
}
</style>


<i18n>
en:
  insuranceExpired: "Insurance is expired!\n Please receive a new one."
  insuranceMissing: "Please provide information about insurance"
  removeConfirm:    "Are you sure you want to delete this asset?"
et:
  insuranceExpired: "Insurance is expired!\n Please receive a new one."
  insuranceMissing: "Please provide information about insurance"
  removeConfirm:    "Are you sure you want to delete this asset?"
ru:
  insuranceExpired: "Insurance is expired!\n Please receive a new one."
  insuranceMissing: "Please provide information about insurance"
  removeConfirm:    "Are you sure you want to delete this asset?"
</i18n>
