<template lang="pug">
tr.loan-schedule-interest-period-list-item
  td {{ periodName }}
  td {{ $t('common:formatDate', { value: period.variableInterestFrom }) }}
  td {{ $t('common:formatDate', { value: period.variableInterestUntil }) }}
  td {{ period.variableInterestRate | percent('0.000 %') }}
  loan-schedule-interest-period-modal(
    v-model          = 'showModal'
    :interest-period = 'period'
    :periods         = 'periods'
  )
</template>


<script>
import LoanScheduleInterestPeriodModal from './LoanScheduleInterestPeriodModal'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'loan-schedule-interest-period-list-item',

  components: { LoanScheduleInterestPeriodModal },

  props: {
    period: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      required: true
    },
    periods: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      showModal: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByCode']),
    removing () {
      return this.$vueLoading.isLoading(`loan:schedule:interest:${this.period.id}:remove`)
    },
    activePeriod () {
      return this.$moment().isBetween(this.period.periodStart, this.period.periodEnd)
    },
    periodName () {
      if (this.period.variableInterestDto) {
        return this.period.variableInterestDto.name
      } else {
        return 'FIXED'
      }
    }
  },

  methods: {
    ...mapActions('loans', ['removeLoanScheduleInterest']),
    async remove ({ done }) {
      await this.removeLoanScheduleInterest({ interestId: this.period.id })
      done()
    }
  }
}
</script>


<i18n>
en:
  removeConfirm: "Are you sure want to delete this interest?"
et:
  removeConfirm: "Are you sure want to delete this interest?"
ru:
  removeConfirm: "Are you sure want to delete this interest?"
</i18n>
