<template lang="pug">
tr.loan-overdraft-term-list-item
  td {{ $t('common:formatDate', { value: term.validFrom }) }}
  td {{ $t('common:formatDate', { value: term.validUntil}) }}
  td: .badge(:class = '`badge-${statusClass}`') {{ status.human }}
</template>


<script>
import { mapGetters } from 'vuex'

const COLOR_MAP = {
  PENDING: 'primary',
  COMPLETE: 'secondary',
  ACTIVE: 'success'
}

export default {
  name: 'loan-overdraft-term-list-item',

  props: {
    term: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    status () {
      return this.classifierById(this.term.status.classifier, this.term.status.id) || {}
    },
    statusClass () {
      return COLOR_MAP[this.status.name]
    }
  }
}
</script>


<style lang="scss">
.loan-overdraft-term-list-item {
  cursor: pointer;
}
</style>
