<template lang="pug">
  fi-form-field(
    v-if   = 'editing'
    :label = 'label || feeType.human'
    :state = '!fee.$anyError'
    v-bind = '$attrs'
  )
    template(#error)
      .form-row
        .col
          .error-message(v-if = '!fee.feeAmount.required') {{ $t('common:required') }}
          .error-message(v-if = '!fee.feeAmount.min') {{ formatRangeError('gte', convertedFeeRange.feeMin, percentageFee) }}
          .error-message(v-if = '!fee.feeAmount.max') {{ formatRangeError('lte', convertedFeeRange.feeMax, percentageFee) }}
    .form-row(v-if = 'fee.feeType.code.$model !== "GOVERNMENT_GUARANTEE"')
      .col: fi-select(
        v-model.number = 'fee.feeCalculationType.code.$model'
        :options       = 'feeCalculationTypeOptions'
        :class         = '{ "is-invalid": fee.feeCalculationType.code.$error }'
        :disabled      = '!fee.$model.feeOverride'
        required
        sm
      )
      .col
        percent-input.form-control.form-control-sm(
          v-if      = 'percentageFee'
          v-model   = 'fee.feeAmount.$model'
          :class    = '{ "is-invalid": fee.feeAmount.$error }'
          :disabled = '!feeEditable(fee.$model)'
        )

        fi-money-input.form-control.form-control-sm(
          v-else
          v-model   = 'fee.feeAmount.$model'
          :class    = '{ "is-invalid": fee.feeAmount.$error }'
          :disabled = '!feeEditable(fee.$model)'
        )
      .col(v-if = '!isFeeRegular && fee.hasOwnProperty("date")')
        fi-datepicker(
          v-model   = 'fee.date.$model'
          :disabled = '!feeEditable(fee.$model)'
          :min-date = 'minDate'
          sm
        )
      slot(name = 'edit-append' :is-custom="isCustomFee")
    .form-row(v-else)
      .col
        percent-input.form-control.form-control-sm(
          v-if      = 'percentageFee'
          v-model   = 'fee.feeAmount.$model'
          :class    = '{ "is-invalid": fee.feeAmount.$error }'
          :disabled = '!feeEditable(fee.$model)'
        )

        fi-money-input.form-control.form-control-sm(
          v-else
          v-model   = 'fee.feeAmount.$model'
          :class    = '{ "is-invalid": fee.feeAmount.$error }'
          :disabled = '!feeEditable(fee.$model)'
        )
      .col: fi-select(
        v-model.number = 'fee.feeCalculationType.code.$model'
        :options       = 'feeCalculationTypeOptions'
        :class         = '{ "is-invalid": fee.feeCalculationType.code.$error }'
        :disabled      = '!fee.$model.feeOverride'
        required
        sm
      )
      .col.margin-auto
        span(:id = '`toggle_${_uid}`')
          div {{ getGuaranteedPercentage | percent }}
        b-popover(
          :target   = '`toggle_${_uid}`'
          triggers  = 'hover'
          placement = 'bottom'
        ) {{ $t('regularNotice') }}
      slot(name = 'edit-append' :is-custom="isCustomFee")
  fi-data-field(
    v-else
    :label = 'label || feeType.human'
    v-bind = '$attrs'
  )
    .form-row
      .col
        template(v-if = 'percentageFee')
          div(:class = 'markChanged("actualAmount")') {{ fee.feeAmount | percent}}
        template(v-else)
          div(:class = 'markChanged("actualAmount")') {{ fee.feeAmount | money}}
      .col
        template(v-if = 'percentageFee')
          div(:class = 'markChanged("actualAmount")') {{ fee.actualAmount | money }}
      .col
        template(v-if="!isFeeRegular")
          div(:class = 'markChanged("date")') {{ $t('common:formatDate', { value: fee.date }) }}
          slot(name = 'show-append')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import PercentInput from '@/components/PercentInput'
import { mapGetters, mapState } from 'vuex'
import { calculationTypes, convertFee, convertFeeRange, feePercentage } from '@/helpers'
import FiDataField from '@/components/FiDataField'
import FiDatepicker from '@/components/FiDatepicker'
import { feeEditableMixin } from '@/mixins'
import { validation } from '@/validation'

export default {
  name: 'fi-fee-item',

  components: {
    FiDatepicker,
    FiDataField,
    FiFormField,
    FiMoneyInput,
    FiSelect,
    PercentInput
  },

  mixins: [feeEditableMixin, validation],

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      required: true
    },
    fee: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      default: false
    },
    feeConfigs: {
      type: Object,
      required: true
    },
    minDate: {
      type: [Date, String],
      default: null
    },
    activeSchedule: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'classifierList', 'classifierByCode']),
    ...mapState('loans', [
      'loanRelations'
    ]),
    feeType () {
      const { classifier, code } = this.fee.feeType
      return this.classifierByName(classifier?.$model ?? classifier, code?.$model ?? code) ?? {}
    },
    percentageFee () {
      const { code, $model = {} } = this.fee.feeCalculationType
      return feePercentage($model?.code ?? code)
    },
    getGuaranteedPercentage () {
      const found = this.loanRelations.find((element) => element.relatedCustomerType.code === 'GUARANTOR')?.guaranteedPercentageFromAmount
      return found || 0
    },
    isFeeRegular () {
      return this.feeType?.feeGroup?.code === 'REGULAR'
    },
    convertedFeeRange () {
      return convertFeeRange(this.fee.$model ?? this.fee, this.amount)
    },
    isCustomFee () {
      const { feeType } = this.fee
      return (Object.keys(this.feeConfigs).includes(feeType?.code.$model ?? feeType.$model) ? feeType?.code.$model ?? feeType.$model : 'CUSTOM_FEE') === 'CUSTOM_FEE'
    },
    feeCalculationTypeOptions () {
      return calculationTypes(
        this.fee.$model ?? this.fee,
        this.feeConfigs,
        this.classifierList('feeCalculationType')
      )
    }
  },

  watch: {
    percentageFee (value, oldValue) {
      if (value === oldValue || typeof oldValue === 'undefined') {
        return
      }

      this.fee.feeAmount.$model = convertFee({
        feeValue: this.fee.feeAmount?.$model,
        isPercentage: value,
        amountValue: this.amount
      })
    }
  },

  methods: {
    markChanged (field) {
      if (this.activeSchedule) {
        return this.activeSchedule.fees.find((fee) => fee.feeType.human === this.fee.feeType.human)[field] !== this.fee[field] && 'text-danger'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .margin-auto {
    margin: auto
  }
</style>

<i18n>
  en:
    regularNotice:  "contract relation terms"
  et:
    regularNotice:  "contract relation terms"
  ru:
    regularNotice:  "contract relation terms"
  </i18n>
