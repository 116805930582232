<template lang="pug">
tr.contract-terms-representatives-list-item
  td {{ type.human }}
  td: router-link(:to = '{ name: "Customer", params: { id: relation.customer.id } }') {{ name }}
</template>


<script>
import { mapGetters } from 'vuex'
import { customerName } from '@/helpers'

export default {
  name: 'contract-terms-representatives-list-item',

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    name () {
      return customerName(this.relation.customer)
    },
    type () {
      return this.classifierById('relationTypes', this.relation.relationTypeId)
    }
  }
}
</script>
