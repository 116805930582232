<template lang="pug">
.loan-terms-data.card
  .card-header {{ $t('title') }}
    .fa-pull-right
      .btn-group.btn-group-sm
        button.btn.btn-primary.animated.fadeIn(
          v-if           = '!editing && !isMatured'
          @click.prevent = 'toggleEditing'
        ) {{ $t("common:edit") }}
  .card-body
    form.form-horizontal(@submit.prevent = 'onSubmit')
      template(v-if = 'editing')
        fi-form-field(
          :label = '$t("signDate")'
          :disabled = '!editable'
        )
          fi-datepicker(
            v-model         = 'termsData.signingDate'
            :disabled-dates = 'disabledDates'
          )
        fi-form-field(
          :label = '$t("paymentRef")'
          :disabled = '!editable'
          :state    = '!$v.termsData.accountId.$error'
        )
          template(#error)
            .error-message(v-if = '!$v.termsData.accountId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = '$v.termsData.accountId.$model'
            :options       = 'optionsFromCustomerAccounts'
            :placeholder   = '$t("accountsPh")'
            :class         = '{ "is-invalid": $v.termsData.accountId.$error }'
            required
            sm
          )
          .fa-pull-right(v-if = 'editable')
            button.btn.btn-link(v-fi-confirm = '{ ok: newAccount, message: $t("addAccountConfirm") }') {{ $t('addNewAccount') }}
            i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("customer:account:save")')
        fi-form-field.pb-2(:label = '$t("loanPurpose")')
          textarea.form-control.form-control-sm(v-model = 'termsData.purpose')
        fi-form-field.pb-2(:label = '$t("specialTerms")')
          fi-rich-text-editor(v-model.trim = 'termsData.terms')
      template(v-else)
        fi-data-field(:label = '$t("signDate")') {{ $t('common:formatDate', { value: loan.signingDate }) }}
        fi-data-field(:label = '$t("paymentRef")') {{ loan.account && loan.account.referenceNumber }}
        fi-data-field(:label = '$t("loanPurpose")') {{ loan.purpose }}
        fi-data-field(:label = '$t("specialTerms")'): span(v-html = 'sanitizedText')
      template(v-if = 'editing')
        button.btn.btn-primary(
          :disabled = 'saving'
          type      = 'submit'
        )
          | {{ $t('common:save') }}
          i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
        | &nbsp;
        button.btn.btn-secondary(
          :disabled      = 'saving'
          @click.prevent = 'toggleEditing'
        ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFormField from '@/components/FiFormField'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiSelect from '@/components/FiSelect'
import FiDatepicker from '@/components/FiDatepicker'
import { loanStatuses } from '@/const'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'
import merge from 'lodash/merge'
import { rules, validation, validators } from '@/validation/index'
import sanitizeHtml from 'sanitize-html'
import { moneyFilter } from '@/filters'

export default {
  name: 'loan-terms-data',

  components: {
    FiRichTextEditor,
    FiSelect,
    FiDataField,
    FiFormField,
    FiDatepicker
  },

  mixins: [validation],

  props: {
    loan: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false,
      termsData: {
        signingDate: null,
        purpose: '',
        terms: '',
        accountId: ''
      }
    }
  },

  computed: {
    ...mapState('loans', ['loanProduct']),
    ...mapGetters('loans', ['optionsFromCustomerAccounts']),
    ...mapGetters('classifiers', ['classifierByName']),
    accountOptions () {
      const { id, referenceNumber, balance } = this.loan.account
      return [{
        value: id,
        text: `${referenceNumber} (${moneyFilter(balance)})`
      }, ...this.optionsFromCustomerAccounts]
    },
    isMatured () {
      return this.loan.status.code === loanStatuses.matured
    },
    saving () {
      return this.$vueLoading.isLoading('loan:term:details:save')
    },
    disabledDates () {
      return [
        date => this.$moment(date).startOf('day').isBefore(this.$moment(this.loan.account.createdAt).startOf('day'))
      ]
    },
    sanitizedText () {
      return sanitizeHtml(this.loan.terms || '', {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u', 'h1'])
      })
    }
  },

  validations () {
    return {
      termsData: merge(
        {},
        pick(rules.loanRules, Object.keys(this.termsData)),
        {
          accountId: {
            required: validators.requiredIf(function () {
              return this.editable
            })
          }
        }
      )
    }
  },

  created () {
    this.loadLoanCustomerAccounts({ productGroup: this.loanProduct.group })
  },

  methods: {
    ...mapActions('loans', ['updateLoanTermDetails', 'loadLoanCustomerAccounts', 'createCustomerAccountFromLoanTerms']),
    async newAccount ({ done }) {
      await this.createCustomerAccountFromLoanTerms({ accountData: { accountTypeId: this.classifierByName('accountTypes', 'PREPAYMENT').id }, customerId: this.loan.account.customerId })
      done()
    },
    resetData () {
      this.termsData = {
        ...this.termsData,
        ...pick(this.loan, Object.keys(this.termsData))
      }
      this.termsData.accountId = this.loan.account.id
      this.$v.$reset()
    },
    toggleEditing () {
      this.editing = !this.editing
      this.resetData()
    },
    onSubmit () {
      if (this.validate()) {
        this.updateLoanTermDetails({ contractId: this.loan.id, termsData: this.termsData })
        this.toggleEditing()
      }
    }
  }
}
</script>


<i18n>
en:
  title:             "Loan terms"
  signDate:          "Signing date"
  payoutBankAcc:     "Payout bank account"
  paymentRef:        "Payment reference"
  accountsPh:        "Select account"
  loanPurpose:       "Purpose of loan"
  specialTerms:      "Special terms"
  addAccountConfirm: "Are you sure you want to add account?"
  addNewAccount:     "Add new account"
  gte:               "Field value should be greater or equal to {{value}}"
  lte:               "Field value should be less or equal to {{value}}"
et:
  title:             "Loan terms"
  signDate:          "Signing date"
  payoutBankAcc:     "Payout bank account"
  paymentRef:        "Payment reference"
  accountsPh:        "Select account"
  loanPurpose:       "Purpose of loan"
  specialTerms:      "Special terms"
  addAccountConfirm: "Are you sure you want to add account?"
  addNewAccount:     "Add new account"
  gte:               "Field value should be greater or equal to {{value}}"
  lte:               "Field value should be less or equal to {{value}}"
ru:
  title:             "Loan terms"
  signDate:          "Signing date"
  payoutBankAcc:     "Payout bank account"
  paymentRef:        "Payment reference"
  accountsPh:        "Select account"
  loanPurpose:       "Purpose of loan"
  specialTerms:      "Special terms"
  addAccountConfirm: "Are you sure you want to add account?"
  addNewAccount:     "Add new account"
  gte:               "Field value should be greater or equal to {{value}}"
  lte:               "Field value should be less or equal to {{value}}"

</i18n>
