<template lang="pug">
fi-table.loan-schedule-invoice-list(
  :fields      = 'fields'
  :items       = 'invoices'
  :empty-label = '$t("notFound")'

  responsive
)
  template(#custom-footer)
    tr.font-weight-bold
      td(colspan=2)
      td(v-if = 'overdraft')
      td.money {{ total.principal | money }}
      td.money {{ total.interest | money }}
      td.money {{ total.fee | money }}
      td.money(v-if = 'vat') {{ total.vat | money }}
      td.money {{ sum | money }}
      td.money {{ paidSum | money }}
      td(colspan=2)
      td(v-if = 'overdraft && flexible')
  template(#default = '{ items }')
    component(
      :is       = 'invoiceComponent'
      v-for     = '(invoice, index) in items'
      :key      = 'invoice.id'
      :invoice  = 'invoice'
      :editing  = 'editing'
      :index    = 'index'
      :editable = 'isEditable(invoice, index)'
      :flexible  = 'flexible'
      @input    = '$emit("input", { [index]: {...$event} } )'
    )
</template>


<script>
import FiTable from '@/components/FiTable'

const LoanScheduleInvoiceListItem = () => import('./LoanScheduleInvoiceListItem')
const LoanScheduleInvoiceVatListItem = () => import('./LoanScheduleInvoiceVatListItem')
const OverdraftScheduleInvoiceListItem = () => import('@/views/loans/LoanSchedule/OverdraftScheduleInvoiceListItem')

export default {
  name: 'loan-schedule-invoice-list',

  components: {
    FiTable
  },

  props: {
    invoices: {
      type: Array,
      default: () => []
    },
    overdraft: Boolean,
    flexible: Boolean,
    vat: Boolean,
    editing: Boolean
  },

  i18nOptions: {},

  computed: {
    fields () {
      return [
        {
          key: 'date',
          label: this.$t('date')
        },
        ...this.overdraft ? [{
          key: 'deadline',
          label: this.$t('deadline')
        }] : [],
        {
          key: 'balance',
          label: this.vat ? this.$t('balanceVat') : this.$t('balance'),
          class: 'money, white-space'
        },
        {
          key: 'principal',
          label: this.$t('principal'),
          class: 'money'
        },
        {
          key: 'interest',
          label: this.$t('interest'),
          class: 'money'
        },
        {
          key: 'fee',
          label: this.$t('fee'),
          class: 'money'
        },
        ...this.vat ? [{
          key: 'vat',
          label: this.$t('vat'),
          class: 'money'
        }] : [],
        {
          key: 'sum',
          label: this.$t('sum'),
          class: 'money'
        },
        {
          key: 'paid',
          label: this.$t('paid'),
          class: 'money'
        },
        {
          key: 'paidDate',
          label: this.$t('paidDate')
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        ...this.overdraft && this.flexible ? [{}] : []
      ]
    },
    total () {
      return this.invoices.reduce((total, invoice) => {
        const sum = {}
        for (const key in invoice) {
          if (isNaN(invoice[key])) continue
          sum[key] = (total[key] ?? 0) + +invoice[key]
        }
        return sum
      }, {})
    },
    sum () {
      const { principal, interest, fee, vat = 0 } = this.total
      return principal + interest + fee + vat
    },
    paidSum () {
      const { amountPaid, vatAmountPaid = 0 } = this.total
      return amountPaid + vatAmountPaid
    },
    invoiceComponent () {
      if (this.vat) {
        return LoanScheduleInvoiceVatListItem
      }

      return this.overdraft ? OverdraftScheduleInvoiceListItem : LoanScheduleInvoiceListItem
    }
  },

  methods: {
    isEditable (invoice, index) {
      const editableLeasing = index < this.invoices.length - 1 && (!this.vat || index > 0)
      return this.flexible && invoice.status.code === 'PENDING' && (this.overdraft || (editableLeasing && invoice.principalInvoiceExists))
    }
  }
}
</script>


<i18n>
en:
  date:      "Date"
  balance:   "Balance"
  balanceVat: "Balance (incl. VAT)"
  principal: "Principal"
  deadline:  "Deadline"
  interest:  "Interest"
  fee:       "Fee"
  sum:       "Sum"
  paid:      "Paid"
  paidDate:  "Paid date"
  status:    "Status"
  notFound:  "No schedule available"
  vat:       "VAT"
et:
  date:      "Date"
  balance:   "Balance"
  balanceVat: "Balance (incl. VAT)"
  principal: "Principal"
  deadline:  "Deadline"
  interest:  "Interest"
  fee:       "Fee"
  sum:       "Sum"
  paid:      "Paid"
  paidDate:  "Paid date"
  status:    "Status"
  notFound:  "No schedule available"
  vat:       "VAT"
ru:
  date:      "Date"
  balance:   "Balance"
  balanceVat: "Balance (incl. VAT)"
  principal: "Principal"
  deadline:  "Deadline"
  interest:  "Interest"
  fee:       "Fee"
  sum:       "Sum"
  paid:      "Paid"
  paidDate:  "Paid date"
  status:    "Status"
  notFound:  "No schedule available"
  vat:       "VAT"
</i18n>


<style scoped lang="scss">
@import '~bootstrap/scss/mixins';
@import 'scss/variables';
@include media-breakpoint-up(lg) {
  .loan-schedule-invoice-list {
    & /deep/ thead, & /deep/ tbody tr, & /deep/ tfoot {
      width:100%;
      table-layout:fixed;/* even columns width , fix width of table too*/
    }
    & /deep/ .scrollBody {
      max-height: 400px;
      overflow: auto;
      display: block;
    }
  }
}
</style>
