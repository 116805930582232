<template lang='pug'>
.loan-payment-relation-fields
  fi-form-field(
    :label = '$t("paymentWay")'
    :state = '!paymentRelation.paymentWayId.$error'
  )
    template(#error)
      .error-message(v-if = '!paymentRelation.paymentWayId.required') {{ $t('common:required') }}
    fi-select(
      v-model  = 'paymentRelation.paymentWayId.$model'
      :class   = '{ "is-invalid": paymentRelation.paymentWayId.$error }'
      :options = 'optionsFromClassifier("paymentWays")'
      sm
      required
    )
  fi-form-field(:label = '$t("beneficiary")')
    fi-customer-select(v-model = 'selectedCustomerModel')
  fi-form-field(
    :label = '$t("beneficiaryName")'
    :state = '!paymentRelation.beneficiaryName.$error'
  )
    template(#error)
      .error-message(v-if = '!paymentRelation.beneficiaryName.required') {{ $t('common:required') }}
    input.form-control-sm.form-control(
      v-model   = 'paymentRelation.beneficiaryName.$model'
      :disabled = '!!paymentRelation.customerId.$model'
      :class    = '{ "is-invalid": paymentRelation.beneficiaryName.$error }'
    )
  fi-form-field(
    :label = '$t("beneficiaryIBAN")'
    :state = '!paymentRelation.beneficiaryExternalAccountNumber.$error'
  )
    template(#error)
      .error-message(v-if = '!paymentRelation.beneficiaryExternalAccountNumber.required') {{ $t('common:required') }}
    fi-select(
      v-if     = 'paymentRelation.customerId.$model && accounts.length'
      v-model  = 'paymentRelation.beneficiaryExternalAccountNumber.$model'
      :class   = '{ "is-invalid": paymentRelation.beneficiaryExternalAccountNumber.$error }'
      :options = 'accounts'
      sm
      required
    )
    input.form-control-sm.form-control(
      v-if    = 'isPaymentExternal'
      v-model = 'paymentRelation.beneficiaryExternalAccountNumber.$model'
      :class  = '{ "is-invalid": paymentRelation.beneficiaryExternalAccountNumber.$error }'
    )
  fi-form-field(
    :label = '$t("amount")'
    :state = '!paymentRelation.amount.$error'
  )
    template(#error)
      .error-message(v-if = '!paymentRelation.amount.required') {{ $t('common:required') }}
    fi-money-input.form-control.form-control-sm(
      v-model = 'paymentRelation.amount.$model'
      :class  = '{ "is-invalid": paymentRelation.amount.$error }'
    )
  fi-form-field(:label = '$t("description")')
    input.form-control.form-control-sm(v-model = 'paymentRelation.description.$model')
  fi-form-field(:label = '$t("referenceNumber")')
    input.form-control.form-control-sm(v-model = 'paymentRelation.referenceNumber.$model')
  fi-form-field(:label = '$t("subtractFee")')
    fi-switch(v-model = 'paymentRelation.subtractFeeAccount.$model')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapGetters } from 'vuex'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import api from '@/api'

export default {
  name: 'loan-payment-relation-fields',

  components: { FiCustomerSelect, FiSwitch, FiMoneyInput, FiSelect, FiMultiselect, FiFormField },

  props: {
    paymentRelation: {
      type: Object,
      required: true
    },
    accounts: {
      type: Array,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedCustomer: null
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierById']),
    isPaymentExternal () {
      return /external/i.test(this.classifierById('paymentWays', this.paymentRelation.paymentWayId.$model).code)
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        this.$emit('select-customer', selected)
      }
    }
  },

  async mounted () {
    if (this.paymentRelation.customerId.$model) {
      const { data: customer } = await api.getCustomer({ id: this.paymentRelation.customerId.$model })
      this.selectedCustomer = customer
      this.$emit('select-customer', customer)
    }
  }
}
</script>


<i18n>
en:
  beneficiary:     "Existing customer"
  payoutAccount:   "Payout bank account"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  description:     "Description"
  referenceNumber: "Reference number"
  paymentWay:      "Payment destination"
et:
  beneficiary:     "Existing customer"
  payoutAccount:   "Payout bank account"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  description:     "Description"
  referenceNumber: "Reference number"
  paymentWay:      "Payment destination"
ru:
  beneficiary:     "Existing customer"
  payoutAccount:   "Payout bank account"
  beneficiaryName: "Beneficiary name"
  beneficiaryIBAN: "Beneficiary IBAN"
  amount:          "Amount"
  subtractFee:     "Subtract fee"
  description:     "Description"
  referenceNumber: "Reference number"
  paymentWay:      "Payment destination"
</i18n>
