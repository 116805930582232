<template lang="pug">
.loan-overview-widget.card(:class = '`bg-${color}`')
  .card-body
    slot
    h4.m-0 {{ value }}
    p.m-0 {{ title }}
</template>


<script>
export default {
  name: 'loan-overview-widget',

  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  }
}
</script>
