<template lang = "pug">
.loan-collateral-mortgage-fields-edit.animated.fadeIn
  fi-form-field(
    :label     = '$t("type")'
    label-cols = 3
  )
    fi-select(
      v-model.number = 'mortgageData.mortgageTypeId'
      :options       = 'optionsFromClassifier("mortgageTypes")'
      sm
    )
  fi-form-field(
    :label     = '$t("amount")'
    label-cols = 3
  )
    fi-money-input.form-control.form-control-sm(v-model.number = 'mortgageData.amount')
  fi-form-field(
    :label     = '$t("setDate")'
    label-cols = 3
  )
    fi-datepicker(
      v-model = 'mortgageData.setDate'
      sm
    )
  fi-form-field(
    :label     = '$t("pledgee")'
    label-cols = 3
  )
    .row: .col
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = '$vueLoading.isLoading("pledgees:fetch")')
      fi-select(
        v-else
        v-model.number = 'mortgageData.pledgeeId'
        :options       = 'optionsFromPledgees'
        :placeholder   = '$t("pledgeesPh")'
        sm
      )
    .row: .col
      loan-collateral-pledgee-new(
        v-if    = 'newPledgee'
        v-model = 'mortgageData.pledgeeId'
        @cancel = 'newPledgee = false'
        @input  = 'newPledgee = false'
      )
      button.btn.btn-link(
        v-else
        @click.prevent = 'newPledgee = true'
      ) {{ $t('newPledgee') }}
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiSelect from '@/components/FiSelect'
import LoanCollateralPledgeeNew from '@/views/loans/LoanCollateral/LoanCollateralPledgeeNew'
import { mapGetters } from 'vuex'

export default {
  name: 'loan-collateral-mortgage-fields-edit',

  components: {
    LoanCollateralPledgeeNew,
    FiMoneyInput,
    FiSelect,
    FiFormField,
    FiDatepicker
  },

  props: {
    mortgageData: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    newPledgee: false
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('pledgees', ['optionsFromPledgees'])
  }
}
</script>


<i18n>
en:
  type:       "Type"
  amount:     "Amount"
  setDate:    "Set date"
  pledgee:    "Pledgee"
  pledgeesPh: "Select pledgee"
  newPledgee: "Define new pledgee"
et:
  type:       "Type"
  amount:     "Amount"
  setDate:    "Set date"
  pledgee:    "Pledgee"
  pledgeesPh: "Select pledgee"
  newPledgee: "Define new pledgee"
ru:
  type:       "Type"
  amount:     "Amount"
  setDate:    "Set date"
  pledgee:    "Pledgee"
  pledgeesPh: "Select pledgee"
  newPledgee: "Define new pledgee"
</i18n>
