<template lang="pug">
.loan-financials.animated.fadeIn
  v-loading(loader = 'loan:financial:fetch')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    template(v-if = 'loanFinancial')
      .card.mb-4.border-bottom-0
        .card-header {{ $t('financials') }}
        .card-body
          .table-responsive
            table.table.table-hover
              thead: tr
                th.money.align-top
                  small.text-muted {{ loanProduct.principalWithVat ? $t('balanceVat') : $t('balance') }}
                  br
                  a.h4.text-primary(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: contractBalanceAccount, amount: loanFinancial.balance, correction: true })'
                  ) {{ loanFinancial.balance | money }}
                th.money.align-top
                  small.text-muted {{ $t('principalDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_PRINCIPAL_RECEIVABLE", "LEDGER_PRINCIPAL_INCOME"], vat: loanProduct.principalWithVat, amount: loanFinancial.principalDebt, provision: true })'
                    :class      = 'debtClass(loanFinancial.principalDebt)'
                  ) {{ loanFinancial.principalDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('interestDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_INTEREST_RECEIVABLE", "LEDGER_INTEREST_INCOME"], amount: loanFinancial.interestDebt, vat: loanProduct.interestWithVat, provision: true })'
                    :class      = 'debtClass(loanFinancial.interestDebt)'
                  ) {{ loanFinancial.interestDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('penaltyDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_PENALTY_RECEIVABLE", "LEDGER_PENALTY_INCOME"], amount: loanFinancial.penaltyDebt, provision: true })'
                    :class      = 'debtClass(loanFinancial.penaltyDebt)'
                  ) {{ loanFinancial.penaltyDebt | money }}
                  template(v-if = 'loan.penaltyGracePeriod && loan.penaltyGracePeriod.penaltyAccrued')
                    br
                    small.text-danger {{ $t('gracePeriod') }} {{ loan.penaltyGracePeriod.penaltyAccrued | money }}
                th.money.align-top
                  small.text-muted {{ $t('feeDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_FEE_RECEIVABLE", "LEDGER_FEE_INCOME"], amount: loanFinancial.feeDebt, vat: feeWithVat, provision: true, isDebt: true })'
                    :class      = 'debtClass(loanFinancial.feeDebt)'
                  ) {{ loanFinancial.feeDebt | money }}
                th.money.align-top(v-if = 'isLeasingGroup')
                  small.text-muted {{ $t('vatDebt') }}
                  br
                  a.h4(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: ["LEDGER_VAT_TAX_RECEIVABLE", "LEDGER_VAT_TAX_PAID"], amount: loanFinancial.vatDebt })'
                    :class      = 'debtClass(loanFinancial.vatDebt)'
                  ) {{ loanFinancial.vatDebt | money }}
                th.money.align-top
                  small.text-muted {{ $t('totalDebt') }}
                  br
                  a.h4(:class = 'debtClass(loanFinancial.debts)') {{ loanFinancial.debts | money }}
                th.money.align-top
                  small.text-muted {{ $t('interest') }}
                  br
                  a.h4.text-primary(
                    href        = '#'
                    @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_INTEREST_ACCRUED", correction: true, amount: loanFinancial.interestAccruedBalance })'
                  ) {{ loanFinancial.interestAccruedBalance | money }}
                th.money.align-top
                  small.text-muted {{ $t('outstanding') }}
                  br
                  a.h4.text-primary {{ outstanding | money }}
              tbody
                tr
                  td.money {{ $t('receivable') }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PRINCIPAL_RECEIVABLE", vat: loanProduct.principalWithVat, correction: true})'
                    ) {{ loanFinancial.principalReceivableBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_INTEREST_RECEIVABLE", vat: loanProduct.interestWithVat, correction: true })'
                    ) {{ loanFinancial.interestReceivableBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PENALTY_RECEIVABLE", correction: true })'
                    ) {{ loanFinancial.penaltyReceivableBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_FEE_RECEIVABLE", vat: feeWithVat, correction: true, isDebt: true })'
                    ) {{ loanFinancial.feeReceivableBalance | money }}
                  td.money(v-if = 'isLeasingGroup')
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_VAT_TAX_RECEIVABLE" })'
                    ) {{ loanFinancial.vatReceivableBalance | money }}
                  td.money {{ totalReceivableDebt | money }}
                  td(colspan=2)
                tr
                  td.money {{ $t('income') }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PRINCIPAL_INCOME", vat: loanProduct.principalWithVat, correction: true })'
                    ) {{ loanFinancial.principalIncomeBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_INTEREST_INCOME", vat: loanProduct.interestWithVat, correction: true })'
                    ) {{ loanFinancial.interestIncomeBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_PENALTY_INCOME", correction: true })'
                    ) {{ loanFinancial.penaltyIncomeBalance | money }}
                  td.money
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_FEE_INCOME", vat: feeWithVat, correction: true, isDebt: true })'
                    ) {{ loanFinancial.feeIncomeBalance | money }}
                  td.money(v-if = 'isLeasingGroup')
                    a(
                      href           = '#'
                      @click.prevent = 'openTransactionsModal({ accountType: "LEDGER_VAT_TAX_PAID", correction: true })'
                    ) {{ loanFinancial.vatPaidBalance | money }}
                  td.money {{ totalIncomeDebt | money }}
                  td(colspan=2)
      loan-financials-list-modal(
        v-model       = 'showTransactionsModal'
        :account-type = 'accountType'
        :is-debt      = 'isDebt'
        :total-debt   = 'accountTotalDebt'
        :vat          = 'vat'
        :correction   = 'correctionEnabled'
        :provision    = 'provisionEnabled'
      )
      template(v-if = 'loanIfrs.length')
        .card.mb-0.border-bottom-0
          .card-header {{ $t('loanAndProvision') }}
          .card-body.loan-and-provision
            .loan-and-provision-left
              .current-box
                .current-box-content
                  fi-data-field(
                    :label = '$t("current")'
                  )
                    span.text-end {{ currentLoanStage }}
              .second-box
                .current-box-content
                  fi-data-field.pt-1(
                    :label = '$t("history")'
                  )
                  span.text-end(v-for = 'el in loanIfrs'
                                v-if  = 'el.current === false') {{ viewElemntIfrs(el, true) }}
            .loan-and-provision-right
              .current-box
                .current-box-content
                  span.text-end {{ currentLoanValue }}
              .second-box
                .current-box-content
                  span.text-end(v-for = 'el in loanIfrs'
                                v-if  = 'el.current === false') {{ viewElemntIfrs(el) }}
</template>


<script>
import { isLoanLeasing } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import LoanFinancialsListModal from '@/views/loans/LoanFinancials/LoanFinancialsListModal'
import FiDataField from '@/components/FiDataField'

export default {
  name: 'loan-financials',

  components: {
    LoanFinancialsListModal,
    FiDataField
  },

  mixins: [isLoanLeasing],

  i18nOptions: {},

  data () {
    return {
      showTransactionsModal: false,
      accountType: '',
      accountTotalDebt: '',
      vat: false,
      correctionEnabled: false,
      provisionEnabled: false,
      isDebt: false
    }
  },

  computed: {
    ...mapState('loans', ['loan', 'loanFinancial', 'loanProduct', 'loanIfrs']),
    totalReceivableDebt () {
      const {
        principalReceivableBalance = 0,
        interestReceivableBalance = 0,
        penaltyReceivableBalance = 0,
        feeReceivableBalance = 0,
        vatReceivableBalance = 0
      } = this.loanFinancial

      return principalReceivableBalance +
        interestReceivableBalance +
        penaltyReceivableBalance +
        feeReceivableBalance +
        vatReceivableBalance
    },
    totalIncomeDebt () {
      const {
        principalIncomeBalance = 0,
        interestIncomeBalance = 0,
        penaltyIncomeBalance = 0,
        feeIncomeBalance = 0,
        vatPaidBalance = 0
      } = this.loanFinancial

      return principalIncomeBalance +
        interestIncomeBalance +
        penaltyIncomeBalance +
        feeIncomeBalance +
        vatPaidBalance
    },
    outstanding () {
      return this.loanFinancial.balance + this.loanFinancial.interestAccruedBalance + this.loanFinancial.debts
    },
    feeWithVat () {
      const { fees } = this.loanProduct
      return fees.some(({ withVat }) => withVat)
    },
    contractBalanceAccount () {
      return this.loanProduct.principalWithVat ? ['VAT_BALANCE', 'LEDGER_PRINCIPAL_BALANCE'] : 'LEDGER_PRINCIPAL_BALANCE'
    },
    currentLoanStage () {
      const currentTrue = this.loanIfrs.filter(el => el.current === true)
      if (currentTrue[0].loanStage === 'UNKNOWN' || currentTrue[0].loanStage === null || currentTrue[0].loanStage === '') {
        return `${currentTrue[0].statementDate}`
      }
      return `${currentTrue[0].statementDate} - ${currentTrue[0].loanStage}`
    },
    currentLoanValue () {
      const currentTrue = this.loanIfrs.filter(el => el.current === true)
      if (currentTrue[0].generalProvision) {
        return `${currentTrue[0].statementDate} - ${currentTrue[0].generalProvision}`
      }
      return `${currentTrue[0].statementDate}`
    }
  },

  created () {
    this.loadLoanFinancial()
    this.loadLoanIfrs()
  },

  methods: {
    ...mapActions('loans', ['loadLoanFinancial', 'loadLoanIfrs']),
    debtClass (value) {
      return (value) ? 'text-danger' : 'text-success'
    },
    openTransactionsModal ({ accountType, amount, vat, correction, provision, isDebt }) {
      this.accountTotalDebt = amount
      this.accountType = accountType
      this.showTransactionsModal = true
      this.vat = vat
      this.correctionEnabled = correction
      this.provisionEnabled = provision
      this.isDebt = isDebt
    },
    viewElemntIfrs (el, direction) {
      if (direction) {
        if (el.loanStage === 'UNKNOWN' || el.loanStage === null || el.loanStage === '') {
          return `${el.statementDate}`
        } else {
          return `${el.statementDate} - ${el.loanStage}`
        }
      } else {
        if (el.generalProvision) {
          return `${el.statementDate} - ${el.generalProvision}`
        }
        return `${el.statementDate}`
      }
    }
  }
}
</script>


<i18n>
en:
  financials:       "Financial overview"
  transactions:     "Loan transactions"
  balance:          "Loan balance"
  balanceVat:       "Loan balance (incl. VAT)"
  principalDebt:    "Principal debt"
  interestDebt:     "Interest debt"
  penaltyDebt:      "Penalty debt"
  feeDebt:          "Fee debt"
  vatDebt:          "VAT debt"
  totalDebt:        "Total debt"
  interest:         "Interest accrued"
  outstanding:      "Total outstanding"
  receivable:       "Scheduled receivables"
  income:           "Client payments"
  gracePeriod:      "Grace period"
  loanAndProvision: "Loan stage and provision"
  current:          "Current"
  history:          "History"
et:
  financials:       "Financial overview"
  transactions:     "Loan transactions"
  balance:          "Loan balance"
  balanceVat:       "Loan balance (incl. VAT)"
  principalDebt:    "Principal debt"
  interestDebt:     "Interest debt"
  penaltyDebt:      "Penalty debt"
  feeDebt:          "Fee debt"
  vatDebt:          "VAT debt"
  totalDebt:        "Total debt"
  interest:         "Interest accrued"
  outstanding:      "Total outstanding"
  receivable:       "Scheduled receivables"
  income:           "Client payments"
  gracePeriod:      "Grace period"
  loanAndProvision: "Loan stage and provision"
ru:
  financials:       "Financial overview"
  transactions:     "Loan transactions"
  balance:          "Loan balance"
  balanceVat:       "Loan balance (incl. VAT)"
  principalDebt:    "Principal debt"
  interestDebt:     "Interest debt"
  penaltyDebt:      "Penalty debt"
  feeDebt:          "Fee debt"
  vatDebt:          "VAT debt"
  totalDebt:        "Total debt"
  interest:         "Interest accrued"
  outstanding:      "Total outstanding"
  receivable:       "Scheduled receivables"
  income:           "Client payments"
  gracePeriod:      "Grace period"
  loanAndProvision: "Loan stage and provision"
</i18n>
