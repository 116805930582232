<template lang='pug'>
fi-modal.loan-sign-modal(
  ref     = 'modal'
  v-bind  = '$attrs'
  v-on    = '$listeners'
  :title  = '$t("title")'
  size    = 'md'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :state = '!$v.loanData.signingDate.$error'
      :label = '$t("signingDate")'
    )
      template(#error)
        .error-message(v-if = '!$v.loanData.signingDate.required') {{ $t('common:required') }}
      fi-datepicker(
        v-model         = '$v.loanData.signingDate.$model'
        :disabled-dates = 'disabledDates'
        :class          = '{ "is-invalid": $v.loanData.signingDate.$error }'
        sm
      )
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    )
      | {{ $t("common:save") }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDatepicker from '@/components/FiDatepicker'
import FiModal from '@/components/FiModal'
import { validation, validators } from '@/validation'
import { mapState } from 'vuex'
import pick from 'lodash/pick'

export default {
  name: 'loan-sign-modal',

  components: {
    FiFormField,
    FiDatepicker,
    FiModal
  },

  mixins: [validation],

  inheritAttrs: false,

  props: {
    loan: {
      type: Object,
      required: true
    },
    callback: {
      type: Function,
      required: true
    },
    loader: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    loanData: {
      signingDate: ''
    }
  }),

  computed: {
    ...mapState('loans', ['systemData']),
    saving () {
      return this.$vueLoading.isLoading(this.loader)
    },
    disabledDates () {
      return [
        date => this.$moment(date).startOf('day').isSameOrBefore(this.$moment(this.systemData.nextDayChange).startOf('day'))
      ]
    }
  },

  validations: {
    loanData: {
      signingDate: {
        required: validators.required
      }
    }
  },

  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$v.$reset()
      this.loanData = {
        ...this.loanData,
        ...pick(this.loan, Object.keys(this.loanData))
      }
    },
    onHide (e) {
      if (this.saving) {
        e.preventDefault()
      }
    },
    async onSubmit () {
      if (this.validate()) {
        await this.callback({
          contractData: this.loanData,
          done: () => {
            this.$refs.modal.hide()
          }
        })
      }
    }
  }
}
</script>


<i18n>
en:
  title:       "Sign contract"
  signingDate: "Contract signing date"
et:
  title:       "Sign contract"
  signingDate: "Contract signing date"
ru:
  title:       "Sign contract"
  signingDate: "Contract signing date"
</i18n>
