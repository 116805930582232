<template lang = "pug">
.loan-list.animated.fadeIn
  .card
    .card-header
      | {{ $t('title') }}
      router-link.float-right(
        to = 'new'
        append
      ) {{ $t('newLink') }}
    .card-body
      form.form-horizontal
        .row
          .form-group.col-lg-6
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-search
              input.form-control(
                v-model.trim = 'searchParams.criteria'
                :placeholder = '$t("searchPlaceholder")'
                type         = 'text'
              )
          .form-group.col-lg-6
            range-input-date(
              :options     = 'rangeInputDateOptions'
              :start.sync  = 'searchParams.dateMin'
              :end.sync    = 'searchParams.dateMax'
              :column.sync = 'searchParams.dateColumn'
            )
        .row
          .form-group.col-lg-6
            range-input-text(
              :options     = 'rangeInputTextOptions'
              :start.sync  = 'searchParams.amountMin'
              :end.sync    = 'searchParams.amountMax'
              :column.sync = 'searchParams.amountColumn'
            )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-tag
              fi-select(
                v-model.number = 'searchParams.productId'
                :options       = 'sortOptionsFromAllProducts'
                :placeholder   = '$t("productsPlaceholder")'
              )
          .form-group.col-lg-3
            .input-group
              .input-group-prepend: span.input-group-text: i.fa.fa-flag
              fi-multiselect(
                v-model      = 'statusSearchModel'
                :options     = 'statusOptions'
                :placeholder = '$t("statusesPlaceholder")'
                multiple
              )
      .my-2(v-if = 'loansTotal') {{ $t('searchResults', { results: loansTotal }) }}
      fi-table(
        :fields      = 'fields'
        :items       = 'loans'
        loader       = 'loans:fetch'
        :empty-label = '$t("notFound")'
        :sort.sync   = 'searchParams.sort'
        responsive
      )
        template(#default = '{ items }')
          loan-list-item.animated.fadeIn(
            v-for = 'loan in items'
            :key  = 'loan.id'
            :loan = 'loan'
          )
      fi-pagination(
        v-if    = 'loansPages > 1'
        :pages  = 'loansPages'
        v-model = 'pagination.page'
      )
</template>


<script>
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import RangeInputDate from '@/components/RangeInputDate'
import RangeInputText from '@/components/RangeInputText'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'
import LoanListItem from './LoanListItem.vue'
import pickBy from 'lodash/pickBy'
import pick from 'lodash/pick'
import { pagination } from '@/const'
import FiMultiselect from '@/components/FiMultiselect'
import { getStatuesQuery } from '@/helpers'

const searchParams = {
  criteria: '',
  dateColumn: 'periodStart',
  dateMin: null,
  dateMax: null,
  amountColumn: 'amount',
  amountMin: null,
  amountMax: null,
  productId: '',
  statuses: [],
  sort: 'periodStart,desc'
}

export default {
  name: 'loan-list',

  components: {
    FiMultiselect,
    FiTable,
    FiSelect,
    FiPagination,
    RangeInputDate,
    RangeInputText,
    LoanListItem
  },

  props: {
    criteria: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    searchParams: { ...searchParams, ...pick(vm.$route.query, Object.keys(searchParams)), statuses: getStatuesQuery(vm.$route.query.statuses) },
    pagination: { ...pagination, ...pick(vm.$route.query, Object.keys(pagination)) }
  }),

  computed: {
    ...mapState('loans', ['loans', 'loansPages', 'loansTotal']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    ...mapGetters('products', ['optionsFromAllProducts']),
    statusOptions () {
      return this.optionsFromClassifier('LoanStatuses', true)
    },
    statusSearchModel: {
      get () {
        return this.searchParams.statuses.map(searchValue => this.statusOptions.find(({ value }) => value === searchValue))
      },
      set (values) {
        if (values.length === this.statusOptions.length) {
          this.searchParams.statuses = []
        } else {
          this.searchParams.statuses = values.map(({ value }) => value)
        }
      }
    },
    sortOptionsFromAllProducts () {
      const list = this.optionsFromAllProducts
      const sortList = list.sort(function (a, b) {
        const nameA = a.text.toLowerCase()
        const nameB = b.text.toLowerCase()
        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
      return sortList
    },
    rangeInputDateOptions () {
      return {
        periodStart: this.$t('startDatePlaceholder'),
        periodEnd: this.$t('endDatePlaceholder'),
        nextPaymentDate: this.$t('nextPaymentDate')
      }
    },
    rangeInputTextOptions () {
      return {
        amount: this.$t('amount'),
        balance: this.$t('balance')
      }
    },
    searchFilter () {
      const filter = { ...this.searchParams }
      if (!(filter.dateMin || filter.dateMax)) {
        filter.dateColumn = null
      }
      if (!(filter.amountMin || filter.amountMax)) {
        filter.amountColumn = null
      }
      return filter
    },
    fields () {
      return [
        {
          key: 'contract',
          label: this.$t('contract'),
          sort: 'agreementNumber'
        },
        {
          key: 'customer',
          label: this.$t('customer'),
          sort: 'customerFullName'
        },
        {
          key: 'product',
          label: this.$t('product'),
          sort: 'productId'
        },
        {
          key: 'amount',
          label: this.$t('amount'),
          class: 'money'
        },
        {
          key: 'balance',
          label: this.$t('balance'),
          class: 'money'
        },
        {
          key: 'limit',
          label: this.$t('limit'),
          class: 'money'
        },
        {
          key: 'debt',
          label: this.$t('debt'),
          class: 'money',
          sort: 'debts'
        },
        {
          key: 'reference',
          label: this.$t('reference')
        },
        {
          key: 'periodStart',
          label: this.$t('startDatePlaceholder'),
          sort: 'periodStart'
        },
        {
          key: 'endDate',
          label: this.$t('endDatePlaceholder'),
          sort: 'periodEnd'
        },
        {
          key: 'nextPaymentDate',
          label: this.$t('nextPaymentDate')
        },
        {
          key: 'signingDate',
          label: this.$t('signingDate')
        },
        {
          key: 'manager',
          label: this.$t('manager')
        },
        {
          key: 'status',
          label: this.$t('status'),
          sort: 'statusId'
        }
      ]
    },
    searchQuery () {
      return pickBy({ ...this.searchFilter, ...this.pagination })
    }
  },

  watch: {
    searchFilter: {
      handler (params, prev) {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    'pagination.page' () {
      this.search()
    },
    '$route.query': {
      handler (query) {
        this.loadLoans({ filters: query })
      },
      deep: true
    }
  },

  created () {
    this.loadProducts({ group: ['LOAN', 'OVERDRAFT', 'LEASING', 'GUARANTEE'] })
    this.loadLoans({ filters: this.searchQuery })
  },

  methods: {
    ...mapActions('loans', ['loadLoans']),
    ...mapActions('products', ['loadProducts']),
    search: debounce(
      function () {
        this.$router.push({ query: this.searchQuery })
      }, 500
    )
  }
}
</script>


<i18n>
en:
  title:                "Loans"
  newLink:              "+ New loan"
  searchPlaceholder:    "Search by name, id code, agreement reference"
  startDatePlaceholder: "Start Date"
  endDatePlaceholder:   "End Date"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All loan statuses"
  nextPaymentDate:      "Next payment date"
  customer:             "Customer"
  contract:             "Contract"
  amount:               "Amount"
  balance:              "Balance"
  limit:                "Contract limit"
  debt:                 "Debt"
  reference:            "Reference"
  product:              "Product"
  status:               "Status"
  signingDate:          "Sign date"
  manager:              "Manager"
  notFound:             "Loans not found"
  searchResults:        "Search results: {{ results }}"
et:
  title:                "Loans"
  newLink:              "+ New loan"
  searchPlaceholder:    "Search by name, id code, agreement reference"
  startDatePlaceholder: "Start Date"
  endDatePlaceholder:   "End Date"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All loan statuses"
  nextPaymentDate:      "Next payment date"
  customer:             "Customer"
  contract:             "Contract"
  amount:               "Amount"
  balance:              "Balance"
  limit:                "Contract limit"
  debt:                 "Debt"
  reference:            "Reference"
  product:              "Product"
  status:               "Status"
  signingDate:          "Sign date"
  manager:              "Manager"
  notFound:             "Loans not found"
  searchResults:        "Search results: {{ results }}"
ru:
  title:                "Loans"
  newLink:              "+ New loan"
  searchPlaceholder:    "Search by name, id code, agreement reference"
  startDatePlaceholder: "Start Date"
  endDatePlaceholder:   "End Date"
  productsPlaceholder:  "All products"
  statusesPlaceholder:  "All loan statuses"
  nextPaymentDate:      "Next payment date"
  customer:             "Customer"
  contract:             "Contract"
  amount:               "Amount"
  balance:              "Balance"
  limit:                "Contract limit"
  debt:                 "Debt"
  reference:            "Reference"
  product:              "Product"
  status:               "Status"
  signingDate:          "Sign date"
  manager:              "Manager"
  notFound:             "Loans not found"
  searchResults:        "Search results: {{ results }}"
</i18n>
