<template lang='pug'>
.card.loan-overview-schedule-overdraft
  b-tabs(
    content-class = 'border-top border-0'
     card
     lazy
   )
    b-tab
      template(#title) {{ $t('title') }} ({{ scheduleStatus.human }})
      loan-schedule-invoice-list(
        :invoices = 'schedule.overdraftInvoices'
        :flexible  = 'editable'
        overdraft
        @input    = 'saveInvoices'
      )
    b-tab(:title = '$t("usage")')
      loan-schedule-usage-statement-list
</template>


<script>
import LoanScheduleInvoiceList from '@/views/loans/LoanSchedule/LoanScheduleInvoiceList'
import LoanScheduleUsageStatementList from '@/views/loans/LoanSchedule/LoanScheduleUsageStatementList'
import { mapActions } from 'vuex'

export default {
  name: 'loan-overview-schedule-overdraft',

  components: {
    LoanScheduleUsageStatementList,
    LoanScheduleInvoiceList
  },

  props: {
    scheduleStatus: {
      type: Object,
      required: true
    },
    schedule: {
      type: Object,
      required: true
    },
    editable: Boolean
  },

  computed: {
    saving () {
      return this.$vueLoading.isLoading('overdraft:schedule:deadline:save')
    }
  },

  methods: {
    ...mapActions('loans', ['updateOverdraftInvoices']),
    async saveInvoices (data) {
      const [payload] = Object.values(data)
      const { done, ...invoiceData } = payload
      const overdraftInvoices = JSON.parse(JSON.stringify(this.schedule.overdraftInvoices))
      overdraftInvoices[data[Object.keys(data)[0]].index].deadlineDate = data[Object.keys(data)[0]].newPaymentDate
      delete invoiceData.index
      try {
        await this.updateOverdraftInvoices({ invoiceData, overdraftInvoices })
        done()
      } catch (e) {
        this.$toasted.error(e)
      }
    }
  }
}
</script>


<i18n>
en:
  title: "Schedule"
  usage: "Statement of usage"
et:
  title: "Schedule"
  usage: "Statement of usage"
ru:
  title: "Schedule"
  usage: "Statement of usage"
</i18n>
