<template lang="pug">
tr.loan-schedule-usage-statement-list-item
  td {{ $t('common:formatDate', { value: transaction.time }) }}
  td {{ transaction.description }}
  td.text-danger.money(v-if = "transaction.credit") -{{ transaction.amount | money }}
  td.text-success.money(v-else) +{{ transaction.amount | money }}
</template>

<script>
export default {
  name: 'loan-schedule-usage-statement-list-item',

  props: {
    transaction: {
      type: Object,
      required: true
    }
  }
}
</script>
