<template lang='pug'>
fi-modal.loan-schedule-parameters-amount-increase-modal(ref     = 'modal'
                                                        v-model = 'showModal'
                                                        :title  = '$t("title")'
                                                        size    = 'md'
                                                        lazy
                                                        @shown  = 'resetData')
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(:label = '$t("increaseLoanAmount")'
                  :state = '!$v.$error')
      template(#error)
        .error-message(v-if = '!$v.increaseLoanAmount.required') {{ $t('common:required') }}
        .error-message(v-if = '!$v.increaseLoanAmount.lte') {{ $t('lte', { value: $v.increaseLoanAmount.$params.lte.value }) }}
      fi-money-input.form-control.form-control-sm(v-model.number = 'increaseLoanAmount'
                                                  type           = 'text'
                                                  :class         = '{ "is-invalid": $v.$error }')
  template(#modal-footer)
    button.btn.btn-primary(@click.prevent = 'onSubmit')
      | {{ $t("common:save") }}
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t("common:cancel") }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDatepicker from '@/components/FiDatepicker'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiModal from '@/components/FiModal'
import { validation, validators } from '@/validation'

export default {
  name: 'loan-schedule-parameters-amount-increase-modal',

  components: {
    FiFormField,
    FiDatepicker,
    FiMoneyInput,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: Boolean,
      required: true
    },
    scheduleData: {
      type: Object,
      required: true
    },
    limitAvailable: {
      type: Number,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      increaseLoanAmount: ''
    }
  },

  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    }
  },

  validations () {
    return {
      increaseLoanAmount: {
        required: validators.required,
        lte: validators.lte(this.limitAvailable - this.scheduleData.amount)
      }
    }
  },

  methods: {
    resetData () {
      this.$v.$reset()
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    async onSubmit () {
      if (this.validate()) {
        this.$emit('submit', {
          increaseLoanAmount: this.increaseLoanAmount,
          amount: this.scheduleData.amount + this.increaseLoanAmount
        })
        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  title:              "Increase contract amount"
  increaseLoanAmount: "Increase amount by"
et:
  title:              "Increase contract amount"
  increaseLoanAmount: "Increase amount by"
ru:
  title:              "Increase contract amount"
  increaseLoanAmount: "Increase amount by"
</i18n>
