<template lang="pug">
.loan-collateral-mortgage-asset-list-item.card-body.border-top.animated.fadeIn
  fi-data-field(:label = '$t("assetId")')
    | {{ assetRegisterNumber }}
    button.btn.btn-link.py-0.btn-sm.text-danger(v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }')
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'removing')
      i.far.fa-trash-alt.fa-lg(v-else)
  fi-data-field(:label = '$t("queue")') {{ asset.queue }}
  fi-data-field(:label = '$t("precedingPriorities")') {{ asset.precedingPriorities | money }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'loan-collateral-mortgage-asset-list-item',

  components: { FiDataField },

  props: {
    asset: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  computed: {
    ...mapState('loans', ['loanAssets']),
    removing () {
      return this.$vueLoading.isLoading(`loan:mortgage:asset:${this.asset.relationId}:remove`)
    },
    assetRegisterNumber () {
      if (this.loanAssets.find(({ id }) => id === this.asset.id)?.registerNumber) {
        return `${this.loanAssets.find(({ id }) => id === this.asset.id)?.id} (${this.loanAssets.find(({ id }) => id === this.asset.id)?.registerNumber}  )`
      }
      return this.loanAssets.find(({ id }) => id === this.asset.id)?.id
    }
  },

  methods: {
    ...mapActions('loans', ['unAssignAssetFromMortgage']),
    async remove ({ done }) {
      await this.unAssignAssetFromMortgage({ relationId: this.asset.relationId })
      done()
    }
  }
}
</script>


<i18n>
en:
  assetId:             "Asset id"
  registerNumber:      "Register number"
  queue:               "Priority"
  precedingPriorities: "Preceding priorities amount"
et:
  assetId:             "Asset id"
  registerNumber:      "Register number"
  queue:               "Priority"
  precedingPriorities: "Preceding priorities amount"
ru:
  assetId:             "Asset id"
  registerNumber:      "Register number"
  queue:               "Priority"
  precedingPriorities: "Preceding priorities amount"
</i18n>
