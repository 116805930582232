<template lang="pug">
.loan-collateral-mortgage-asset-list.card
  .card-header.border-bottom-0
    | {{ $t('title') }}
    a.fa-pull-right(v-if           = 'allowMoreAssets'
                 href           = '#'
                 @click.prevent = 'newAsset = true') {{ $t('newAsset') }}

  loan-collateral-mortgage-asset-form(v-if      = 'newAsset'
                                      v-model   = 'newAsset'
                                      :mortgage = 'mortgage')

  loan-collateral-mortgage-asset-list-item(v-for  = 'asset in mortgage.fullAssets'
                                           :key   = 'asset.relationId'
                                           :asset = 'asset')
</template>


<script>
import LoanCollateralMortgageAssetListItem from './LoanCollateralMortgageAssetListItem'
import LoanCollateralMortgageAssetForm from './LoanCollateralMortgageAssetForm'
import { mapGetters } from 'vuex'

export default {
  name: 'loan-collateral-mortgage-asset-list',

  components: {
    LoanCollateralMortgageAssetListItem,
    LoanCollateralMortgageAssetForm
  },

  props: {
    mortgage: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      newAsset: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById']),
    allowMoreAssets () {
      return (!(this.classifierById('mortgageTypes', this.mortgage.mortgageTypeId).name === 'regular' && this.mortgage.fullAssets.length))
    }
  }
}
</script>


<i18n>
en:
  title: "Immovables"
  newAsset: "+ Add new"

et:
  title: "Immovables"
  newAsset: "+ Add new"

ru:
  title: "Immovables"
  newAsset: "+ Add new"
</i18n>
