<template lang="pug">
.loan-terms
  v-loading(:loader = '!loading && ["loan:term:details:fetch"]')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    .animated.fadeIn.row
      .col
        loan-details(
          v-if        = 'loanSchedule'
          :loan       = 'loanTermDetails'
          :schedule   = 'loanSchedule'
          :leasing    = 'isLeasingGroup'
          :fee-configs = 'feeConfigs'
        )
    .animated.fadeIn.row
      .col-sm-6
        loan-terms-data(
          :editable = 'isInserted'
          :loan     = 'loanTermDetails'
        )
        loan-terms-relations(
          :editable  = 'relationsEditable'
          :relations = 'loanRelations'
          @delete    = 'deleteRelation'
          @save      = 'saveRelation'
        )
        contract-terms-representatives-list(
          v-if            = 'loanCustomerRelations'
          :relations      = 'referredRelations'
          :customer       = 'loanCustomer'
          @relation-saved = 'loadCustomerRelations'
        )
      .col-sm-6
        contract-custom-fields-data(
          :custom-fields = 'customFieldsValues'
          :options       = 'loanCustomFields'
          loader         = 'loan:custom-fields:save'
          @submit        = 'saveCustomFields'
        )
        loan-payment-relations-list(
          :payment-relations = 'loanPaymentRelations'
          @create            = 'addPaymentRelation'
          @save              = 'savePaymentRelation'
          @remove            = 'removePaymentRelation'
        )
</template>


<script>
import { loanStatuses } from '@/const'
import LoanDetails from '@/views/loans/LoanDetails'
import LoanPaymentRelationsList from '@/views/loans/LoanTerms/LoanPaymentRelationList'
import LoanTermsRelations from './LoanTerms/LoanTermsRelations'
import LoanTermsData from './LoanTerms/LoanTermsData'
import { mapActions, mapState } from 'vuex'
import { isLoanLeasing, isOverdraft } from '@/mixins'
import ContractTermsRepresentativesList from '@/views/loans/LoanTerms/ContractTermsRepresentativesList'
import ContractCustomFieldsData from '@/views/loans/LoanTerms/ContractCustomFieldsData'

export default {
  name: 'loan-terms',

  components: {
    ContractCustomFieldsData,
    ContractTermsRepresentativesList,
    LoanPaymentRelationsList,
    LoanDetails,
    LoanTermsRelations,
    LoanTermsData
  },

  mixins: [isLoanLeasing, isOverdraft],

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState('loans', [
      'loan',
      'loanRelations',
      'loanProduct',
      'loanPaymentRelations',
      'loanCustomer',
      'loanSchedule',
      'loanTermDetails',
      'loanCustomerRelations',
      'customFieldsValues'
    ]),
    ...mapState('products', ['feeConfigs']),
    ...mapState('customFields', ['customFields']),
    isInserted () {
      return this.loanTermDetails?.status?.id === loanStatuses.inserted
    },
    isActive () {
      return this.loanTermDetails?.status.id === loanStatuses.active
    },
    relationsEditable () {
      return this.loanTermDetails?.status.id !== loanStatuses.matured
    },
    referredRelations () {
      return this.loanCustomerRelations?.referredRelations
    },
    loanCustomFields () {
      return this.loanProduct?.customFields?.map(field => ({ ...field, customField: this.customFields.find(({ id }) => id === field.customFieldId) }))
        .filter(({ customField }) => customField?.enabled)
        .sort((a, b) => a.fieldOrder - b.fieldOrder)
    }
  },

  async created () {
    await this.loadLoanTermDetails({ contractId: this.id })
    await this.loadPaymentRelations({ loanId: this.id })
    await this.loadLoanRelations({ loanId: this.id })
    const scheduleVersion = this.isActive ? 'active' : 'last'
    await this.loadFeeConfig({ group: this.loanProduct.group })
    if (this.isOverdraftProduct) {
      await this.loadLoanOverdraftTerm({ termId: scheduleVersion })
    } else {
      await this.loadLoanSchedule({ scheduleId: scheduleVersion })
    }
    await this.loadCustomFields()
    await this.loadLoanCustomFields({ loanId: this.id, productGroup: this.loanProduct?.group })
    this.loading = false
  },

  methods: {
    ...mapActions('loans', [
      'loadLoanSchedule',
      'loadLoanOverdraftTerm',
      'loadPaymentRelations',
      'loadLoanRelations',
      'deleteLoanRelation',
      'createLoanRelation',
      'updateLoanRelation',
      'createLoanPaymentRelation',
      'removeLoanPaymentRelation',
      'updateLoanPaymentRelation',
      'loadLoanFull',
      'loadLoanTermDetails',
      'loadCustomerRelations',
      'loadLoanCustomFields',
      'saveLoanCustomFields'
    ]),
    ...mapActions('products', ['loadFeeConfig']),
    ...mapActions('customFields', ['loadCustomFields']),
    deleteRelation ({ relationId }) {
      this.deleteLoanRelation({ loanId: this.loanTermDetails.id, relationId })
    },
    async saveRelation ({ relationId, done, relation }) {
      if (relationId) {
        await this.updateLoanRelation({ loanId: this.loanTermDetails.id, relationId, relationData: relation })
      } else {
        await this.createLoanRelation({ loanId: this.loanTermDetails.id, relationData: relation })
      }
      done && done()
    },
    async addPaymentRelation ({ done, paymentRelationData }) {
      await this.createLoanPaymentRelation({ paymentRelationData })

      done && done()
    },
    removePaymentRelation (paymentRelationId) {
      this.removeLoanPaymentRelation({ paymentRelationId })
    },
    async savePaymentRelation ({ done, paymentRelationData, paymentRelationId }) {
      await this.updateLoanPaymentRelation({ paymentRelationId, paymentRelationData })
      done && done()
    },
    async saveCustomFields ({ data, done }) {
      await this.saveLoanCustomFields({ loanId: this.id, customFieldData: data, productGroup: this.loanProduct?.group })
      done()
    }
  }
}
</script>
